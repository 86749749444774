import React from 'react';
import {
  Switch, Route, useRouteMatch,
} from 'react-router-dom';

import Streetrats from './Streetrats';
import CompletePack from './CompletePack';

const StatsCalculator = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/streetrats`}>
        <Streetrats />
      </Route>
      <Route path={`${path}/complete-package`}>
        <CompletePack />
      </Route>
    </Switch>
  );
};

export default StatsCalculator;
