import React from 'react';

import NPCStatBlock from '../../../components/NPCStatBlock';

import npcData from './NPCdata';

const NPCList = () => (
  <div style={{
    display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '3rem',
  }}
  >
    {npcData.map((npc) => (
      <NPCStatBlock key={npc.name} npc={npc} />
    ))}
  </div>
);

export default NPCList;
