import React, { useState } from 'react';
import Sidebar from 'react-sidebar';
import { AiOutlineMenu } from 'react-icons/ai';
import './App.css';
import {
  Switch, Route, Redirect,
} from 'react-router-dom';

import {
  Sidemenu, LifepathScreen, GeneratorsScreen, InfoScreen, StatsCalculator,
} from './features';

import styles from './app.module.scss';

function App() {
  const [sidebarDocked, setSidebarDocked] = useState(true);

  return (
    <div className="App">
      <Sidebar
        sidebar={<Sidemenu onHeaderClick={() => setSidebarDocked(false)} />}
        docked={sidebarDocked}
        styles={{ sidebar: { background: 'white' } }}
      >
        <div className={styles.content}>
          {!sidebarDocked && (
          <div
            className={styles.sidemenuButton}
            role="button"
            tabIndex={0}
            onClick={() => setSidebarDocked(true)}
          >
            <AiOutlineMenu />
          </div>
          )}
          <Switch>
            <Route exact path="/">
              <Redirect to="/character-generator" />
            </Route>
            <Route path="/character-generator">
              <LifepathScreen />
            </Route>
            <Route path="/stats">
              <StatsCalculator />
            </Route>
            <Route path="/generators">
              <GeneratorsScreen />
            </Route>
            <Route path="/info">
              <InfoScreen />
            </Route>
          </Switch>
        </div>
      </Sidebar>
    </div>
  );
}

export default App;
