import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';

import StatOption from '../../../../components/StatOption';

import skillsData from '../../../../skills';

import { SkillValue } from '../../../../types';
import styles from './styles.module.scss';

type SkillsTableProps = {
  skillValues: SkillValue[]
};

const SkillsTable = ({ skillValues }: SkillsTableProps) => {
  const [skills, setSkills] = useState(cloneDeep(skillsData));

  useEffect(() => {
    const newSkills = cloneDeep(skillsData);
    skillValues.forEach((skillValue) => {
      newSkills[skillValue.name].value = skillValue.value;
    });
    setSkills(cloneDeep(newSkills));
  }, [skillValues]);

  const groupedSkills = groupBy(skills, (skill) => skill.skillCategory);

  return (
    <div className={styles.skillsTable}>
      {map(groupedSkills, (skillGroup, groupName) => (
        <div key={groupName} className={styles.skillGroup}>
          <div className={styles.skillGroupHeader}>
            {groupName}
          </div>
          <div className={styles.skillGroupTable}>
            {skillGroup.map((skill) => (
              <StatOption
                key={skill.name}
                title={`${skill.name} ${skill.isX2 ? ' (X2)' : ''}`}
                bold={skill.isBase}
                tooltip={skill.description}
                value={skill.value}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkillsTable;
