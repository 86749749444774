import type { DrugDuration } from 'types';

const drugDurationData: DrugDuration[] = [
  {
    name: 'short',
    difficultyMultiplier: 1,
    duration: '1d10 minutes',
  }, {
    name: 'medium',
    difficultyMultiplier: 2,
    duration: '1d6x10 minutes',
  }, {
    name: 'long',
    difficultyMultiplier: 3,
    duration: '1d10 hours',
  },
];

export default drugDurationData;
