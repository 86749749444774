import React, { useState, useEffect } from 'react';
import { roles } from 'types';
import useRerandom from 'utils/rerandomHook';
import { Dropdown, Card } from 'components/UI';

import StatsTable from './StatsTable';
import SkillsTable from './SkillsTable';

import skillsByRoleData from './skillsByRoleData';
import statsArrays from '../statsArrays';

import styles from './styles.module.scss';

const StreetratsStatsTable = () => {
  const [role, setRole] = useState(roles[0]);
  const [roleStats, setRoleStats] = useState(statsArrays[role]);
  const [randomValue, rerandom] = useRerandom(10);
  const randomStatBlock = roleStats[randomValue];
  const [hp, setHp] = useState(0);
  const [swThreshold, setSwThreshold] = useState(Math.ceil(hp / 2));

  useEffect(() => {
    setRoleStats(statsArrays[role]);
  }, [role]);

  useEffect(() => {
    setHp(Math.ceil((randomStatBlock.body + randomStatBlock.will) / 2) * 5 + 10);
    setSwThreshold(Math.ceil(hp / 2));
  }, [randomStatBlock, randomStatBlock.body, randomStatBlock.will, hp]);

  return (
    <div className={styles.streetratsBlock}>
      <Card>
        <StatsTable stats={randomStatBlock} rerandom={rerandom} />
        <div className={styles.derivedStatsBlock}>
          <div>
            {`HP: ${hp}`}
          </div>
          <div>
            {`Seriously Wounded: ${swThreshold}`}
          </div>
          <div>
            {`Death Save: ${randomStatBlock.body}`}
          </div>
          <div>
            {`Humanity: ${randomStatBlock.emp * 10}`}
          </div>
        </div>
      </Card>
      <Card>
        <div>
          Role:
          <Dropdown
            value={role}
            options={roles}
            onChange={(value) => setRole(value)}
          />
        </div>
      </Card>
      <Card style={{ width: '100%' }}>
        <SkillsTable skillValues={skillsByRoleData[role]} />
      </Card>
    </div>
  );
};

export default StreetratsStatsTable;
