/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import style from './Dropdown.module.scss';

type DropdownProps<OptionsType> = {
  value?: OptionsType | null,
  options: OptionsType[],
  onChange: (option: OptionsType) => void
};

function Dropdown<T>({ value, options, onChange }: DropdownProps<T>) {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div className={style.dropdown}>
      <div
        role="menu"
        tabIndex={0}
        className={style.dropdownInput}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {value || 'none'}

      </div>
      {showDropdown
        && (
          <div className={style.dropdownOptions}>
            {
              options.map((option, index) => (
                <div
                  key={option as unknown as string + index}
                  role="menuitem"
                  tabIndex={-1}
                  onClick={() => {
                    onChange(option);
                    setShowDropdown(false);
                  }}
                >
                  {option}
                </div>
              ))
            }
          </div>
        )}
    </div>
  );
}

Dropdown.defaultProps = {
  value: null,
};

export default Dropdown;
