import React, { useState, useEffect } from 'react';
import { Stats } from 'types';
import { Card } from 'components/UI';
import StatsTable from './StatsTable';
import SkillsTable from './SkillsTable';

import styles from './styles.module.scss';

const baseStats = {
  int: 2,
  ref: 2,
  dex: 2,
  tech: 2,
  cool: 2,
  will: 2,
  luck: 2,
  move: 2,
  body: 2,
  emp: 2,
};

const CompletePack = () => {
  const [stats, setStats] = useState<Stats>(baseStats);
  const [hp, setHp] = useState(0);
  const [swThreshold, setSwThreshold] = useState(Math.ceil(hp / 2));
  useEffect(() => {
    setHp(Math.ceil((stats.body + stats.will) / 2) * 5 + 10);
    setSwThreshold(Math.ceil(hp / 2));
  }, [stats, stats.body, stats.will, hp]);

  return (
    <div className={styles.completePackBlock}>
      <StatsTable stats={stats} setStats={setStats} />
      <Card>
        <div className={styles.derivedStatsBlock}>
          <div>
            {`HP: ${hp}`}
          </div>
          <div>
            {`Seriously Wounded: ${swThreshold}`}
          </div>
          <div>
            {`Death Save: ${stats.body}`}
          </div>
          <div>
            {`Humanity: ${stats.emp * 10}`}
          </div>
        </div>
      </Card>
      <SkillsTable />
    </div>
  );
};

export default CompletePack;
