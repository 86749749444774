import React, { useState } from 'react';
import StatOption from 'components/StatOption';
import { Card } from 'components/UI';

import type { Stats } from '../../../../types';

import styles from './styles.module.scss';

type CompleteStatsTableProps = {
  stats: Stats,
  setStats: (stats: Stats) => void
};

const CompleteStatsTable = ({ stats, setStats }: CompleteStatsTableProps) => {
  const [points, setPoints] = useState(42);

  const onAdd = (stat: string) => {
    if (stats[stat as keyof typeof stats] === 8 || points === 0) {
      return;
    }
    const newStats = { ...stats };
    newStats[stat as keyof typeof stats] += 1;
    setStats(newStats);
    setPoints(points - 1);
  };

  const onDecrease = (stat: string) => {
    if (stats[stat as keyof typeof stats] === 2) {
      return;
    }
    const newStats = { ...stats };
    newStats[stat as keyof typeof stats] -= 1;
    setStats(newStats);
    setPoints(points + 1);
  };
  return (
    <Card>
      <div className={styles.statsInfo}>
        <div className={styles.statsTable}>
          {Object.keys(stats).map((stat) => (
            <StatOption
              key={stat}
              title={stat}
              withControls
              value={stats[stat as keyof typeof stats]}
              onAdd={() => onAdd(stat)}
              onDecrease={() => onDecrease(stat)}
            />
          ))}
        </div>
        <div className={styles.pointsTracker}>
          {points}
        </div>
      </div>
    </Card>
  );
};

export default CompleteStatsTable;
