export type Weapon = {
  type: string,
  skill: string,
  specialFeatures?: string[],
  damage: string,
  magazine?: number,
  rof: number,
  handsRequired?: number,
  concealable: boolean,
  cost: number
};

const meleeWeaponData: Weapon[] = [
  {
    type: 'Light Melee Weapon',
    skill: 'Melee weapon',
    damage: '1d6',
    rof: 2,
    concealable: true,
    cost: 50,
  }, {
    type: 'Medium Melee Weapon',
    skill: 'Melee weapon',
    damage: '2d6',
    rof: 2,
    concealable: false,
    cost: 50,
  }, {
    type: 'Heavy Melee Weapon',
    skill: 'Melee weapon',
    damage: '3d6',
    rof: 2,
    concealable: false,
    cost: 100,
  }, {
    type: 'Very Heavy Melee Weapon',
    skill: 'Melee weapon',
    damage: '4d6',
    rof: 1,
    concealable: false,
    cost: 500,
  },
];

const rangedWeaponData: Weapon[] = [
  {
    type: 'Medium Pistol',
    skill: 'Handgun',
    damage: '2d6',
    magazine: 12,
    rof: 2,
    handsRequired: 1,
    concealable: true,
    cost: 50,
  }, {
    type: 'Heavy Pistol',
    skill: 'Handgun',
    damage: '3d6',
    magazine: 8,
    rof: 2,
    handsRequired: 1,
    concealable: true,
    cost: 100,
  }, {
    type: 'Very Heavy Pistol',
    skill: 'Handgun',
    damage: '4d6',
    magazine: 8,
    rof: 1,
    handsRequired: 1,
    concealable: false,
    cost: 100,
  }, {
    type: 'SMG',
    skill: 'Handgun',
    specialFeatures: ['Autofire (3)', 'Suppressive Fire'],
    damage: '2d6',
    magazine: 30,
    rof: 1,
    handsRequired: 1,
    concealable: true,
    cost: 100,
  }, {
    type: 'Heavy SMG',
    skill: 'Handgun',
    specialFeatures: ['Autofire (3)', 'Suppressive Fire'],
    damage: '3d6',
    magazine: 40,
    rof: 1,
    handsRequired: 1,
    concealable: false,
    cost: 100,
  }, {
    type: 'Shotgun',
    skill: 'Shoulder arms',
    specialFeatures: ['Shotgun Shell'],
    damage: '5d6',
    magazine: 4,
    rof: 1,
    handsRequired: 2,
    concealable: false,
    cost: 500,
  }, {
    type: 'Assault Rifle',
    skill: 'Shoulder arms',
    specialFeatures: ['Autofire (4)', 'Suppressive Fire'],
    damage: '5d6',
    magazine: 25,
    rof: 1,
    handsRequired: 2,
    concealable: false,
    cost: 500,
  }, {
    type: 'Sniper Rifle',
    skill: 'Shoulder arms',
    damage: '5d6',
    magazine: 4,
    rof: 1,
    handsRequired: 2,
    concealable: false,
    cost: 500,
  }, {
    type: 'Bows & Crossbows',
    skill: 'Archery',
    specialFeatures: ['Arrows'],
    damage: '4d6',
    magazine: NaN,
    rof: 1,
    handsRequired: 2,
    concealable: false,
    cost: 100,
  }, {
    type: 'Grenade Launcher',
    skill: 'Heavy Weapons',
    specialFeatures: ['Explosive'],
    damage: '6d6',
    magazine: 2,
    rof: 1,
    handsRequired: 2,
    concealable: false,
    cost: 500,
  }, {
    type: 'Rocket Launcher',
    skill: 'Heavy Weapons',
    specialFeatures: ['Explosive'],
    damage: '8d6',
    magazine: 1,
    rof: 1,
    handsRequired: 2,
    concealable: false,
    cost: 500,
  },
];

const rangedWeaponTypes = [
  'Medium Pistol', 'Heavy Pistol', 'Very Heavy Pistol',
  'SMG', 'Heavy SMG', 'Shotgun', 'Assault Rifle', 'Sniper Rifle',
  'Bows & Crossbows', 'Grenade Launcher', 'Rocket Launcher', 'Exotic',
];

export type WeaponAttachment = {
  name: string,
  notCompatible: string[],
  description: string,
  attachmentSlotsRequired: number,
  cost: number,
};

const weaponAttachmentsData: WeaponAttachment[] = [
  {
    name: 'Bayonet',
    notCompatible: ['Medium Pistol', 'Heavy Pistol', 'Very Heavy Pistol',
      'SMG', 'Heavy SMG', 'Bows & Crossbows', 'Grenade Launcher', 'Rocket Launcher'],
    description: 'When wielded, this weapon can also be used as a Light Melee Weapon. While this is attached to a weapon, it cannot be concealed under clothing.',
    attachmentSlotsRequired: 1,
    cost: 100,
  }, {
    name: 'Grenade Launcher Underbarrel',
    notCompatible: ['Medium Pistol', 'Heavy Pistol', 'Very Heavy Pistol',
      'SMG', 'Heavy SMG', 'Bows & Crossbows', 'Grenade Launcher', 'Rocket Launcher'],
    description: 'When wielded in two hands, the weapon can also be used as a Grenade Launcher, with only 1 grenade in its magazine. While this is attached to a weapon, it cannot be concealed under clothing.',
    attachmentSlotsRequired: 2,
    cost: 500,
  }, {
    name: 'Infrared Nightvision Scope',
    notCompatible: ['Exotic'],
    description: 'Reduces penalties imposed on your firing at a target obscured to you by darkness, smoke, fog, etc. to 0. Looking through the scope, you can distinguish hot meat from cold metal, but not more specifically than that. You can’t tell the brand of their Cyberarm from a distance, or see any of its internal surprises, for example.',
    attachmentSlotsRequired: 1,
    cost: 500,
  }, {
    name: 'Shotgun Underbarrel',
    notCompatible: ['Medium Pistol', 'Heavy Pistol', 'Very Heavy Pistol',
      'SMG', 'Heavy SMG', 'Bows & Crossbows', 'Grenade Launcher', 'Rocket Launcher'],
    description: 'When wielded in two hands, the weapon can also be used as a Shotgun, with only 2 shots in its magazine. While this is attached to a weapon, it cannot be concealed under clothing.',
    attachmentSlotsRequired: 2,
    cost: 500,
  }, {
    name: 'Smartgun Link',
    notCompatible: ['Exotic'],
    description: 'Installing or uninstalling a Smartgun Link takes an hour. A weapon is a Smartgun only when it has a Smartgun Link attached to it. Special Cyberware is required to take advantage of a Smartgun. A Smartgun Link must be connected to you with Interface Plugs or a Subdermal Grip in order to operate, both of which require you to have a Neural Link. A Subdermal Grip connects a Smartgun held in it automatically. You can plug in Interface Plugs as part of drawing a Smartgun into a free hand, as long as your Interface Plugs aren\'t already plugged into something else. Being disarmed of your Smartgun doesn\'t snap your cables, it just unplugs them from the Smartgun. Plugging them back in isn\'t an Action should you have the Smartgun in your hand, as their ports are designed for ease-of-use. Why go through all this trouble? Because when making Ranged Attacks with one, you add a +1 to your Check.',
    attachmentSlotsRequired: 2,
    cost: 500,
  }, {
    name: 'Sniping Scope',
    notCompatible: ['Exotic'],
    description: 'Looking through the scope, user can see detail up to 800m/yds away. When attacking a target 51m/yards or further away with either a weapon\'s single shot firing mode or an Aimed Shot, you can add a +1 to your Check. Does not stack with TeleOptics Cyberware.',
    attachmentSlotsRequired: 1,
    cost: 100,
  },
];

type ExoticWeapon = {
  name: string,
  description: string,
  cost: number,
};

const exoticWeponData: ExoticWeapon[] = [
  {
    name: 'Air Pistol',
    description: 'An Exotic Medium Pistol. Fires paint balls, which are typically filled with paint, and thus deal no damage. If you filled the paint balls with Acid however, each Acid Paintball still deals no damage, but lowers the SP of the target\'s armor (worn in the location shot) by one with each successful hit. Great for trying to capture someone alive. Damage dealt by this weapon cannot cause a Critical Injury. Ammunition costs the same as for a normal Medium Pistol, even if filled with Acid.',
    cost: 100,
  }, {
    name: 'Battleglove',
    description: 'A heavy gauntlet covering the hand and forearm. Contains three option slots for Cyberarm or Cyberlimb options. When worn, the options stored in the glove’s slots can be accessed. Putting on a Battleglove and taking one off is an Action. The cost to purchase and install a Cyberarm option into the Battleglove is the same cost as doing so in a Cyberarm. Any options stored in a Cyberarm or meat arm the Battleglove is being worn over are inaccessible while the Battleglove is being worn. Cannot be concealed.',
    cost: 1000,
  }, {
    name: 'Constitution Arms Hurricane Assault Weapon',
    description: 'An Exotic 2 ROF Shotgun. It cannot make Aimed Shots. Its drum holds 16 shots. Reloading this weapon requires using two Actions, and thus can only be done over the course of two turns. Firing this weapon requires BODY 11 or higher unless it is mounted.',
    cost: 5000,
  }, {
    name: 'Dartgun',
    description: 'An Exotic Very Heavy Pistol that can only load Non-Basic Arrow Ammunition. Unlike other weapons that fire Arrows, a Dartgun has a clip of 8 Non-Basic Arrows and must be reloaded just like a typical Very Heavy Pistol.',
    cost: 100,
  }, {
    name: 'Flamethrower',
    description: 'An Exotic Shotgun fired with the Heavy Weapons Skill instead of the Shoulder Arms Skill. Mechanically, the flamethrower is a Shotgun that can only fire incendiary shotgun shells (ammunition cost is also the same as Incendiary Shotgun Shells), except that while your targets are ignited, until they spend an Action to put themselves out, they take 4 damage to their HP at the end of their turns. If they were already on fire, this fire effect replaces one that would deal less damage. Damage dealt by this weapon cannot cause a Critical Injury, and it cannot be used to make Aimed Shots.',
    cost: 500,
  }, {
    name: 'Kendachi Mono-Three',
    description: 'An Excellent Quality Two-Handed Exotic Very Heavy Melee Weapon. With the correct biometric key,damage dealt by this weapon ignores the target\'s armor entirely if it is lower than SP11. Armor with a higher SP is still interacted with as normal. A high-tech katana with a nearly transparent crystal blade. Orbital crystal reinforces its redesigned microscopically serrated edge which vibrates four thousand times a minute when its handle is gripped by a user with a correct biometric key. In the hands of a user without the correct biometric key, the blade will not vibrate, leaving it only an Excellent Quality Two-Handed Exotic Very Heavy Melee Weapon. At no additional cost, any color of laser can be installed into the hilt to refract within the crystal blade, giving it a flaring neon glow. The most popular color is red.',
    cost: 5000,
  }, {
    name: 'Malorian Arms 3516',
    description: 'An Excellent Quality Exotic Very Heavy Pistol. It deals 5d6 damage with a single shot, and comes permanently installed with the Smartgun Link weapon attachment which must be connected (via Interface Plugs or a Subdermal Grip) in order for the weapon to operate. Extremely rare and sought after, this weapon is even more expensive today than it was in the past, when one even can be found for sale.',
    cost: 10000,
  }, {
    name: 'Microwaver',
    description: 'An Exotic Very Heavy Pistol. Instead of dealing damage, on a hit it forces the target to try to beat a DV15 Cybertech Check. If they fail, the GM chooses two pieces of their cyberware or carried electronics to become inoperable for a minute. Cyberlimbs that are rendered inoperable act as their meat counterparts do when they have been dismembered, but they still hang loosely. See Critical Injuries. Ammunition is costless, as it runs off easily rechargeable (1 hour) battery packs that need to be replaced after 8 shots. Extra battery packs are 50eb (Costly).',
    cost: 500,
  }, {
    name: 'Militech "Cowboy" U-56 Grenade Launcher',
    description: 'An Exotic 2 ROF Grenade Launcher. Its magazine holds four grenades. Despite being an Exotic Weapon, it is capable of firing all forms of Grenade Ammunition. Reloading this weapon requires using two Actions, and thus can only be done over the course of two turns. Firing this weapon requires BODY 11 or higher unless it is mounted.',
    cost: 5000,
  }, {
    name: 'Rhinemetall EMG-86 Railgun',
    description: 'An Exotic Assault Rifle that is incapable of Autofire and Aimed Shots. It is fired with the Heavy Weapons Skill instead of the Shoulder Arms Skill. The weapon holds 4 shots. Damage dealt by this weapon ignores the target\'s armor entirely if it is lower than SP11. Armor with a higher SP is still interacted with as normal. Reloading this weapon requires using two Actions, and thus can only be done over the course of two turns.Firing this weapon requires BODY 11 or higher unless it is mounted.',
    cost: 5000,
  }, {
    name: 'Shrieker',
    description: 'An Exotic Very Heavy Pistol. Whenever a user fires this weapon without some form of ear protection, they suffer the Damaged Ear Critical Injury. Instead of dealing damage, on a hit it forces the target to try to beat a DV15 Resist Torture/Drugs Check. If they fail, they suffer the Damaged Ear Critical Injury. Ammunition is costless as it runs off of easily rechargeable (1 hour) battery packs that need to be replaced after 8 shots. Extra battery packs are 50eb (Costly).',
    cost: 500,
  }, {
    name: 'Stun Baton',
    description: 'A One-Handed Exotic Medium Melee Weapon. If damage dealt by it would reduce a target to under 1 HP, they are instead Unconscious at 1 HP. Damage dealt by this weapon cannot cause a Critical Injury and doesn\'t ablate armor.',
    cost: 100,
  }, {
    name: 'Stun Gun',
    description: 'An Exotic Heavy Pistol. If damage dealt by it would reduce a target to under 1 HP, they are instead Unconscious at 1 HP. Damage dealt by this weapon cannot cause a Critical Injury and doesn\'t ablate armor. Ammunition is costless as it runs off easily rechargeable (1 hour) battery packs that need to be replaced after 8 shots. Extra battery packs are 50eb (Costly).',
    cost: 100,
  }, {
    name: 'Tsunami Arms Helix',
    description: 'An Exotic Assault Rifle, fired entirely with the Autofire Skill. It can only be fired using Autofire and it cannot make Aimed Shots or fire in single shot. It holds 40 bullets. When fired, the Helix consumes 20 bullets with every attack. If you hit, you roll 2d6 for damage, and multiply it by the amount you beat the DV to hit your target, up to a maximum of 5. Reloading this weapon requires using two Actions, and thus can only be done over the course of two turns. Firing this weapon requires BODY 11 or higher unless it is mounted.',
    cost: 5000,
  },
];

type Armor = {
  type: string,
  sp: number,
  penalties: string,
  description: string,
  cost: number,
};

const armorData: Armor[] = [
  {
    type: 'Leathers',
    sp: 4,
    penalties: '',
    description: 'Thin leather with reinforced pads at shoulders, hips, and gut. Favored by Nomads and other \'punks who ride bikes. This also includes all those road-warrior wannabes wearing open-butt chaps and random sports equipment.',
    cost: 20,
  }, {
    type: 'Kevlar',
    sp: 7,
    penalties: '',
    description: 'The favored protection for the past 90 years. To quote DuPont: Kevlar® is a heat-resistant, synthetic, lightweight fiber that delivers high tensile strength that brings improved protection and performance across a range of industries and applications. Like when people are trying to stab or shoot you. Can be made into clothes, vests, jackets, business suits, and even bikinis.',
    cost: 50,
  }, {
    type: 'Light Armorjack',
    sp: 11,
    penalties: '',
    description: 'A combination of Kevlar® and plastic meshes inserted into the weave of the fabric. Armorjack provides superior protection, especially against high-velocity bullets.',
    cost: 100,
  }, {
    type: 'Bodyweight Suit',
    sp: 11,
    penalties: '',
    description: 'Skinsuit with impact absorbing, sintered armorgel layered in key body areas. Surprisingly, they are also breathable and quite comfortable. Besides giving you a measure of protection, a Bodyweight Suit also has a place to store your Cyberdeck and supports your Interface Plugs so they stay out of the way while you\'re busy brain-burning that fool who just dared to pop a Hellhound on you. Many Netrunners wear clothing over their Bodyweight Suits, but plenty don\'t. It\'s a matter of personal style. Unlike other armor, a Bodyweight Suit isn\'t bought in two pieces, and must always be worn on both your body and head location. Each location has its own SP11. When repaired, both pieces are repaired at the same time. You can\'t wear more than one Bodyweight Suit. Wearing a Bodyweight Suit adds one Hardware only Option Slot to a Cyberdeck connected to it. Hardware installed in the Bodyweight Suit cannot be accessed if the armor isn\'t worn and can only take up 1 Option Slot.',
    cost: 1000,
  }, {
    type: 'Medium Armorjack',
    sp: 12,
    penalties: '-2 REF, DEX, and MOVE',
    description: 'Heavier Armorjack, with solid plastic plating, reinforced with thicker Kevlar® mesh. Typical Street wear, this combines decent protection with a decent ost.',
    cost: 100,
  }, {
    type: 'Heavy Armorjack',
    sp: 13,
    penalties: '-2 REF, DEX, and MOVE',
    description: ' The thickest Armorjack, combining denser Kevlar® and a layered mix of plastic and mesh weaves. It stops all but the heaviest attacks, but costs a pretty eb.',
    cost: 500,
  }, {
    type: 'Flak',
    sp: 15,
    penalties: '-4 REF, DEX, and MOVE',
    description: 'This is the 21st century version of the time-honored flak vest and pants with metal plates designed to provide protection from high explosive weaponry, artillery, grenades, shotguns, and anti-personnel mines. Modern flak will also stop many of the higher caliber rounds from automatic rifles.',
    cost: 500,
  }, {
    type: 'Metalgear',
    sp: 18,
    penalties: '-4 REF, DEX and MOVE',
    description: 'You know how Evil Empire Storm Troopers just seem to stand there and take the hit? Metalgear® is the Dark Future equivalent of that type of armor: solid metal and plastic plates on a mesh body cover. Metalgear® will stop almost anything, but you\'re going to be easier to hit than a one-legged bantha in a potho race.',
    cost: 5000,
  },
];

type Gear = {
  name: string,
  description: string,
  cost: number,
};

const gearData: Gear[] = [
  {
    name: 'Agent',
    description: 'Self-adaptive-AI powered smartphone; that "learns" how best to fit your needs simply by interacting with you. While not a true AI, it is more than capable of replacing any need for a secretary. When you sit back and allow your Agent to manage your life, everything is easier, including making sure you have time to do what you need to do (crimes, killing people, getting away with it, and so forth) instead of going to the store to get something you forgot. There are many reasons why almost everyone has one. In addition to all this, an Agent gives the user +2 to their Library Search Skill and +2 to their Wardrobe & Style Skill, but only if you wear the clothes your Agent suggests for you, which change every season. Multiple Agents don\'t multiply these bonuses.',
    cost: 100,
  }, {
    name: 'Airhypo',
    description: 'Easy to use drug distribution platform which uses a quick burst of compressed air to force a drug through the skin. Allows user to use an Action to administer a single dose of a desired drug to a willing target, or try to make a Melee Weapon Attack to administer a single dose to an unwilling target on a hit instead of dealing damage. Reloading the Airhypo with a dose of your desired drug isn\'t an Action.',
    cost: 50,
  }, {
    name: 'Anti-Smog Breathing Mask',
    description: 'Useful for filtering out toxins and smoke from the local environment. User is immune to the effects of toxic gasses, fumes, and all similar dangers that must be inhaled to affect the user.',
    cost: 20,
  }, {
    name: 'Audio Recorder',
    description: 'Device records up to 24 hours of audio before its output fills up a standard Memory Chip stored in the device.',
    cost: 100,
  }, {
    name: 'Auto Level Dampening Ear Protectors',
    description: 'Compact ear protection. When worn, user is immune to deafness or other effects caused by dangerously loud noises, like those produced by a flashbang.',
    cost: 1000,
  }, {
    name: 'Binoculars',
    description: 'You look through them. They double or triple the size of what you are seeing.',
    cost: 50,
  }, {
    name: 'Braindance Viewer',
    description: 'Allows the user to experience braindance content. Braindances are digital recordings of an experience which you view through the eyes of the actor. The experience includes all the subject\'s senses, and you feel every emotion felt, for better or worse.',
    cost: 1000,
  }, {
    name: 'Bug Detector ',
    description: 'Device beeps when user is within 2m/yds of a tap, bug, or other listening device.',
    cost: 500,
  }, {
    name: 'Carryall',
    description: 'Heavy ripstop nylon bags of varying sizes, from messenger to nearly man-sized duffel bags.',
    cost: 20,
  }, {
    name: 'Chemical Analyzer',
    description: 'Can test substances as an Action to find their precise chemical composition, identifying most substances instantly from a wide database of samples.',
    cost: 1000,
  }, {
    name: 'Computer',
    description: 'Laptop or desktop computer, used mostly for comfortable word processing and surfing the Data Pool.',
    cost: 50,
  }, {
    name: 'Cryopump',
    description: 'A Cryopump is a briefcase-sized tool containing a body bag hooked up to a powerful pump. Once willing/unconscious targets have been placed into the bag and hooked up to the pump as an Action, the pump forces a hyper-cooled chemical fluid into the bag, draining one of the Cryopump\'s charges per target put in stasis (one per person, if the Cryopump can accept multiple people). While in stasis, targets are unconscious and no longer roll any Death Saves for up to a week, as long as they remain inside the bag and the bag has at least 1 HP. A Character in a cryopump bag is considered to be behind a piece of cover that has 15 HP. The bag\'s transparent top and gloves molded into the lining allow the target to undergo surgery and be stabilized while in stasis, which is much less dangerous to the patient. A standard Cryopump has only 1 charge and can only hold a single roughly humansized target. Refueling a Cryopump costs 50eb (Costly) per charge. A Character who is not a Medtech cannot operate a Cryopump.',
    cost: 5000,
  }, {
    name: 'Cryotank',
    description: 'A Cryotank is a human-sized container which can hold a fully grown adult. Assuming the Medtech succeeds at a DV13 Medical Tech Check, the Cryotank keeps 1 person in stasis as long as desired. While in the Cryotank, they are considered to be unconscious, but they heal at double the normal rate as long as they remain inside the tank and the tank has at least 1 HP. A Character in a Cryotank is considered to be behind a piece of cover that has 30 HP. A Character who is not a Medtech cannot operate a Cryotank.',
    cost: 5000,
  }, {
    name: 'Cyberdeck (Poor Quality)',
    description: 'Modular platform that Programs and Hardware are installed on for the purpose of Netrunning. This cyberdeck has 5 slots to install Programs and Hardware. Requires Interface Plugs and Neural Link for a Netrunner to operate.',
    cost: 500,
  }, {
    name: 'Cyberdeck',
    description: 'Modular platform that Programs and Hardware are installed on for the purpose of Netrunning. This cyberdeck has 7 slots to install Programs and Hardware. Requires Interface Plugs and Neural Link for a Netrunner to operate.',
    cost: 500,
  }, {
    name: 'Cyberdeck (Excellent Quality)',
    description: 'Modular platform that Programs and Hardware are installed on for the purpose of Netrunning. This cyberdeck has 9 slots to install Programs and Hardware. Requires Interface Plugs and Neural Link for a Netrunner to operate.',
    cost: 500,
  }, {
    name: 'Disposable Cell Phone',
    description: 'There are still billions of the things around. A good choice for Fixers and other people who don\'t want to be tracked.',
    cost: 50,
  }, {
    name: 'Drum Synthesizer',
    description: ' Flat plastic pads of varying sizes, linked by cables to a central processor. Can simulate almost any kind of drum. Requires some type of amplification to be heard.',
    cost: 500,
  }, {
    name: 'Duct Tape',
    description: 'Comes in many colors and optionally can glow in the dark. Glowing duct tape is often used to mark tunnels, dead drops, or caches. It glows in the dark even if there has been no light exposure.',
    cost: 20,
  }, {
    name: 'Electric Guitar or another Instrument',
    description: 'Use your imagination. But remember that you will need an amp to be heard with any electronic-based instrument.',
    cost: 500,
  }, {
    name: 'Flashlight',
    description: 'Rechargeable. 100m/yd beam, lasts up to 10 hours on a charge.',
    cost: 20,
  }, {
    name: 'Food Stick',
    description: 'Grainy, dried food bar that comes in a variety of (awful) flavors. One meal.',
    cost: 10,
  }, {
    name: 'Glow Paint',
    description: ' Glow in the dark paint for marking locations and creating art. Comes in a spray can. Also good for tagging.',
    cost: 20,
  }, {
    name: 'Glow Stick',
    description: 'Light tube to illuminate a 4m/yd area for up to 10 hours. One use only.',
    cost: 10,
  }, {
    name: 'Grapple Gun',
    description: 'When wielded in a hand, user as an Action can fire a rocket propelled grapple that will attach securely to any "thick" cover up to 30m/yds away. Line can only support two times the user\'s body weight, and has 10 HP. The user negates the normal movement penalty for climbing when they climb this line, and can retract the line without an Action, including as they climb. When used as a grapple, user can\'t hold anything in the hand used to wield the grapple gun. Ineffective as a weapon, and cannot be used to make the Grab Action.',
    cost: 100,
  }, {
    name: 'Handcuffs',
    description: 'Book \'em, Danno. Can be broken easily if your BODY is higher than 10.',
    cost: 50,
  }, {
    name: 'Homing Tracer',
    description: 'Device can follow a linked tracer up to 1-mile away. Comes with a free button sized linked tracer. Replacement linked tracers are 50eb.',
    cost: 500,
  }, {
    name: 'Inflatable Bed & Sleep-bag',
    description: 'It\'s a self-inflating air mattress than comes packed with a thin sleeping bag. The whole thing folds to a 6"x6" package for easy storage.',
    cost: 20,
  }, {
    name: 'Kibble Pack',
    description: 'One foil package of dry, pet food-like cereal or wafers equivalent to a single meal. Usually identified by number rather than the fake appetizing label and description.',
    cost: 10,
  }, {
    name: 'Linear Frame ∑ (Sigma)',
    description: 'Powered exoskeleton, giving the user even more tremendous strength. • User increases their BODY to 14 while plugged into the frame. This cannot increase the user\'s BODY to 15 or higher. This increase in BODY does not increase the user\'s HP or change their Death Save. • Requires 2 installation of Interface Plugs to operate.',
    cost: 5000,
  }, {
    name: 'Linear Frame ß (Beta)',
    description: 'Powered exoskeleton, giving the user tremendous strength. • User increases their BODY to 12 while plugged into the frame using two installations of Interface Plugs. This cannot increase the user\'s BODY to 13 or higher. This increase in BODY does not increase the user\'s HP or change their Death Save. • Requires 1 installations of Interface Plugs to operate.',
    cost: 1000,
  }, {
    name: 'Lock Picking Set',
    description: 'A small pouch of tools for cracking mechanical locks',
    cost: 20,
  }, {
    name: 'Medscanner',
    description: 'Scanner with external probes and contacts that diagnoses injury and illness, assisting user in medical emergencies not requiring Surgery. User adds +2 to their First Aid and Paramedic Skills. This doesn\'t stack with itself.',
    cost: 1000,
  }, {
    name: 'Medtech Bag',
    description: 'Medical toolkit that includes everything from dermal staplers to spray skin applicators to sterile scalpels. All you need to save lives using your skills and training.',
    cost: 100,
  }, {
    name: 'Memory Chips',
    description: 'Thin wafers of doped plastic that store information in all forms. Some of these are larger than others.',
    cost: 10,
  }, {
    name: 'MRE',
    description: 'Self-heating plastic and foil meal bag. Add water, snap the tab on the top, and in 2 minutes you have something that resembles a single hot, nourishing meal.',
    cost: 10,
  }, {
    name: 'Personal CarePak',
    description: 'Toothpaste-loaded toothbrush, all body wet-wipes, depilatory paste, comb, etc.',
    cost: 20,
  }, {
    name: 'Pocket Amplifier',
    description: 'About the size of a large book, this rechargeable amplifier delivers sound up to 100m/yd for up to 6 hours. Can support two instruments.',
    cost: 50,
  }, {
    name: 'Radar Detector',
    description: 'Device beeps if an active radar beam is present within 100m/yds.',
    cost: 500,
  }, {
    name: 'Radio Communicator',
    description: 'Earpiece allowing user to communicate via radio, 1-mile range',
    cost: 100,
  }, {
    name: 'Radio Scanner/Music Player',
    description: 'Music player can link to the Data Pool to listen to the hottest music, or play directly from a Memory Chip. User can also scan all radio bands within a mile that are currently being used and tune into them, though some channels might require a Descrambler to understand.',
    cost: 50,
  }, {
    name: 'Road Flare',
    description: 'Lights an area of 100m/yards for 1 hour. Different colors. One use.',
    cost: 10,
  }, {
    name: 'Rope (60m/yds)',
    description: 'Nylon rope. Can come in colors if desired. Holds up to 800lbs (360kg).',
    cost: 20,
  }, {
    name: 'Scrambler/Descrambler',
    description: 'Allows user to scramble outgoing communications so they cannot be understood without a descrambler, which is also included at no extra charge.',
    cost: 500,
  }, {
    name: 'Smart Glasses',
    description: 'Contains two option slots for Cybereye options. When worn, Smart Glasses give the user access to the benefits of these options. When cybereye options are installed into the glasses, they always count as if they were paired, and it costs the same as installing the option once in a cybereye. You can only wear a single pair at a time. Enthusiasts often replace the frames of their Smart Glasses with nicer ones, as they aren\'t the prettiest out of the box.',
    cost: 500,
  }, {
    name: 'Tech Bag',
    description: 'Small bag of tools for fixing electronics and machines. Includes a Techtool, electrical parts like tape and wire wraps, asst. screws and bolts, plug in modules for repairs, heat torch, 2 small prybars, and hammer.',
    cost: 500,
  }, {
    name: 'Techscanner',
    description: ': Scanner diagnoses a wide variety of machinery and electronics, assisting the user in repairs, or other technical work. User adds +2 to their Basic Tech, Cybertech, Land Vehicle Tech, Sea Vehicle Tech, Air Vehicle Tech, Electronics/Security Tech, and Weaponstech Skills. This doesn\'t stack with itself.',
    cost: 1000,
  }, {
    name: 'Techtool',
    description: 'Small bag of tools for fixing electronics and machines. Includes a Techtool, electrical parts like tape and wire wraps, asst. screws and bolts, plug in modules for repairs, heat torch, 2 small prybars, and hammer.',
    cost: 100,
  }, {
    name: 'Tent & Camping Equipment',
    description: 'Small one-person tube tent with plastic stakes, one self-heating, rechargeable pot to boil water (takes 5 min to recharge, lasts 2 hours) and a cheap metal spork that couldn\'t hurt a fly',
    cost: 50,
  }, {
    name: 'Vial of Biotoxin',
    description: 'An entire vial of biotoxin can be smeared on any Light Melee Weapon as an Action. For the next 30 minutes after application, instead of dealing the weapon\'s typical damage, anyone meat hit by the biotoxin-coated Light Melee Weapon must instead attempt to beat a DV15 Resist Torture/Drugs Check. Anyone who fails is dealt 3d6 damage directly to their HP. Their armor isn\'t ablated because it wasn\'t interacted with.',
    cost: 500,
  }, {
    name: 'Vial of Poison',
    description: 'An entire vial of poison can be smeared on any Light Melee Weapon as an Action. For the next 30 minutes after application, instead of dealing the weapon\'s typical damage, anyone meat hit by the poisoned Light Melee Weapon must instead attempt to beat a DV13 Resist Torture/Drugs Check. Anyone who fails is dealt 2d6 damage directly to their HP. Their armor isn\'t ablated because it wasn\'t interacted with.',
    cost: 100,
  }, {
    name: 'Video Camera',
    description: 'When held in a hand, user can record up to 12 hours of video and audio before its output fills up a standard Memory Chip stored in the device.',
    cost: 100,
  }, {
    name: 'Virtuality Goggles',
    description: 'Headset that projects cyberspace imagery over your view of the world around you. Highly advised for Netrunners. ',
    cost: 100,
  },
];

export {
  meleeWeaponData,
  rangedWeaponData,
  rangedWeaponTypes,
  weaponAttachmentsData,
  armorData,
  exoticWeponData,
  gearData,
};
