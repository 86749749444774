import React from 'react';
import { Card } from 'components/UI';

import { meleeWeaponData } from '../equipmentData';

import styles from './styles.module.scss';

const MeleeWeaponBlock = () => (
  <Card>
    <div className={styles.cardHeader}>
      Melee Weapon
    </div>
    <div className={styles.card}>
      <div
        className={styles.item}
      >
        <div className={styles.type}>
          Weapon Type
        </div>
        <div className={styles.damage}>
          Damage
        </div>
        <div className={styles.rof}>
          ROF
        </div>
        <div className={styles.concealability}>
          Can hide?
        </div>
        <div className={styles.cost}>
          Cost
        </div>
      </div>
      {meleeWeaponData.map((meleeWeapon) => (
        <div
          key={meleeWeapon.type}
          className={styles.item}
        >
          <div className={styles.type}>
            {meleeWeapon.type}
          </div>
          <div className={styles.damage}>
            {meleeWeapon.damage}
          </div>
          <div className={styles.rof}>
            {meleeWeapon.rof}
          </div>
          <div className={styles.concealability}>
            {meleeWeapon.concealable ? 'Yes' : 'No'}
          </div>
          <div className={styles.cost}>
            {`${meleeWeapon.cost}eb`}
          </div>
        </div>
      ))}
    </div>
  </Card>
);

export default MeleeWeaponBlock;
