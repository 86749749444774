import React from 'react';
import NightMarket from './NightMarket';
import RandomEncounter from './RandomEncounter';
// import NetArch from './NetArch';
import Drugs from './Drugs';

const GeneratorsScreen = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'space-evenly',
    alignContent: 'flex-start',
    gap: '4rem',
    flexWrap: 'wrap',
  }}
  >
    <NightMarket />
    <RandomEncounter />
    <Drugs />
    {/* <NetArch /> */}
  </div>
);

export default GeneratorsScreen;
