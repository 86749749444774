import type { DrugFeature } from 'types';

const drugFeaturesData: DrugFeature[] = [
  {
    name: 'Lessened Withdrawal Symptoms',
    costModifier: 10,
    description: 'Only usable for addictive drugs. Withdrawal symptoms are half as bad as they would normally be. A drug with the “Severe Withdrawal Symptoms” side effect cannot contain this feature.',
  }, {
    name: 'Longer Duration',
    costModifier: 10,
    description: 'When the drug is taken, roll the duration and then double the result.',
  }, {
    name: 'Timed Duration',
    costModifier: 10,
    description: 'Drug duration lasts as long as is specified in the description, but no longer than the maximum duration. For instance, a drug with a medium duration can last anywhere from ten to sixty minutes, but no longer. Drugs with this side effect usually require exact dosages based on the weight of the subject. Note that giving a subject too much of a drug with this feature will produce an immediate overdose, while giving a subject too little will have no effect at all.',
  },
];

export default drugFeaturesData;
