import React from 'react';

import StatOption from '../../StatOption';

import type { Stats } from '../../../types';

import styles from './styles.module.scss';

type CompleteStatsTableProps = {
  stats: Stats,
};

const CompleteStatsTable = ({ stats }: CompleteStatsTableProps) => (
  <div className={styles.statsTable}>
    {Object.keys(stats).map((stat) => (
      <StatOption
        key={stat}
        title={stat}
        value={stats[stat as keyof typeof stats]}
      />
    ))}
  </div>
);

export default CompleteStatsTable;
