import React, { useEffect } from 'react';

import ButtonToRandom from '../../../components/UI/ButtonToRandom';

import useRearandom from '../../../utils/rerandomHook';

import styles from './FactItem.module.scss';

type FactItemProps = {
  generateRandom: boolean,
  header: string,
  table?: string[]
};

const FactItem = (
  { generateRandom, header, table = [] }: FactItemProps,
) => {
  const [randomValue, triggerRerandom] = useRearandom(table.length);

  useEffect(() => {
    if (generateRandom) {
      triggerRerandom();
    }
  }, [generateRandom]);

  return (
    <div className={styles.factItem}>
      <div className={styles.header}>
        {header}
        <ButtonToRandom onClick={triggerRerandom} />
      </div>
      <div className={styles.body}>
        {table[randomValue]}
      </div>
    </div>
  );
};

FactItem.defaultProps = {
  table: [],
};

export default FactItem;
