import type { DrugSideEffect } from 'types';

const drugTimedSideEffectsData: DrugSideEffect[] = [
  {
    name: 'Acne',
    costModifier: 2,
    description: 'The drug causes the user’s face to break out due to excess oil production or stress. Normally, this could start out as a small problem, but frequent use of the drug can cause a more serious acne problem to develop (negative modifiers to ATT, as well as the increased chance of infection).',
  }, {
    name: 'Aggressive Behavior',
    costModifier: 6,
    description: 'A character experiencing such behavior will act to satisfy his own needs with little regard for the feelings of others, is irritable and easily provoked. He will not back down from a fight, and may even attempt to initiate one. He will have no respect for authority, and any suggestion that he is incapable of anything will be seen as a challenge. Gee, sounds like your typical Cyberpunk character…',
  }, {
    name: 'Bad Breath',
    costModifier: 2,
    description: 'This side effect indicates that halitosis develops. The character’s breath will be incredibly rank, granting him a -3 modifier to all social rolls involving direct, face-to-face contact. No amount of brushing or listerine will help.',
  }, {
    name: 'Bloodshot Eyes',
    costModifier: 2,
    description: 'The drug induces red, puffy, bloodshot eyes. Vision is not impaired.',
  }, {
    name: 'Cold Sweats',
    costModifier: 2,
    description: 'This side effect causes the character to sweat for no apparent reason. No amount of anti-perspirant will do, since every pore on his body is working a double shift. Sweaty characters suffer no real modifications, though they may grow dehydrated or get the chills in cold or air-conditioned environments (-1 to all checks involving Reflex). Their palms will be slippery, and they will stink. Incredibly sweaty characters may also incur negative modifiers to social rolls.',
  }, {
    name: 'Constipation',
    costModifier: 2,
    description: 'Constipation prevents a character from performing normal bowel movements for the duration of the side effect. No matter how bad the character feels he has to go, no amount of straining, grunting, or groaning will help. The character will feel as if he is carrying a lead weight in his colon, and will receive a -1 modifier to all actions involving physical exertion, including Initiative rolls.',
  }, {
    name: 'Cowardice',
    costModifier: 6,
    description: 'The character will exhibit his most timid side, and will be more likely to run from trouble. Any time the character tries to accomplish a task which may cause him physical harm, he must make a Save against Cool at -2 in order to show some guts and stick around. If he fails, he must run or hide, whichever is more convenient.',
  }, {
    name: 'Dandruff/Eczema',
    costModifier: 2,
    description: 'The user’s skin cracks and peels away. This side effect could be confined to the user’s scalp, a specific part of the body, or the body as a whole. Complications include reduced Attractiveness. In addition, the user tends to leave little bits of himself behind at crime scenes, and police will have no problem isolating his DNA.',
  }, {
    name: 'Dehydration',
    costModifier: 2,
    description: 'The subject cannot retain water at all, and will suffer from symptoms such as cotton mouth and dry eyes. This side effect is usually accompanied by either nausea, diarrhea, diuretic, cold sweats, or a combination of the four.',
  }, {
    name: 'Delusions',
    costModifier: 5,
    description: 'Anyone suffering from delusions is under the impression that something that is highly unlikely is actually happening. Such delusions can be as minor as believing that Saburo Arasaka is controlled by aliens, or as serious as believing that you actually ARE Saburo Arasaka.',
  }, {
    name: 'Depression',
    costModifier: 4,
    description: 'The character becomes depressed, and doesn’t feel like doing anything. Anything he attempts to do will suffer a -2 modifier, because he just doesn’t really care. Severe cases of depression can cause feelings of hopelessness, and characters who are normally unstable may feel like dropping the final curtain by committing suicide.',
  }, {
    name: 'Diarrhea',
    costModifier: 4,
    description: 'The drug causes sudden, painful, and explosive diarrhea. The unlucky soul will be unable to control his bowels for more than a few moments at a time.',
  }, {
    name: 'Difficult Respiration',
    costModifier: 6,
    description: 'Anyone experiencing the effect of difficult respiration is suffering from either shortness of breath, asthma-like symptoms, a tightening of the diaphragm muscles, or a combination of the three. On a roll of 1 on 1D10 indicates the subject cannot, in fact, breathe at all, and will suffocate unless proper medical attention has been administered. Running is out of the question, as is most other high- and low-impact exercise',
  }, {
    name: 'Diuretic',
    costModifier: 2,
    description: 'The drug increases the amount of urine produced and secreted by the body. The user affected by this will exhibit excessive urination, at least once per hour of the effect’s duration, if not more.',
  }, {
    name: 'Dizziness',
    costModifier: 4,
    description: 'The drug causes severe dizziness. The character suffering this effect will have trouble walking in a straight line or, for that matter, standing. This results in the user’s Reflex and MA being halved for the duration of the side effect. In addition, he looks damn weird as he lurches around, bumping into objects and people.',
  }, {
    name: 'Easily Bruised',
    costModifier: 2,
    description: 'Even small bumps and thumps leave large bruises on the user. These bruises tend to be painful and noticeable, but don’t affect the user otherwise.',
  }, {
    name: 'Excessive Salivation',
    costModifier: 2,
    description: 'The drug causes the user’s salivary glands to work double time. Unless he continues to swallow his saliva as it is produced, it will dribble down his chin and onto his body in an incredibly disgusting fashion. This side effect can also be used to emulate the “mad dog” effect of foaming at the mouth.',
  }, {
    name: 'Flatulence',
    costModifier: 2,
    description: 'The user gets terrible abdominal gas, and must vent uncontrollably for the duration. While being mildly painful, the real disadvantage to this side effect comes with the stench it causes, as well as the potential embarrassment. Then again, there are people who enjoy this sort of thing, so who am I to judge?',
  }, {
    name: 'Hair Loss',
    costModifier: 2,
    description: 'The drug causes hair loss in both men and women. Eighty or ninety percent of the user’s head hair falls out effortlessly in large clumps. Most users of the drug are left with a mangy-looking scalp and small patches of unhealthy-looking hair here and there, modifying the character’s ATT by -2 or more.',
  }, {
    name: 'Hallucinations',
    costModifier: 5,
    description: 'This side effect causes unlooked-for hallucinations. Such hallucinations can be traumatic or harmless, depending on the situation. Any Hallucinogenic drug with this side effect causes a “bad trip” 95% of the time.',
  }, {
    name: 'Headaches',
    costModifier: 4,
    description: 'The drug causes blinding headaches. The character cannot focus on anything for long periods of time, and has trouble seeing. Loud noises and bright lights will make this side effect worse.',
  }, {
    name: 'Heartburn / Acid indigestion',
    costModifier: 2,
    description: 'The drug causes an excess production of stomach acids. While this is uncomfortable and rarely serious, it can result in ulcers in the stomach and esophagus.',
  }, {
    name: 'Hives',
    costModifier: 4,
    description: 'The character develops itchy and painful hives all over his body. Not only does he suffer from the “Itchy” side-effect, but the have-related rash covering his body is obvious and unattractive, as well as being incredibly uncomfortable.',
  }, {
    name: 'Impotence/Frigidity',
    costModifier: 2,
    description: 'While the user might desire to have sex, he or she is physically incapable for the duration of the side effect.',
  }, {
    name: 'Increased Pain Sensitivity',
    costModifier: 6,
    description: 'The drug makes little pains feel much, much bigger. Big pains will incapacitate the character as he writhes around in agony. While this effect lasts, the character makes all stun/shock saves at -2, and gains a -4 to resist torture.',
  }, {
    name: 'Insomnia',
    costModifier: 4,
    description: 'The character cannot sleep, no matter how tired he is. Characters suffering from lack of sleep tend to be cranky and unable to concentrate. Subtract -1 from all actions until the character gets at least six hours of sleep.',
  }, {
    name: 'Internal Bleeding',
    costModifier: 4,
    description: 'For some reason, the drug causes the user’s guts to hemorrhage and bleed. For every two hours of side effect duration (or every fraction thereof), the user takes one box of damage. Blood may show up in the users stools, urine, and/or vomit.',
  }, {
    name: 'Irrational Fear',
    costModifier: 10,
    description: 'Anyone suffering this side effect will develop a sudden intense fear of a random object, thing, or circumstance, determined by the Game Master. The character will stop at nothing to put as much distance between himself and the object/situation he fears. If escape is impossible, he will fight like a cornered animal.',
  }, {
    name: 'Itchy',
    costModifier: 3,
    description: 'The character suffers from a terrible itchy sensation all over his body. No amount of scratching or hydrocortisone will stop the irritation. Due to trouble concentrating, the character is at -1 to all activities. Habitual users of drugs that cause itching are usually walking scabs.',
  }, {
    name: 'Kidney/Liver failure',
    costModifier: 8,
    description: 'Taking this drug will cause a user’s kidneys and liver to work overtime while filtering his blood stream of the nasty substance. This can cause these organs to fail. Roll 1d10. On a roll of 1, the user’s kidneys and/or liver have stopped functioning. Unless proper medical attention is sought, the character will die within twenty-four hours due to poisons running unchecked through his bloodstream.',
  }, {
    name: 'Lack Of Concentration',
    costModifier: 5,
    description: 'The character cannot concentrate on any one thing for more than a couple minutes. His mind is restlessly wandering, and he is easily distracted. For the duration, he suffers a -3 to all actions.',
  }, {
    name: 'Light Sensitivity',
    costModifier: 2,
    description: 'The drug dilates the pupils and induces red, bloodshot eyes. The character is at a loss in any situation involving a bright light (-2 to all actions in daylight) unless wearing sunglasses or equipped with flare compensation. Characters with cybereyes are still affected by bright lights, since this side effect focuses on the optic nerve rather than the eye itself.',
  }, {
    name: 'Loss Of Appetite',
    costModifier: 2,
    description: 'The character will not eat, and will not feel hungry. People hooked on drugs with this side effect tend to be thin and emaciated. Characters who don’t eat will starve within a couple weeks if not given treatment. This is reflected in the loss of one BODY point every three or four days. When the character’s BODY reaches 0, he will die. If normal food consumption is resumed, the character will recover one BODY point a day until he reaches his original score',
  }, {
    name: 'Loss Of Inhibition',
    costModifier: 4,
    description: 'Anyone suffering from Loss of Inhibition will do things that they normally would be adverse to doing. For instance, someone who wouldn’t normally consider raping a helpless teenage girl might think it’s a good idea at the time and throw inhibitions to the wind. Much of this side effect must be role-played. If game mechanics enter into it, however, the player must roll under his character’s COOL score on a 1D10 in order to consider his actions and stop. Modify the character’s COOL score by the drug’s STR, using the number as a negative (instead of positive) modifier.',
  }, {
    name: 'Memory Loss',
    costModifier: 4,
    description: 'The user will have no memory of what has happened while under the effects of the drug. If he tries really hard (Very Difficult Intelligence check), he can remember vague events, but no specifics (ie, names, phone numbers, conversations, etc).',
  }, {
    name: 'Nausea',
    costModifier: 4,
    description: 'The character must make a stun/shock save at -2 or throw up. The nausea is painful and will extend into dry heaves. For the duration the character may not safely eat or drink anything. If he attempts to eat anything, a new stun/shock save at -2 is in order. Failure indicates more vomiting.',
  }, {
    name: 'Soft Numbness',
    costModifier: 4,
    description: 'This drug causes the user’s extremities to become numb and void of feeling. Fine manipulation is impossible, and walking is impossible unless the character is given support.',
  }, {
    name: 'Hard Numbness',
    costModifier: 6,
    description: 'This drug causes the user’s extremities to become numb and void of feeling. Lack of feeling extends to the whole body. Since the user is experiencing no pain, the GM should secretly keep track of his wounds.',
  }, {
    name: 'Paralysis',
    costModifier: 6,
    description: 'Characters suffering from paralysis cannot move, speak, and they may have trouble breathing. Paralysis can take many forms, from complete muscle relaxation to a painful tightening of the entire muscular system. While paralyzed, the user is completely helpless and can perform no actions.',
  }, {
    name: 'Paranoia',
    costModifier: 5,
    description: 'While this side effect lasts, the user is convinced that everyone and everything is out to get him. While most of this will have to be role-played, a few Cool rolls at appropriate times may be called for.',
  }, {
    name: 'Psychotic Rage',
    costModifier: 10,
    description: 'A result of some of the worst combat drugs. The user goes berserk, attacking the nearest friend or enemy, he doesn’t really care which. If a Difficult Resist Torture/Drugs roll is made, the user may specifically ignore a friend or ally and focus his attention on a target of his choice.',
  }, {
    name: 'Reduced Attribute',
    costModifier: 5,
    description: 'This drug reduces an attribute by an amount equal to the drug strength for an amount of time equal to the duration. Attributes that can be reduced include Reflex, Intelligence, Body, Movement Allowance, Empathy, Cool, and Tech. If a physical attribute (Reflex, Body, MA) is reduced below zero, make a Death Save or die. If a mental attribute (Intelligence, Tech) is reduced below zero, the user slips into a deep coma for the duration. In the case of Empathy being lowered below zero, assume that user is sociopathic and is liable to do anything. A Cool score below zero indicates total nervous breakdown.',
  }, {
    name: 'Reduced Awareness',
    costModifier: 6,
    description: 'The user of a drug with this effect is oblivious to the world around him. While Sense Reduction only affects one of the senses, Reduced Awareness affects them ALL. All skill rolls which rely on any of the user’s senses suffer a -4 modifier, and all Awareness rolls are made at -6.',
  }, {
    name: 'Ringing In Ears',
    costModifier: 2,
    description: 'Due to severe tinnitus, the user has trouble hearing soft to medium volume sounds. As such, he suffers -2 to all noise-related awareness rolls.',
  }, {
    name: 'Runny Nose',
    costModifier: 2,
    description: 'Users suffering from a runny nose are constantly sniffling and blowing their noses. This doesn’t really help, though. Roll 1d10. On a roll of 1 or 2, the character develops a bloody nose. While having no real game effect, he will be dripping blood everywhere. It will take between three and five minutes of constant pressure to stop the nose from bleeding, but the character still has a serious case of the sniffles.',
  }, {
    name: 'Sense Reduction',
    costModifier: 4,
    description: 'The drug reduces the user’s sense of touch, smell, hearing, taste, or sight for the duration of the side effect. All rolls made which rely on the sense in question receive a -4 modifier. For example, someone suffering from Sense Reduction: Sight would be at a -4 to shooting his weapon due to blurred vision or near-blindness. All awareness rolls made with the appropriate sense are also at -4.',
  }, {
    name: 'Sexual Arousal',
    costModifier: 4,
    description: 'The drug turns the user on, yet to a lesser degree than most aphrodisiacs. Anyone attempting to seduce the user gains a +1 bonus. In addition, all outward signs of arousal are visible and quite obvious. If the drug with this side effect is an aphrodisiac, roll 1d10. On a result of 1 or 2, a permanent state of arousal occurs, and doesn’t go away…EVER. Is that a truncheon in your pocket, or are you just happy to see me?',
  }, {
    name: 'Sleepy',
    costModifier: 4,
    description: 'The drug makes the user sleepy. A character who is overcome with sleepiness must make a successful stun/shock save or fall into a deep, dreamless sleep. Soporifics with this side effect induce a deep, coma-like state that can last for 1d10 days.',
  }, {
    name: 'Strange Skin Pallor',
    costModifier: 2,
    description: 'The drug tints the user’s skin a strange color. This can indicate that the drug makes its user’s skin pale, or that his lips turn blue, but it can also indicate a more severe chromatic variation to his epidermis. Like, say, your whole body is blue. Like a smurf.',
  }, {
    name: 'Suicidal Tendencies',
    costModifier: 5,
    description: 'The user becomes depressed or agitated, convinced that life is meaningless and that it might as well end as soon as possible. While possessed of suicidal tendencies, a character must roll under his COOL score -2. If the roll is failed, he must take immediate suicidal action. The method he uses is whichever is most convenient, be it putting a gun in his mouth and pulling the trigger, or jumping off his fifth floor balcony into the moving traffic below. Needless to say, users of such drugs eventually do society a favor.',
  }, {
    name: 'Technicolor Excreta',
    costModifier: 2,
    description: 'The user’s bodily waste, be it urine or feces or both, turns a strange color. This effect lasts twice as long as the drug duration.',
  }, {
    name: 'The Munchies',
    costModifier: 2,
    description: 'The drug with this side effect causes the user to grow really irrationally hungry. The character will eat any edible substance, and will believe that it’s the best thing he’s ever tasted.',
  }, {
    name: 'Tremors',
    costModifier: 2,
    description: 'The user experiences violent tremors in his face and hands. The palsied fellow has his Reflex reduced by 2.',
  }, {
    name: 'Water Retention',
    costModifier: 2,
    description: 'Due to nonfunctional kidneys or other less traumatic problems, the user of a drug with this side effect will retain water for the side effect’s duration. He will not be able to urinate, and will become bloated for the duration.',
  }, {
    name: 'Weight Gain',
    costModifier: 2,
    description: 'The drug causes the user’s body to store excess energy in the form of fat. Users of drugs with this side effect tend to be quite large and overweight, while addicts suffer from obesity. Every time a drug with this effect is used, the user gains 1D6 pounds. Due to the extra weight he is carrying, an overweight character suffers a -1 to MA for every twenty-five pounds he weighs past his “ideal weight.” In addition to the physical effects, in the superficial atmosphere of Cyberpunk 2020, where everyone is fashion-model perfect, overweight people might suffer a social stigma worse than their 20th century counterparts.',
  },
];

export default drugTimedSideEffectsData;
