import React from 'react';
import ReactTooltip from 'react-tooltip';
import capitalize from 'lodash/capitalize';

import styles from './styles.module.scss';

type StatOptionProps = {
  title: string,
  value: number,
  tooltip?: string,
  bold?: boolean
  withControls?: boolean,
  onAdd?: () => void,
  onDecrease?: () => void,
};

const StatOption = ({
  title, value, tooltip, bold, withControls, onAdd, onDecrease,
}: StatOptionProps) => (
  <div
    data-tip
    data-for={`stat${title}`}
    className={styles.statOption}
  >
    {tooltip && (
      <ReactTooltip
        id={`stat${title}`}
        delayShow={500}
        type="light"
        effect="solid"
        border
        multiline
      >
        <div className={styles.tooltip}>
          {tooltip}
        </div>
      </ReactTooltip>
    )}
    {withControls && <button type="button" onClick={onDecrease}>-</button>}
    <div style={{ fontWeight: bold ? 'bold' : 'normal' }}>
      {capitalize(`${title}: ${value}`)}
    </div>
    {withControls && <button type="button" onClick={onAdd}>+</button>}
  </div>
);

StatOption.defaultProps = {
  tooltip: '',
  withControls: false,
  bold: false,
  onAdd: () => { },
  onDecrease: () => { },
};

export default StatOption;
