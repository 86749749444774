import React, { useState } from 'react';
import { Card } from 'components/UI';
import FactItem from '../FactItem';

import lifepathTables from './lifepathTables.json';

import styles from './styles.module.scss';

const CommonBlock = () => {
  const [generateRandom, setGenerateRandom] = useState(false);
  return (
    <Card>
      <div
        className={styles.blockHeader}
        role="button"
        tabIndex={0}
        onClick={() => {
          setGenerateRandom(true);
          setTimeout(() => {
            setGenerateRandom(false);
          });
        }}
      >
        Create Lifepath
      </div>
      <div className={styles.lifePathBlockArray}>
        {lifepathTables.map((chargenTable) => {
          const tableHeader = Object.keys(chargenTable)[0] as keyof typeof chargenTable;
          return (
            <FactItem
              key={tableHeader}
              generateRandom={generateRandom}
              header={tableHeader}
              table={chargenTable[tableHeader]}
            />
          );
        })}
      </div>
    </Card>
  );
};

export default CommonBlock;
