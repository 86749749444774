import React from 'react';
import { TiArrowRepeat } from 'react-icons/ti';

import StatOption from 'components/StatOption';

import type { Stats } from 'types';

import styles from './styles.module.scss';

type CompleteStatsTableProps = {
  stats: Stats,
  rerandom: () => void
};

const CompleteStatsTable = ({ stats, rerandom }: CompleteStatsTableProps) => (
  <div className={styles.statsInfo}>
    <div className={styles.statsTable}>
      {Object.keys(stats).map((stat) => (
        <StatOption
          key={stat}
          title={stat}
          value={stats[stat as keyof typeof stats]}
        />
      ))}
    </div>
    <button type="button" className={styles.rerandomButton} onClick={rerandom}>
      <TiArrowRepeat />
    </button>
  </div>
);

export default CompleteStatsTable;
