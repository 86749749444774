import type { DrugLegality } from 'types';

const drugLegalityData: DrugLegality[] = [
  {
    name: 'Legal/Common',
    costMultiplier: 1,
    description: 'Legal/Common drugs include anything you can buy in a liquor store, bar, or over the counter in a pharmacy. It’s common stuff that is easy to get, legal, but still governed by local and federal laws.',
  }, {
    name: 'By Prescription Only',
    costMultiplier: 5,
    description: 'Anything available By Prescription Only includes substances that must be obtained through a doctor or pharmacist, prescribed for a specific ailment. If you’ve got a prescription, you can get the drug pretty easily, and for a much reduced cost. However, getting the stuff on the street may take a bit more doing, and cops don’t like to find you carrying around a bottle of pills with someone else’s name on it.',
  }, {
    name: 'Type C Illegal',
    costMultiplier: 5,
    description: 'Illegal drugs are anything that the FDA hasn’t approved of. Dangerous non-medicinal chemicals are also ruled by these categories, as are combat drugs. The local cops and feds try to stop these sorts of drugs from being sold on the streets, usually without much success. Type C substances are illegal, but possession is usually a minor offense.',
  }, {
    name: 'Type B Illegal',
    costMultiplier: 7.5,
    description: 'Illegal drugs are anything that the FDA hasn’t approved of. Dangerous non-medicinal chemicals are also ruled by these categories, as are combat drugs. The local cops and feds try to stop these sorts of drugs from being sold on the streets, usually without much success. Type B substances are usually more dangerous than Type C, and carry with them a stiffer punishment.',
  }, {
    name: 'Type A Illegal',
    costMultiplier: 10,
    description: 'Illegal drugs are anything that the FDA hasn’t approved of. Dangerous non-medicinal chemicals are also ruled by these categories, as are combat drugs. The local cops and feds try to stop these sorts of drugs from being sold on the streets, usually without much success. Type A substances include the most dangerous and addictive drugs, and penalties for carrying them are large.',
  }, {
    name: 'Experimental',
    costMultiplier: 25,
    description: 'Experimental drugs are corporate, criminal, and military cocktails that end up on the streets as part of clandestine experiments. Most of the time, they are part of a single shipment, and don’t stay around too long unless they sell well. Drugs like this don’t remain in this category for long, and are usually classified as an Illegal-rated substance after a few weeks on the streets.',
  },
];

export default drugLegalityData;
