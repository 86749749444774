import React from 'react';

import type { SkillValue } from '../../../types';

import styles from './styles.module.scss';

type SkillsTableProps = {
  skills: SkillValue[]
};

const SkillsTable = ({ skills }: SkillsTableProps) => (
  <div className={styles.skillsTable}>
    {skills.map((skill) => (
      <div key={skill.name}>
        {`${skill.name}: ${skill.value}`}
      </div>
    ))}
  </div>
);

export default SkillsTable;
