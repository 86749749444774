export type NPC = {
  name: string,
  stats: Stats,
  hp: number,
  swThreshold: number,
  deathSave: number,
  weapons: string[],
  armor: {
    head: string,
    body: string,
  },
  uniqSkill?: SkillValue,
  skillBases: SkillValue[],
  equipment: string[],
  programs?: string[],
};

export type Stats = {
  int: number,
  ref: number,
  dex: number,
  tech: number,
  cool: number,
  will: number,
  luck: number,
  move: number,
  body: number,
  emp: number,
};

export type Skill = {
  name: string,
  value: number,
  description: string,
  isX2: boolean,
  isBase: boolean,
  linkedStat: Stat,
  skillCategory: string,
};

export type SkillValue = {
  name: string,
  value: number
};

export type FloorContent = {
  value: string,
  available: boolean,
  oneTime: boolean,
};

export type DrugEffect = {
  name: string,
  difficulty: number,
  description: string,
  od: string,
};

export type DrugFeature = DrugSideEffect;

export type DrugSideEffect = {
  name: string,
  costModifier: number,
  description: string,
};

export type DrugDuration = {
  name: string,
  duration: string,
  difficultyMultiplier: number,
};

export type DrugLegality = {
  name: string,
  description: string,
  costMultiplier: number
};

export type DrugForm = {
  name: string,
  description: string,
  timeToEffect: string,
  costMultiplier: number,
  cuttable: boolean,
};

export type DrugStrengthRelatedInfo = {
  strength: number,
  addictionNumber: number,
  timeToNextDose: string,
  timeToWithdrawal: string,
};

export type Drug = {
  effect: DrugEffect,
  feature?: DrugFeature,
  untimedSideEffect?: DrugSideEffect,
  timedSideEffects: DrugSideEffect[],
  strengthRelatedInfo: DrugStrengthRelatedInfo,
  duration: DrugDuration,
  legality: DrugLegality,
  form: DrugForm,
  difficulty: number,
  cost: number,
};

export type Stat = 'int' | 'ref' | 'dex' | 'tech' | 'cool' | 'will' | 'luck' | 'move' | 'body' | 'emp';

export const roles: Role[] = ['rockerboy', 'solo', 'netrunner', 'tech', 'medtech', 'media', 'exec', 'lawman', 'fixer', 'nomad'];

export type Role = 'rockerboy' | 'solo' | 'netrunner' | 'tech' | 'medtech' | 'media' | 'exec' | 'lawman' | 'fixer' | 'nomad';
