import React, { useState, useEffect } from 'react';

import drugDurationData from 'data/Drugs/duration';

import drugEffects from 'data/Drugs/effects';
import drugStrengthRelatedInfoData from 'data/Drugs/strengthRelatedInfo';
import drugLegalityData from 'data/Drugs/legality';
import drugFormData from 'data/Drugs/form';
import type { Drug } from 'types';
import DataSelector from './DataSelector';

import styles from './styles.module.scss';

const DrugsGen = () => {
  const [drugData, setDrugData] = useState<Drug>({
    effect: drugEffects[0],
    timedSideEffects: [],
    strengthRelatedInfo: drugStrengthRelatedInfoData[0],
    duration: drugDurationData[0],
    legality: drugLegalityData[0],
    form: drugFormData[0],
    difficulty: 11,
    cost: 5,
  });

  useEffect(() => {
    let newDifficulty = drugData.effect.difficulty;
    if (drugData.feature) {
      newDifficulty += drugData.feature?.costModifier;
    }
    if (drugData.untimedSideEffect) {
      newDifficulty -= drugData.untimedSideEffect?.costModifier;
    }
    if (drugData.timedSideEffects.length !== 0) {
      const sideEffectsMod = drugData.timedSideEffects
        .reduce((acc, curr) => acc + curr.costModifier, 0);
      newDifficulty -= sideEffectsMod;
    }

    newDifficulty += drugData.strengthRelatedInfo.strength;
    newDifficulty *= drugData.duration.difficultyMultiplier;

    const newCost = newDifficulty * drugData.legality.costMultiplier * drugData.form.costMultiplier;
    setDrugData({ ...drugData, difficulty: newDifficulty, cost: newCost });
  }, [
    drugData.effect,
    drugData.feature,
    drugData.untimedSideEffect,
    drugData.timedSideEffects,
    drugData.strengthRelatedInfo,
    drugData.duration,
    drugData.legality,
    drugData.form,
  ]);

  return (
    <div className={styles.drugsConstructor}>
      <div className={styles.constructedDrugBlock}>
        <div>
          {`Main effect: ${drugData.effect.name}`}
        </div>
        {drugData.feature && (
          <div>
            {`Feature: ${drugData.feature?.name}`}
          </div>
        )}
        {drugData.untimedSideEffect && (
          <div>
            {`Untimed side effect: ${drugData.untimedSideEffect?.name}`}
          </div>
        )}
        {drugData.timedSideEffects.length !== 0 && (
          <div>
            {'Timed side effects are: '}
            {drugData.timedSideEffects.map((sideEffect) => (
              <div key={sideEffect.name}>{sideEffect.name}</div>
            ))}
          </div>
        )}
        <div>
          {`Strength: ${drugData.strengthRelatedInfo.strength}`}
        </div>
        <div>
          {`Duration: ${drugData.duration.duration}`}
        </div>
        <div>
          {`Addiction number: ${drugData.strengthRelatedInfo.addictionNumber}`}
        </div>
        <div>
          {`Time to next dose: ${drugData.strengthRelatedInfo.timeToNextDose}`}
        </div>
        <div>
          {`Time to withdrawal: ${drugData.strengthRelatedInfo.timeToWithdrawal}`}
        </div>
        <div>
          {`Legality: ${drugData.legality.name}`}
        </div>
        <div>
          {`Form: ${drugData.form.name} Time to effect: ${drugData.form.timeToEffect}`}
        </div>
        <div>
          {`Can be cut: ${drugData.form.cuttable ? 'Yes' : 'No'}`}
        </div>
        <div>{`Difficulty of creation: ${drugData.difficulty}`}</div>
        <div>{`Cost: ${drugData.cost} eb/dose`}</div>
      </div>
      <DataSelector drugData={drugData} setDrugData={setDrugData} />
    </div>
  );
};

export default DrugsGen;
