const encounters: { [key: string]: [number, string][] } = {
  daytime: [
    [5, 'Local Law: A number of patrol officers equal to half the Players, armed with Assault Rifles, Very Heavy Pistols, and heavy batons and armored in Kevlar®. If you’re wearing visible weapons or armor, they stop you and demand identification. If you’re argumentative, they call for backup (3 officers) to take you in. If you reach for a weapon, they’ll shoot first, fill out the paperwork later. (Use Security Operatives.)'],
    [11, 'Corporate Guards: A number of low-level Corporate guards equal to the Players patrolling the area, wearing Light Armorjack and carrying SMGs. Unless you work for their Corporation, they don’t think you have any business hanging around where they’re operating. Move it, punk. (Use Security Operatives; Replace Kevlar® with Light Armorjack and Poor Quality Assault Rifles with Poor Quality SMGs.)'],
    [13, 'Techs: A number of Techs equal to half the Players, with Shotguns, wearing Kevlar® vests. Roll 1d10. 1-2, they’re lugging a crate of tools into an AV-4. 3-5, they’re working on a City system in your path. 6-10, they’re walking toward you on their way to work. (Use Bodyguards; Add Tool Hand and a MicroOptics Cybereye.)'],
    [17, 'Private Investigator: A PI armed with a Very Heavy Pistol and a heavy baton and wearing a Medium Armorjack. Roll 1d10. On 1-3, they are ahead of you, hassling an informant on the street corner for the whereabouts of a suspect. 4-7, they’re shadowing someone ahead of you. 8-10, they stop you and ask you whether you’ve seen the person they’re following. (Use Security Officers; Remove Assault Rifle and Bulletproof Shield.)'],
    [20, 'Corporates: A number of Corporates equal to the Players from a local firm, trying to find a taxi. They are wearing suits woven with Kevlar® and carrying Medium Pistols. Roll 1d10. 1-4, they’re being followed by a number of gangers equal to the players intent on robbery. 5-8, they think you’re boosters and open fire at any provocation. 9-10, they think you’re trouble and call for Corporate backup (see Corporate Guards, above). (Corporates; use Boosters; Remove Rippers; Replace Leather with Kevlar® and Poor Quality Very Heavy Pistols with Medium Pistols; Add Cyberaudio Suite and Radio Scanner/Music Player. Gangers; use Boosters.)'],
    [27, 'Locals: Two young people from the local neighborhood. (Use Boosters; Remove Rippers and Poor Quality Very Heavy Pistols.) Roll 1d10. 1-5, they are being held up by a number of gangers equal to the Players from the Red Chrome Legion gang. 6-10, they are being beaten severely by a number of Inquisitor cultists equal to the Players for the sin of having designer eyes. (Red Chrome Legion; use Boosters; Replace Leather with Heavy Armorjack. Inquisitors; use Boosters; Replace Rippers with Medium Melee Weapons; Remove Techhair.)'],
    [32, 'Reclaimers: You run across a wellequipped group of reclaimers equal to the Players minus 2 with 1 chief trying to hot-wire an abandoned building into the local power grid. Roll 1d10. On 1-5, they ignore you. 6-8, the chief and one of the reclaimers come over to "Check those guys watching us." 9-10, they blow out a local transformer and the entire block goes black. General panic ensues. (Reclaimers; Use Road Gangers. Reclaimer Chief; Use Reclaimer Chief)'],
    [37, 'Medias: A 2-person camera and interviewer team, staking out a building on a hot assignment. Roll 1d10. On 1-5, they’re spotted by their story-subject and a conflict ensues. You, of course, are right in the middle of it. (Media; use Boosters; Replace Leather with Kevlar® and Poor Quality Very Heavy Pistols with Heavy Pistols; Remove Rippers; Add MicroVideo Cybereye, Cyberaudio Suite, and Amplified Hearing. Story-Subject; Roll again on the Encounter Table.)'],
    [41, 'Private Investigator: A PI armed with a Very Heavy Pistol and a heavy baton and wearing a Medium Armorjack. Roll 1d10. On 1–3, they are ahead of you, beating an informant on the street corner. 4–7, they are breaking into someone’s car. 8–10, they stop you and demand answers regarding the whereabouts of their target. (use Security Officers; Remove Assault Rifle and Bulletproof Shield)'],
    [46, 'Trauma Team: The AV-4 hits the ground in the middle of a recent firefight and the medics start tending to the half-dozen wounded gangers. Roll 1d10. On a 1-5, the Team decides you’re bystanders and ignores you. On 6-10, they figure you’re part of the problem and the 2 security officers open up with Assault Rifles. (See Trauma Team on pg. 224 for Trauma Team Statistics.)'],
    [57, 'Scavvers: A number of dirt-poor scavengers equal to the Players rooting through the ruins or garbage near one of the burned-out city blocks. All have filthy leathers and are armed with a variety of makeshift knives and Poor Quality Very Heavy Pistols. Roll 1d10. On a 1-6, they beg you for hard cash or anything you can spare. On 7-8, they ignore you. On 9-10, they try to rob you. At the GM’s discretion, the scavvers may have up to a 6 fellow scavs nearby who come to assist if violence ensues. (Use Boosters; Replace Rippers with Light Melee Weapon.)'],
    [63, 'Nomads: A group of Nomads equal to Players. Wearing Leather, carrying Crossbows, knives, and Very Heavy Pistols. Moderately drunk and looking for a fight. They hassle anyone they find attractive in your group. Face it: they want a fight. Give them one. (Use Road Gangers.)'],
    [70, 'Boostergang: A number of low-level street punks equal to the Players from the Piranhas boostergang. If you look like easy prey and have money, they’ll rough you up. They’re armed with Very Heavy Pistols and Rippers. (Use Boosters.)'],
    [76, 'Street Punks: A number of Smash-heads equal to the Players, looking for cash to feed their habit. If you’re not wearing Corporate or gang colors, they try to rush you right on the street. Armed with knives and clubs; no armor. (Use Boosters; Replace Rippers with Light Melee Weapons, and remove Very Heavy Pistol and Leather Armor.)'],
    [82, 'Culties: The Reckoners are out in force. A number of them equal to the Players, armed openly with knives, clubs, and Heavy Pistols, corner you to preach that the red sky is a sign of the End Times. If you just blow them off, they try to beat their truth into you. (Use Boosters; Replace Rippers with Big Knucks and Poor Quality Very Heavy Pistols with Poor Quality Heavy Pistols; Add a Light Melee Weapon.)'],
    [88, 'Nomad Truck: A number of Nomads from the Steel Vaqueros equal to half the Players (Minimum 2) are dealing with a broken-down truck. Most of them are facing off with a group of local gangers equal to the Players while the last one tries to fix the motor. If you ignore them, they ignore you, but the Nomads may take an offer to help (Roll 1d10: 1-6, Yes; 7-10, No). (Nomads; use Road Gangers; Replace Leather with Kevlar®. Gangers; Use Boosters.)'],
    [94, 'Boostergang: A number of members of the Iron Sights gang equal to the Players, armed with SMGs, Rippers, Low Light Cybereyes, and Speedware. They hassle anyone in the Crew who stands out, shake you down for money, and generally are spoiling for a fight. (Use Boosters; Replace Poor Quality Very Heavy Pistols with Poor Quality SMGs. Add Low Light/Infrared/UV Cybereyes, Neural Link, & Kerenzikov.)'],
    [100, 'Major Criminal: You’ve walked into a major operation of the ruthless Vilshenko syndicate. A number of Solos equal to the Players minus 2 armed with Very Heavy Pistols and Shotguns and wearing Heavy Armorjack are unloading contraband cargo out of a truck. They’re led by a veteran Solo. Roll 1d10. 1-4, they don’t notice you. 5-8, they notice you and one warns you off. 9-10, they decide you’re witnesses and ought to be totaled right now. (Solos; Use Security Operatives. Veteran Solo; Use Security Officer; Replace Poor Quality Assault Rifles and Assault Rifles with Shotguns.)'],
  ],
  evening: [
    [5, 'City Police: A number of patrol officers equal to half the Players, armed with Assault Rifles, armored in Medium Armorjack. If you’re wearing visible weapons or armor, they’ll stop you and demand identity papers. If you’re argumentative, they call for backup (3 Security Operatives) to take you in. If you reach for a weapon, they’ll shoot first, fill out the paperwork later. (Use Security Officers.)'],
    [11, 'Corporate Guards: A number of Corporate guards equal to the players patrolling the area. Armored in Heavy Armorjack carrying Heavy SMGs. Unless you’re a Corporate, they don’t think you have any business running around their territory. Move it, punk. (Use Security Operatives; Replace Kevlar® with Heavy Armorjack; Replace Poor Quality Assault Rifles with Poor Quality Heavy SMGs.)'],
    [13, 'Corporate Techs: A number of Corporate Techs equal to half the Players with a number of heavily armed bodyguards equal to half the Players. Roll 1d10. 1-2, they are loading crates of parts into an AV-4. 3-5, they are working on a City system in your path. 6-10, they are fixing up a nice-looking car on the side of the road. (Tech; Use Bodyguards; Add Tool Hand and a MicroOptics Cybereye. Bodyguards: Use Bodyguards; Replace Kevlar® with Medium Armorjack and Poor Quality Shotgun with Shotguns; Add Targeting Scope Cybereye.)'],
    [17, 'Private Investigator: A PI armed with a Very Heavy Pistol and a machete and wearing a Light Armorjack. Roll 1d10. On 1-3, they’re ahead of you, hassling an informant on the street corner for the whereabouts of a suspect. 4-7, they’re shadowing someone ahead of you. 8-10, they’ll stop you and ask whether you’ve seen the person they’re following. The PI is armed with a heavy caliber revolver and wearing an armor jacket. (Use Reclaimer Chief; Remove Tent & Camping Equipment, Shotgun; Replace Heavy Pistol with Very Heavy Pistol and Heavy Melee Weapon with Medium Melee Weapon.)'],
    [20, 'Corporates: A number of Corporates equal to the Players from a large firm, headed for the lev train station. All of them wear suits woven with Kevlar® and carry polymer one-shots. Roll 1d10. 1-4, they’re being followed by a number of gangers equal to the Players intent on robbery. 5-8, they think you’re boosters and will open fire at any provocation. 9-10, they not only think you’re trouble, but will call for Corporate backup (see Corporate Guards, above). (Corporates; use Boosters; Remove Rippers; Replace Leather with Kevlar®; Add Cyberaudio Suite and Radio Scanner/ Music Player. Gangers; use Boosters.)'],
    [25, 'Rockerboys: You start to overtake a group of Rockers equal to half the Players minus 1on the way to a gig, backed by their Solo bodyguards (equal to half the Players) and their Fixer manager. Roll 1d10. On 1-4, they will invite you to join them. 5-8, they will send the Solos to "Deal with those guys following them." 9-10, they ignore you. (Rockers; Use Boosters; Add AudioVox, & Instruments. Fixer; Use Boosters; Add Cyberaudio Suite and Internal Agent. Bodyguards; use Bodyguards.)'],
    [30, 'Medias: A 2-person camera and interviewer team, staking out a building on a hot assignment. Roll 1d10. On 1-5, they will be spotted by their story-subject and a firefight will ensue. You, of course, will be right in the middle of it. (Media; use Boosters; Replace Leather with Kevlar® and Poor Quality Very Heavy Pistols with Heavy Pistols; Remove Rippers; Add MicroVideo Cybereye, Cyberaudio Suite, and Amplified Hearing. StorySubject; Roll again on the Encounter Table.)'],
    [33, 'Philharmonic Vampires: The Philharmonic Vampires gang are up to some trouble again. Roll 1d10. 1-2, three of them are setting up for some kind of prank involving three or four unusual household items (your choice), but a group of gangers equal to the Players have caught on and conflict ensues. 3-4, three of them have hacked into the local streetlights to flash to the beat of a popular pop song. 5-6, three of them have hacked into the Data Terms to show a warning of an impending missile strike and panic is spreading. 7-8, three of them have released the contents of several huge bags of Eurobucks from the window of a stolen police cruiser. People are scrambling for the cash only to find it’s all fake. 9-10, three of them have hacked into the local video boards and are broadcasting fake news. Undoing the Vamp’s pranks requires a DV14 Electronic/Security Tech Check. (Philharmonic Vampires; Use Boosters; Add an Electronic/Security Tech Skill Base of 10. Gangers; Use Bodyguards.)'],
    [40, 'Locals: One teen from a Beaverville. Probably snuck out to see the city. Roll 1d10. 1-4, they’re being held up by a number of gangers equal to the Players from the Piranhas gang. 5-8, they’re being beaten severely by a number of Inquisitors equal to the Players for the sin of having fashionware. 9-10: they are being held up by a Fixer they don’t have the money to pay. (Piranhas; use Boosters; Replace Leather with Light Armorjack. Inquisitors; use Boosters; Replace Rippers with Light Melee Weapons; Remove Techhair. Fixer; use Bodyguard; Add Cyberaudio Suite, Internal Agent, and Voice Stress Analyzer; Add a Trading Skill Base of 10.)'],
    [46, 'Roaming Netrunner: Two Netrunners armed with Very Heavy Pistols and armored in Light Armorjack. They’re lingering around a small Corporate office trying not to get spotted as they break into the building’s NET Architecture. Roll 1d10. On 1-5, they’re spotted by a group of security guards equal to the Players and a conflict ensues. You, of course, are right in the middle of it. On 6-10, the Netrunners assume you’re security and use the building’s 2 security turrets to attack you. (Netrunners; use Netrunner; Replace Bodyweight Suit with Light Armorjack. Security; use Security Operatives. Security Turret; use Automated Turret on pg. 214)'],
    [52, 'Nomads: A group of Nomads equal to the Players from the Roadrunners Pack. Wearing Medium Armorjack, carrying fighting knives, and rifles. Moderately drunk and looking for a fight. They’ll hassle any attractive looking people in your group. Face it; they want a fight. Give them one. (Use Road Gangers; Replace Crossbows with Assault Rifles and Leather with Medium Armorjack. Swap Archery Skill for Shoulder Arms Skill.)'],
    [58, 'Street Punks: A group of Smash-heads equal to the Players, looking for cash to feed their habit. If you’re not wearing Corporate or gang colors, they try to rush you right on the street. Armed with knives and clubs; no armor. (Use Boosters; Replace Rippers with Light Melee Weapons, and remove Poor Quality Very Heavy Pistol and Leather Armor.)'],
    [63, 'Trauma Team: The AV-4 hits the ground in the middle of a recent firefight and the medics start tending to the half-dozen wounded gangers. Roll 1d10. On a 1-5, the Team decides you’re bystanders and ignores you. On 6-10, they figure you’re part of the problem and the 2 security officers open up with Assault Rifles. (See Trauma Team on pg. 224 for Trauma Team Statistics.)'],
    [69, 'Chromers: A group of hardcore fans of a local Chromatic Rock band decked out with metal spiked leathers and metal arms covered with chrome and cyberweapons. They equal the Players in number. Roll 1d10. 1-5, they’re hyped up on Smash and try to pick a fight. 6-7, they pass you by, maybe making a few rude comments. 8-10, they take a liking to one or more members of the Crew and invite you to tag along with them to get smashed and hit the concert. (Use Boosters; Add 2 Cyberarms with Superchrome® Covering; Replace Rippers with Wolvers and Leather with Light Armorjack.)'],
    [72, 'Solo Team: A group of Solos equal to half the Players, armored in Light Armorjack, carrying swords and a Heavy Pistol. They’re cybered up with boosted reflexes, cybereyes with IR, and cyberlegs. Obviously, a team of assassins looking for someone. Roll 1d10. 1-5, they dodge you and move on; 6-10, they decide you’re witnesses and ought to be totaled right now. (Use Security Officers; Add Low-Light/Infrared/UV Cybereyes, and two Cyberlegs with Jump Boosters; Remove Assault Rifle; Replace Very Heavy Pistol with Heavy Pistol.'],
    [77, 'Boostergang: A group of Iron Sights gangers equal to the Players armed with automatic weapons, cyberweapons, Low-Light/Infrared/UV cybereyes, boosted reflexes. They will hassle any attractive people in the Crew, shake you down for money, and are generally looking for a fight. This group is tougher than the daylight crowd was. (Use Bodyguard; Replace Poor Quality Shotgun with Heavy SMGs; Add two cyberweapons of your choice; Add Low Light/Infrared/UV Cybereyes, Neural Link, and Kerenzikov.'],
    [83, 'Solo Team: A group of Solos equal to half the Players, carrying Smartgun Linked Assault Rifles. Boosted reflexes, boosted hearing, cybereyes with IR and targeting. Obviously up to some grey operation. If you are wearing a rival Corporate uniform or patch, they will: 1-5, dodge you and move on; 6-10, decide you’re witnesses and ought to be totaled right now. (Use Security Officers; Add Smartgun Link, Interface Plugs, Cyberaudio Suite, Amplified Hearing, Low-Light/Infrared/UV Cybereyes, and a Targeting Scope in one eye.'],
    [90, 'Boostergang: A group of low-level street punks from the Piranhas equal to the Players plus 2. If you look like easy prey and have money, they’ll rough you up. They’re armed with Medium Pistols, knives, and boosted reflexes. (Use Boosters; Replace Poor Quality Very Heavy Pistols with Poor Quality Medium Pistols; Add Neural Link and Kerenzikov.)'],
    [93, 'Major Criminal: You’ve walked into a major operation of the notorious Scagattalia Family. A number of cybered-up Solos armed with cyber weapons, Heav y Pistols, and Smartgun Linked Assault Rifles are unloading a drug cargo out of a truck. Roll 1d10. 1-4, they don’t notice you. 5-8, they notice you and one warns you off. 9-10, they decide you’re witnesses and ought to be totaled right now. (Use a number of Security Operatives equal to half the players and 2 Security Officers; Replace Poor Quality Assault Rifles with Smartgun Linked Assault Rifles; Add Wolvers, Neural Link, Interface Plugs, Smartgun Link, and a Targeting Scope Cybereye.)'],
    [100, 'Firefight: Great. You just walked into a major altercation between the Maelstrom and the Red Chrome Legion. Each side has a number of members equal to the Players who are wearing Light Armorjack and firing Very Heavy Pistols. Cyberweapons are everywhere. Each side is led by 1 leader armed with heavier weapons. Pick a side or pick a target. (Grunts; use Boosters; Replace Leather with Light Armorjack. Keep Rippers or replace with other cyberweapons as desired. Leaders; use Security Officers; Add a cyberweapon of your choice.)'],
  ],
  midnight: [
    [10, 'City Police: A number of patrol officers equal to half the Players, armed with Smartgun Linked Assault Rifles. If you’re wearing visible weapons or armor, they’ll stop you and demand identity papers. You’ve got no business running around after midnight and they know it. They’ll stop you and look for any pretext to make a bust. If you’re wearing visible weapons, consider yourself detained. If you go for a weapon, they’ll shoot first and forget the paperwork. (Use Security Officers; Add Interface Plugs and Smartgun Link.'],
    [22, 'Corporate Guards: A group of Corporate guards equal to the Players patrolling the area, carrying Smartgun Linked Heavy SMGs. "What are you doing on the property after midnight? Can’t have a good reason!" (Use Security Officers; Replace Assault Rifles with Heavy SMGs, Add Interface Plugs and Smartgun Link.)'],
    [24, 'Private Investigator: A PI armed with a Very Heavy Pistol and a machete and wearing a Light Armorjack. Roll 1d10. On 1-3, they’re ahead of you, hassling an informant on the street corner for the whereabouts of a suspect. 4-7, they’re shadowing someone ahead of you. 8-10, they’ll stop you and ask whether you’ve seen the person they’re following. The PI is armed with a heavy caliber revolver and wearing an armor jacket. (Use Reclaimer Chief; Remove Tent & Camping Equipment, Shotgun; Replace Heavy Pistol with Very Heavy Pistol and Heavy Melee Weapon with Medium Melee Weapon.)'],
    [25, 'Medias: A 2-person camera and interviewer team, looking for a story. Roll 1d10. On 1-5, they’ll be spotted by their story-subject and a firefight will ensue. You, of course, will be right in the middle of it. On a 6-10, they’ll decide you’re the story and follow you. (Media; use Boosters; Replace Leather with Kevlar® and Poor Quality Very Heavy Pistols with Heavy Pistols; Remove Rippers; Add MicroVideo Cybereye, Cyberaudio Suite, and Amplified Hearing. Story-Subject; Roll again on the Encounter Table.)'],
    [29, 'Chromers: A group of hardcore fans of a local Chromatic Rock band decked out with metal spiked leathers and metal arms covered with chrome and cyberweapons. They outnumber the Players by 2. Roll 1d10. 1-5, they’re coming back from a concert, hyped up on Smash, and try to pick a fight. 6-7, they drunkenly pass you by, maybe making a few rude comments. 8-10, they take a liking to one or more members of the Party and invite you to tag along with them to the after party. (Use Boosters; Add 2 Cyberarms with Superchrome® Covering; Replace Rippers with Wolvers and Leather with Light Armorjack.'],
    [39, 'Edgerunner Team: A small team of Edgerunners. One Netrunner, 1 Solo, and a Nomad. They’re on the street in an old Ground Car preparing to break into a small Corporate office. Outside, 6 security guards are on patrol. Roll 1d10. On 1-5, they’re spotted by security and a conflict ensues. You, of course, are right in the middle of it. On 6-10, they spot you and offer you a piece of the action. (Netrunner; use Netrunner. Solos; use Security Officer. Nomad; use Reclaimer Chief. Security; use Security Operatives.)'],
    [42, 'Trauma Team: The AV-4 hits the ground in the middle of a recent firefight and the medics start tending to the half-dozen wounded gangers. Roll 1d10. On a 1-5, the Team decides you’re bystanders and ignores you. On 6-10, they figure you’re part of the problem and the 2 security officers open up with Assault Rifles. (See Trauma Team on pg. 224 for Trauma Team Statistics.)'],
    [45, 'Ranger: A Lawman and their deputized partner are in town looking for a local gang of 6 scavengers hiding out in the City. Roll 1d10. 1-5, the gunfight has already broken out by the time you get there, blocking your path. The Lawman calls to the Players to join them. There’s a high bounty on the 6 lowlifes. 6-10, the Lawman and their deputy are about to go into the gang’s hideout and flush them out. They’ve blocked off the street with the help of the local cops and the Lawman offers the Players part of the bounty to go in and help bring the scavengers out dead or alive. (Lawman; use Outrider. Deputy; use Road Ganger. Scavengers; use Boosters.)'],
    [58, 'Nomads: A group of Nomads equal to the Players plus 2 from the Wildman Pack are currently beating a Corporate couple bloody for looking at them wrong. The Nomads are sporting light tattoos, and leather pants, carrying fighting knives, clubs, and rifles. As long as you stay clear of them and their bikes, they’ll ignore you and concentrate on beating the Corporates to mush. (Nomads: Use Road Gangers; Add Light Tattoos, Replace Crossbows with Assault Rifles. Swap Archery Skill for Shoulder Arms Skill. Corporates: Use Boosters; Remove Rippers)'],
    [63, 'Culties: It’s Inquisition time! The Inquisitors are out in maximum force. A group of them equal to the Players, armed openly with nunchaku, handguns, and whips corner you. Two more Inquisitors armed with Air Pistols with Acid Rounds stand back, ready to pepper the more heavily armored Players. "Only a servant of the Metal Demons would be awake at this hour. Any decent citizen would be at home, asleep. Hack, slay, and maim the heretics!" (Inquisitors; use Boosters; Remove Leathers and Techhair; Replace Rippers with Medium Melee Weapons; Add Air Pistols with 20 Acid Paintballs to two of them.)'],
    [73, 'Street Punks: A group of lace addicts equal to the Players plus 2, looking for cash to feed their habit. Even if you’re wearing Corporate or gang colors, they try to rush you right on the street. They’re boosted up on Black Lace and armed with knives and clubs; no armor. (Use Boosters; Replace Rippers with Light Melee Weapons and remove Very Heavy Pistol and Leather Armor. They’re all under the influence of Black Lace [see page 227] and are unaffected by the Seriously Wounded Wound State.)'],
    [74, 'Major Criminal: You’ve walked into a major operation of the notorious Scagattalia Family. A group of cybered-up Solos equal to the Players armed with cyberweapons, Heavy Pistols, and Smartgun Linked Assault Rifles are unloading a drug cargo out of a truck. Roll 1d10. 1-4, they don’t notice you. 5-8, they notice you and one warns you off. 9-10, they decide you are a witness and ought to be totaled right now. (Use Security Officers; Add Wolvers, Interface Plugs, Smartgun Link, and a Targeting Scope Cybereye)'],
    [79, 'Turf War: Great. You just walked into an all-out turf war between the two of the largest gangs in the area. Roll 1d10 for each of the sides. 1-2, it’s the Tyger Claws, wearing light armored biker jackets, with Katanas and Very Heavy Pistols. 3-4, it’s the 6th Street, armored in Medium Armorjack and carrying Heavy SMGs. 5-6, it’s the Piranhas armed with Poor Quality Very Heavy Pistols and ripped leather jackets. 7-8, it’s the Iron Sights, armed with various cyberweapons and SMGs. 9-10, it’s NCPD Patrol Officers in Medium Armorjack uniforms with Assault Rifles. Each side has a number of members equal to the Players (Minimum 4) who have gear according to their affiliation. Cyberweapons are everywhere. Pick a side or pick a target. (Tyger Claws; use Boosters; Add Heavy Melee Weapons; Replace Leather with Light Armorjack. 6th Street; use Boosters; Replace Leather with Medium Armorjack. Replace Rippers with Heavy SMGs. Piranhas; use Boosters. Iron Sights; use Boosters; Replace Poor Quality Very Heavy Pistols with SMGs; Replace Rippers with Cyberweapons as desired. NCPD Patrol Officers; use Boosters; Replace Leather with Medium Armorjack; Add Assault Rifles and a Shoulder Arms Skill Base of 10.)'],
    [87, 'Arsonists: A small group of radical anarchists with a grudge against somebody local. One cybered up ganger wielding a Flamethrower, an Axe, and a Heavy Pistol, and leading a group of Boosters equal to the Players minus 3 (Minimum 2) each armed with 1 Incendiary Grenade, Rippers, and a Poor Quality Very Heavy Handgun. They aren’t looking for a fight but they’re burning down the block and they’ll kill anybody who gets in their way, or happens to be in the way. (Flamethrower Ganger; use Pyro. Gangers; use Boosters; Add 1 Incendiary Grenade)'],
    [92, 'Turf War: Great. You just walked into an all-out turf war between two of the largest gangs in the area. Roll 1d10 for each of the sides. 1-2, it’s the Bozos, decked out in polka dot Light Armorjack, with giant mallets and colorful Very Heavy Pistols. 3-4, it’s the Maelstrom, armored in Medium Armor Jack and bristling with cyberweapons. 5-6, it’s the Primetime Players armed with old school shotguns and leather jackets with fringe. 7-8, it’s the Voodoo Boys, armed with Teargas Grenades and SMGs. 9-10, it’s the Red Chrome Legion in Medium Armorjack uniforms with Assault Rifles. Each side has a number of members equal to the Players (Minimum 4) who have gear according to their affiliation. Cyberweapons are everywhere. Pick a side or pick a target. (Bozos; use Boosters; Replace Leather with Light Armorjack; Replace Rippers with Big Knucks; Add Very Heavy Melee Weapons. Maelstrom; use Boosters; Replace Leather with Medium Armorjack. Replace Rippers with Wolvers. Primetime Players; use Boosters; Add Shotgun with 20 Slugs and a Shoulder Arms Skill Base of 10. Voodoo Boys; use Boosters; Replace Poor Quality Very Heavy Pistols with SMGs; Add 1 Teargas Grenade. Red Chrome Legion; use Boosters; Replace Leather with Medium Armorjack; Add Assault Rifles and a Shoulder Arms Skill Base of 10.)'],
    [99, 'Major Criminal: You’ve walked into a major operation of the notorious Scagattalia Family. Six cybered-up Solos armed with cyberweapons, Heavy Pistols, and Smartgun Linked Assault Rifles are unloading a drug cargo out of a truck. Roll 1d10. 1-4, they don’t notice you. 5-8, they notice you and one warns you off. 9-10, they decide you’re witnesses and ought to be totaled right now. (Use 6 Security Officers; Add Wolvers, Interface Plugs, Smartgun Link, and a Targeting Scope Cybereye.)'],
    [100, 'Cyberpsycho Rage: A single cyberpsycho, gleaming with metal and taking their fury out on a pedestrian who pushed them a little too far. The cyberpsycho is cybered up to the teeth with four cyberlimbs, Jump Boosters, multiple Popguns, Wolvers, and more. They don’t look like they’re going to calm down and it will be a little bit (1d6 + 1 rounds) before Psycho can get on the scene. What’s worse, they just noticed you. (Use Cyberpsycho.)'],
  ],
};

const encounterTypes = ['daytime', 'evening', 'midnight'] as EncounterCategories[];

type EncounterCategories = keyof typeof encounters ;

export type { EncounterCategories };
export { encounters, encounterTypes };
