import React from 'react';
import { Card } from 'components/UI';

import { rangedWeaponData } from '../equipmentData';

import styles from './styles.module.scss';

const RangedWeaponBlock = () => (
  <Card>
    <div className={styles.cardHeader}>
      Ranged Weapon
    </div>
    <div className={styles.card}>
      <div
        className={styles.item}
      >
        <div className={styles.type}>
          Weapon Type
        </div>
        <div className={styles.skill}>
          Weapon Skill
        </div>
        <div className={styles.damage}>
          Damage
        </div>
        <div className={styles.magazine}>
          Magazine
        </div>
        <div className={styles.rof}>
          ROF
        </div>
        <div className={styles.handsReq}>
          Hands
        </div>
        <div className={styles.concealability}>
          Can hide?
        </div>
        <div className={styles.cost}>
          Cost
        </div>
      </div>
      {rangedWeaponData.map((rangedWeapon) => (
        <div
          className={styles.item}
          key={rangedWeapon.type}
        >
          <div className={styles.type}>
            {rangedWeapon.type}
          </div>
          <div className={styles.skill}>
            {rangedWeapon.skill}
          </div>
          <div className={styles.damage}>
            {rangedWeapon.damage}
          </div>
          <div className={styles.magazine}>
            {rangedWeapon.magazine}
          </div>
          <div className={styles.rof}>
            {rangedWeapon.rof}
          </div>
          <div className={styles.handsReq}>
            {rangedWeapon.handsRequired}
          </div>
          <div className={styles.concealability}>
            {rangedWeapon.concealable ? 'Yes' : 'No'}
          </div>
          <div className={styles.cost}>
            {`${rangedWeapon.cost}eb`}
          </div>
        </div>
      ))}
    </div>
  </Card>
);

export default RangedWeaponBlock;
