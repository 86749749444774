import execImage from './exec.png';
import fixerImage from './fixer.png';
import lawmanImage from './lawman.png';
import mediaImage from './media.png';
import medtechImage from './medtech.png';
import netrunnerImage from './netrunner.png';
import nomadImage from './nomad.png';
import rockerboyImage from './rockerboy.png';
import soloImage from './solo.png';
import techImage from './tech.png';

export default {
  execImage,
  fixerImage,
  lawmanImage,
  mediaImage,
  medtechImage,
  netrunnerImage,
  nomadImage,
  rockerboyImage,
  soloImage,
  techImage,
};
