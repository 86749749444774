import type { DrugEffect } from 'types';

const drugEffectsData: DrugEffect[] = [
  {
    name: 'Antibiotics',
    difficulty: 10,
    description: 'These drugs prevent infection, or stop an existing one. While I could fill an entire file on microbiology, and the effects of antibiotics on germs, this would do very little for game play. Assume that an antibiotic, when employed against an infection that is susceptible to it, will cause the infection to cease spreading and the user to become well more quickly, where the strength of the drug is the multiplier. For example, someone with strep throat who takes a +2 STR antibiotic will get well twice as fast, and so on.',
    od: 'Overdosing on antibiotics will cause severe nausea and diarrhea for 1d6 days.',
  }, {
    name: 'Anticoagulant',
    difficulty: 10,
    description: 'The opposite of coagulants. Anticoagulants prevent blood from clotting. While usable as a quick antidote from a coagulant overdose, such drugs can also be employed to allow an affected user to bleed to death. Assume that someone who has used anticoagulants and is subsequently wounded for four or more points of damage will lose an additional point per turn until he dies from blood loss or the bleeding is stopped.',
    od: 'Extreme hemophilia will result in the case of anticoagulant overdose. Even the smallest cuts (1 point of damage or more) will bleed heavily, and the user will suffer 1 point of damage per turn until he exsanguinates or the wound is stanched.',
  }, {
    name: 'Antidote',
    difficulty: 15,
    description: 'Antidotes add their strength to saves made against poisons, toxins, etc. Normally, these drugs are tailored to reduce the effects of one specific substance, or negate them altogether.',
    od: 'Most antidotes are almost poisons in their own right, and anyone overdosing on them will suffer the effects of minor poisoning. Treat this poisoning as the drug’s STR in D6 poison damage. For example, overdosing on a +3 STR antidote would cause 3D6 damage to the user.',
  }, {
    name: 'Aphrodisiacs',
    difficulty: 10,
    description: 'These drugs increase a person’s sex drive and libido. The user will be physically uncomfortable unless involved in a sexual situation, and in the case of some stronger drugs, this may not even help unless the user is continually stimulated. While under the influence, the user has a negative modifier to seduction checks equal to the drug strength.',
    od: 'The character’s sexual urges cannot be satisfied, and his state of arousal will not diminish for up to several days or weeks.',
  }, {
    name: 'Attribute Increase',
    difficulty: 20,
    description: 'This increases an attribute by the drug’s strength rating for the drug’s duration. Almost any attribute can be increased. Such attributes include Reflex, Intelligence, Body, Movement Allowance, Empathy, Cool, and Tech.',
    od: ' If an overdose occurs, roll 1d10. On a roll of 1-5, you have lost a point from the attribute in question, permanently.',
  }, {
    name: 'Coagulant',
    difficulty: 10,
    description: 'These drugs cause blood to clot, and prevent blood loss. In games where blood loss is a genuine concern, assume that a patient’s blood loss is reduced by as many points as the drug has in STR. In other cases where blood loss does not apply, the drug STR may be added to a First Aid or Medtech roll when stabilizing a patient.',
    od: 'An overdose of coagulants can cause blood to clot inside a healthy body, unavoidably causing death. Make a death save as if you were at Mortal 2, with a -4 modifier. Failure indicates death.',
  }, {
    name: 'Contraceptive',
    difficulty: 10,
    description: 'These drugs prevent pregnancy. They have a base effectiveness of 80%, plus 5% for every point of drug strength.',
    od: 'An overdose of contraceptive drugs can cause sterility. Roll 1d10. On a roll of 1, the user is sterile and cannot sire children.',
  }, {
    name: 'Depressant',
    difficulty: 5,
    description: 'Depressants slow you down. They reduce tension, stress, and help you relax. The down side is that they also reduce your Reflex score by an amount equal to the drug’s strength. While you may wonder why someone might want to take a drug like this, remember that alcohol is a depressant, and it’s the most popular drug around.',
    od: 'Taking too many depressants will cause the character to make a stun/shock at -2 or slip into a deep sleep/coma for 1d10 hours.',
  }, {
    name: 'Enhanced Perception',
    difficulty: 15,
    description: 'The user of these sorts of drugs starts to notice the most intricate of details. While this adds the drug’s strength to his Awareness score for the duration, the user may be overcome by his increased sensory powers. Loud noises, bright lights, intense tastes, and strong odors have twice the normal effect on him.',
    od: 'Anyone suffering the effects of overdosing on Enhanced Perception drugs will be completely overwhelmed by his senses, and cannot perform any actions. There is a 25% chance that he will suffer from terrible hallucinations.',
  }, {
    name: 'Euphoric',
    difficulty: 5,
    description: 'These drugs make you feel really, REALLY good. They give a “rush” that lasts for as long as the drug’s duration.',
    od: 'Overdosing on a euphoric generally makes you very sick, including nausea, cold sweats, and either sleeplessness or sleepiness.',
  }, {
    name: 'Hallucinogen',
    difficulty: 10,
    description: 'Wow, man, look at the butterflies. These drugs make the user see things that aren’t there. While under the influence, or “tripping,” the user is prone to see anything his frying imagination can cook up. Such hallucinations depend on the mood of the subject (as well as the GM!), but aren’t usually terrible unless the drug itself is bad.',
    od: 'If overdosed on, assume that the hallucinogenic “trip” goes completely bad. In the case of extremely traumatic hallucinations, mental illness may result (ie, phobias, etc.).',
  }, {
    name: 'Hypnotic',
    difficulty: 15,
    description: 'Hypnotic drugs, in high doses, are used to produce sleep during surgery. In low doses, they make many users speak and talk less guardedly about things they might otherwise have trouble speaking about. Due to this side-effect, hypnotics are routinely used as “truth serums” by corporate, military, and government agencies. In game terms, hypnotic drugs reduce the user’s COOL by their strength, while at the same time granting a bonus equal to the drug’s strength to anyone using Interrogation, Human Perception, or Interview on the subject.',
    od: 'Hypnotics, if taken in large doses, cause a deep anesthesia for 1d10 hours.',
  }, {
    name: 'Increased Endurance',
    difficulty: 10,
    description: 'These drugs include most stimulants. While under the influence of these types of drugs, the user has increased energy and stamina. This is reflected by adding the drug’s strength to his Endurance skill checks. However, someone under the influence of such a drug may have trouble sleeping.',
    od: ' In the case of an OD, the user must make a stun/shock save at -2. In the case of a failure, he suffers from a heart attack, and must then make a death save as if at Mortal 0 or die.',
  }, {
    name: 'Increased Healing Rate',
    difficulty: 15,
    description: 'These drugs speed up the body’s natural healing process by .5 points per point of drug strength per day. Available only by prescription.',
    od: 'If healing drugs are taken to the point of overdose, assume that the user enters a severe state of shock as the body attempts to heal nonexistent wounds and injuries, and must make a death save at Mortal 0 or die.',
  }, {
    name: 'Pain Negation',
    difficulty: 10,
    description: 'Pain killers, plain and simple. These drugs allow the user to ignore wound penalties by one level per strength point the drug has. For example, someone who is seriously wounded and takes a +1 STR pain killer acts as if he is only lightly wounded, and so forth.',
    od: 'The user must make a successful unmodified stun/shock save or slip into a coma for 1d10 hours.',
  }, {
    name: 'Soporific',
    difficulty: 5,
    description: 'Soporifics put you to sleep. Otherwise known as sleeping pills or tranquilizers. A user who wishes to resist the effects of these drugs must roll a body check using the strength of the drug as a negative modifier. Failure indicates immediate unconsciousness for the duration of the drug. Success means that the user gets a negative modifier equal to the drug’s STR to ALL actions until the drug wears off.',
    od: 'We all know what happens when you take too many sleeping pills. You go to sleep and never wake up. Assume that without proper treatment, someone who has ODed on soporifics will die (no death save is given) or enter a deep coma which will last for 1d10 days.',
  }, {
    name: 'Stun Reducers',
    difficulty: 10,
    description: 'Like pain killers, but these are usually taken before one is actually wounded. They allow the user to gain a bonus to his stun/shock and death saves, +1 for each point of drug strength. People under the influence of powerful stun-reducing drugs have been known to walk through a shower of high caliber bullets as if pebbles were being rained upon them. Of course, most of them died later on.',
    od: 'In the case of most stun reducing drugs, an overdose will cause the user to become extremely hostile and enter a psychotic rage akin to the side effect (see SIDE EFFECTS).',
  },
];

export default drugEffectsData;
