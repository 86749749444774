import React, { useState } from 'react';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';

import { Card } from 'components/UI';

import StatOption from 'components/StatOption';

import skillsData from 'skills';

import styles from './styles.module.scss';

const SkillsTable = () => {
  const [points, setPoints] = useState(60);
  const [skills, setSkills] = useState({ ...skillsData });

  const groupedSkills = groupBy(skillsData, (skillData) => skillData.skillCategory);

  const onAdd = (skill: string) => {
    if (skills[skill].value === 6 || points === 0) {
      return;
    }
    const newSkills = { ...skillsData };
    newSkills[skill].value += 1;
    setSkills({ ...newSkills });
    if (skills[skill].isX2) {
      setPoints(points - 2);
    } else {
      setPoints(points - 1);
    }
  };

  const onDecrease = (skill: string) => {
    if (skills[skill].value === 0) {
      return;
    }
    const newSkills = { ...skillsData };
    newSkills[skill].value -= 1;
    setSkills({ ...newSkills });
    if (skills[skill].isX2) {
      setPoints(points + 2);
    } else {
      setPoints(points + 1);
    }
  };

  return (
    <Card style={{ width: '100%' }}>
      <div className={styles.skillsTable}>
        <div className={styles.pointsTracker}>
          {points}
        </div>
        {map(groupedSkills, (skillGroup, groupName) => (
          <div key={groupName} className={styles.skillGroup}>
            <div className={styles.skillGroupHeader}>
              {groupName}
            </div>
            <div className={styles.skillGroupTable}>
              {
                  skillGroup.map((skill) => (
                    <StatOption
                      key={skill.name}
                      title={`${skill.name} ${skill.isX2 ? ' (X2)' : ''}`}
                      withControls
                      bold={skill.isBase}
                      tooltip={skill.description}
                      value={skill.value}
                      onAdd={() => onAdd(skill.name)}
                      onDecrease={() => onDecrease(skill.name)}
                    />
                  ))
                }
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default SkillsTable;
