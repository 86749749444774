import React from 'react';
import { Card } from 'components/UI';

import * as equipmentData from './equipmentData';

import MeleeWeaponBlock from './MeleeWeaponBlock';
import RangedWeaponBlock from './RangedWeaponBlock';

import styles from './styles.module.scss';

const Equipment = () => (
  <div className={styles.base}>
    <MeleeWeaponBlock />
    <RangedWeaponBlock />
    <Card>
      <div className={styles.cardHeader}>
        Gear
      </div>
      {equipmentData.gearData.map((gear) => (
        <div key={gear.name}>{gear.name}</div>
      ))}
    </Card>
  </div>
);

export default Equipment;
