import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaCaretRight } from 'react-icons/fa';

import styles from './styles.module.scss';

type MenuItemProps = {
  title: string,
  children?: React.ReactNode,
  onClick?: ()=>void,
  collapsible?: boolean,
};

const MenuItem = ({
  title, children = null, onClick = () => {}, collapsible = false,
}: MenuItemProps) => {
  const [collapsed, setCollapsed] = useState(collapsible);
  return (
    <div
      className={styles.menuItem}
    >
      <div
        className={styles.title}
        role="link"
        tabIndex={0}
        onClick={collapsible ? () => setCollapsed(!collapsed) : onClick}
      >
        <div>
          {title}
        </div>
        {collapsible && <FaCaretRight style={{ marginLeft: 'auto' }} />}
      </div>
      {children && !collapsed && <div style={{ marginLeft: '1rem' }}>{children}</div>}
    </div>
  );
};

type SidemenuProps = {
  onHeaderClick: () => void
};

const Sidemenu = ({ onHeaderClick }:SidemenuProps) => {
  const history = useHistory();
  return (
    <div className={styles.sideMenu}>
      <div
        role="button"
        tabIndex={0}
        onClick={onHeaderClick}
        className={styles.header}
      >
        CP_RED.boost
      </div>
      <div className={styles.content}>
        <MenuItem
          title="Lithepath"
          onClick={() => history.push('/character-generator')}
        />
        <MenuItem title="Stats Calc" collapsible>
          <MenuItem
            onClick={() => history.push('/stats/streetrats')}
            title="Streetrat"
          />
          <MenuItem
            title="Edgerunner"
          />
          <MenuItem
            onClick={() => history.push('/stats/complete-package')}
            title="Complete Package"
          />
        </MenuItem>
        <MenuItem
          title="Generators"
          onClick={() => history.push('/generators')}
        />
        <MenuItem
          title="Info"
          onClick={() => history.push('/info')}
          collapsible
        >
          <MenuItem
            title="NPC List"
            onClick={() => history.push('/info/npc-list')}
          />
          <MenuItem
            title="Equipment"
            onClick={() => history.push('/info/equipment')}
          />
        </MenuItem>
      </div>
    </div>
  );
};

export default Sidemenu;
