import type { SkillValue } from '../../../types';

const skillByRole: { [key: string]: SkillValue[] } = {
  rockerboy: [
    {
      name: 'Athletics',
      value: 2,
    }, {
      name: 'Brawling',
      value: 6,
    }, {
      name: 'Concentration',
      value: 2,
    }, {
      name: 'Conversation',
      value: 2,
    }, {
      name: 'Education',
      value: 2,
    }, {
      name: 'Evasion',
      value: 6,
    }, {
      name: 'First Aid',
      value: 6,
    }, {
      name: 'Human Perception',
      value: 6,
    }, {
      name: 'Language (Streetslang)',
      value: 2,
    }, {
      name: 'Local Expert (Your Home)',
      value: 4,
    }, {
      name: 'Perception',
      value: 2,
    }, {
      name: 'Persuasion',
      value: 6,
    }, {
      name: 'Stealth',
      value: 2,
    }, {
      name: 'Composition',
      value: 6,
    }, {
      name: 'Handgun',
      value: 6,
    }, {
      name: 'Melee Weapon',
      value: 6,
    }, {
      name: 'Personal Grooming',
      value: 4,
    }, {
      name: 'Play Instrument',
      value: 6,
    }, {
      name: 'Streetwise',
      value: 6,
    }, {
      name: 'Wardrobe & Style',
      value: 4,
    },
  ],
  solo: [
    {
      name: 'Athletics',
      value: 2,
    }, {
      name: 'Brawling',
      value: 2,
    }, {
      name: 'Concentration',
      value: 2,
    }, {
      name: 'Conversation',
      value: 2,
    }, {
      name: 'Education',
      value: 2,
    }, {
      name: 'Evasion',
      value: 6,
    }, {
      name: 'First Aid',
      value: 6,
    }, {
      name: 'Human Perception',
      value: 2,
    }, {
      name: 'Language (Streetslang)',
      value: 2,
    }, {
      name: 'Local Expert (Your Home)',
      value: 2,
    }, {
      name: 'Perception',
      value: 6,
    }, {
      name: 'Persuasion',
      value: 2,
    }, {
      name: 'Stealth',
      value: 2,
    }, {
      name: 'Autofire',
      value: 6,
    }, {
      name: 'Handgun',
      value: 6,
    }, {
      name: 'Interrogation',
      value: 6,
    }, {
      name: 'Melee Weapon',
      value: 6,
    }, {
      name: 'Resist Torture/Drugs',
      value: 6,
    }, {
      name: 'Shoulder Arms',
      value: 6,
    }, {
      name: 'Tactics',
      value: 6,
    },
  ],
  netrunner: [
    {
      name: 'Athletics',
      value: 2,
    }, {
      name: 'Brawling',
      value: 2,
    }, {
      name: 'Concentration',
      value: 2,
    }, {
      name: 'Conversation',
      value: 2,
    }, {
      name: 'Education',
      value: 6,
    }, {
      name: 'Evasion',
      value: 6,
    }, {
      name: 'First Aid',
      value: 2,
    }, {
      name: 'Human Perception',
      value: 2,
    }, {
      name: 'Language (Streetslang)',
      value: 2,
    }, {
      name: 'Local Expert (Your Home)',
      value: 2,
    }, {
      name: 'Perception',
      value: 2,
    }, {
      name: 'Persuasion',
      value: 2,
    }, {
      name: 'Stealth',
      value: 6,
    }, {
      name: 'Basic Tech',
      value: 6,
    }, {
      name: 'Conceal/Reveal Object',
      value: 6,
    }, {
      name: 'Cryptography',
      value: 6,
    }, {
      name: 'Cybertech',
      value: 6,
    }, {
      name: 'Electronics/Security Tech',
      value: 6,
    }, {
      name: 'Handgun',
      value: 6,
    }, {
      name: 'Library Search',
      value: 6,
    },
  ],
  tech: [
    {
      name: 'Athletics',
      value: 2,
    }, {
      name: 'Brawling',
      value: 2,
    }, {
      name: 'Concentration',
      value: 2,
    }, {
      name: 'Conversation',
      value: 2,
    }, {
      name: 'Education',
      value: 6,
    }, {
      name: 'Evasion',
      value: 6,
    }, {
      name: 'First Aid',
      value: 6,
    }, {
      name: 'Human Perception',
      value: 2,
    }, {
      name: 'Language (Streetslang)',
      value: 2,
    }, {
      name: 'Local Expert (Your Home)',
      value: 2,
    }, {
      name: 'Perception',
      value: 2,
    }, {
      name: 'Persuasion',
      value: 2,
    }, {
      name: 'Stealth',
      value: 2,
    }, {
      name: 'Basic Tech',
      value: 6,
    }, {
      name: 'Cybertech',
      value: 6,
    }, {
      name: 'Electronics/Security Tech',
      value: 6,
    }, {
      name: 'Land Vehicle Tech',
      value: 6,
    }, {
      name: 'Shoulder Arms',
      value: 6,
    }, {
      name: 'Science',
      value: 6,
    }, {
      name: 'Weaponstech',
      value: 6,
    },
  ],
  medtech: [
    {
      name: 'Athletics',
      value: 2,
    }, {
      name: 'Brawling',
      value: 2,
    }, {
      name: 'Concentration',
      value: 2,
    }, {
      name: 'Conversation',
      value: 6,
    }, {
      name: 'Education',
      value: 6,
    }, {
      name: 'Evasion',
      value: 6,
    }, {
      name: 'First Aid',
      value: 2,
    }, {
      name: 'Human Perception',
      value: 6,
    }, {
      name: 'Language (Streetslang)',
      value: 2,
    }, {
      name: 'Local Expert (Your Home)',
      value: 2,
    }, {
      name: 'Perception',
      value: 2,
    }, {
      name: 'Persuasion',
      value: 2,
    }, {
      name: 'Stealth',
      value: 2,
    }, {
      name: 'Basic Tech',
      value: 6,
    }, {
      name: 'Cybertech',
      value: 4,
    }, {
      name: 'Deduction',
      value: 6,
    }, {
      name: 'Paramedic',
      value: 6,
    }, {
      name: 'Resist Torture/Drugs',
      value: 4,
    }, {
      name: 'Science',
      value: 6,
    }, {
      name: 'Shoulder Arms',
      value: 6,
    },
  ],
  media: [
    {
      name: 'Athletics',
      value: 2,
    }, {
      name: 'Brawling',
      value: 2,
    }, {
      name: 'Concentration',
      value: 2,
    }, {
      name: 'Conversation',
      value: 6,
    }, {
      name: 'Education',
      value: 2,
    }, {
      name: 'Evasion',
      value: 6,
    }, {
      name: 'First Aid',
      value: 2,
    }, {
      name: 'Human Perception',
      value: 6,
    }, {
      name: 'Language (Streetslang)',
      value: 2,
    }, {
      name: 'Local Expert (Your Home)',
      value: 6,
    }, {
      name: 'Perception',
      value: 6,
    }, {
      name: 'Persuasion',
      value: 6,
    }, {
      name: 'Stealth',
      value: 2,
    }, {
      name: 'Bribery',
      value: 6,
    }, {
      name: 'Composition',
      value: 6,
    }, {
      name: 'Deduction',
      value: 6,
    }, {
      name: 'Handgun',
      value: 6,
    }, {
      name: 'Library Search',
      value: 4,
    }, {
      name: 'Lip Reading',
      value: 4,
    }, {
      name: 'Photography/Film',
      value: 4,
    },
  ],
  lawman: [
    {
      name: 'Athletics',
      value: 2,
    }, {
      name: 'Brawling',
      value: 6,
    }, {
      name: 'Concentration',
      value: 2,
    }, {
      name: 'Conversation',
      value: 6,
    }, {
      name: 'Education',
      value: 2,
    }, {
      name: 'Evasion',
      value: 6,
    }, {
      name: 'First Aid',
      value: 2,
    }, {
      name: 'Human Perception',
      value: 2,
    }, {
      name: 'Language (Streetslang)',
      value: 2,
    }, {
      name: 'Local Expert (Your Home)',
      value: 2,
    }, {
      name: 'Perception',
      value: 2,
    }, {
      name: 'Persuasion',
      value: 2,
    }, {
      name: 'Stealth',
      value: 2,
    }, {
      name: 'Autofire',
      value: 6,
    }, {
      name: 'Criminology',
      value: 6,
    }, {
      name: 'Deduction',
      value: 6,
    }, {
      name: 'Handgun',
      value: 6,
    }, {
      name: 'Interrogation',
      value: 6,
    }, {
      name: 'Shoulder Arms',
      value: 6,
    }, {
      name: 'Tracking',
      value: 6,
    },
  ],
  exec: [
    {
      name: 'Athletics',
      value: 2,
    }, {
      name: 'Brawling',
      value: 2,
    }, {
      name: 'Concentration',
      value: 2,
    }, {
      name: 'Conversation',
      value: 6,
    }, {
      name: 'Education',
      value: 2,
    }, {
      name: 'Evasion',
      value: 6,
    }, {
      name: 'First Aid',
      value: 2,
    }, {
      name: 'Human Perception',
      value: 6,
    }, {
      name: 'Language (Streetslang)',
      value: 4,
    }, {
      name: 'Local Expert (Your Home)',
      value: 6,
    }, {
      name: 'Perception',
      value: 2,
    }, {
      name: 'Persuasion',
      value: 6,
    }, {
      name: 'Stealth',
      value: 2,
    }, {
      name: 'Accounting',
      value: 6,
    }, {
      name: 'Bureaucracy',
      value: 6,
    }, {
      name: 'Business',
      value: 6,
    }, {
      name: 'Deduction',
      value: 6,
    }, {
      name: 'Handgun',
      value: 6,
    }, {
      name: 'Lip Reading',
      value: 6,
    }, {
      name: 'Personal Grooming',
      value: 4,
    },
  ],
  fixer: [
    {
      name: 'Athletics',
      value: 2,
    }, {
      name: 'Brawling',
      value: 2,
    }, {
      name: 'Concentration',
      value: 2,
    }, {
      name: 'Conversation',
      value: 6,
    }, {
      name: 'Education',
      value: 2,
    }, {
      name: 'Evasion',
      value: 6,
    }, {
      name: 'First Aid',
      value: 2,
    }, {
      name: 'Human Perception',
      value: 6,
    }, {
      name: 'Language (Streetslang)',
      value: 4,
    }, {
      name: 'Local Expert (Your Home)',
      value: 6,
    }, {
      name: 'Perception',
      value: 2,
    }, {
      name: 'Persuasion',
      value: 4,
    }, {
      name: 'Stealth',
      value: 2,
    }, {
      name: 'Bribery',
      value: 6,
    }, {
      name: 'Business',
      value: 6,
    }, {
      name: 'Forgery',
      value: 6,
    }, {
      name: 'Handgun',
      value: 6,
    }, {
      name: 'Pick Lock',
      value: 4,
    }, {
      name: 'Streetwise',
      value: 6,
    }, {
      name: 'Trading',
      value: 6,
    },
  ],
  nomad: [
    {
      name: 'Athletics',
      value: 2,
    }, {
      name: 'Brawling',
      value: 6,
    }, {
      name: 'Concentration',
      value: 2,
    }, {
      name: 'Conversation',
      value: 2,
    }, {
      name: 'Education',
      value: 2,
    }, {
      name: 'Evasion',
      value: 6,
    }, {
      name: 'First Aid',
      value: 6,
    }, {
      name: 'Human Perception',
      value: 2,
    }, {
      name: 'Language (Streetslang)',
      value: 2,
    }, {
      name: 'Local Expert (Your Home)',
      value: 2,
    }, {
      name: 'Perception',
      value: 4,
    }, {
      name: 'Persuasion',
      value: 2,
    }, {
      name: 'Stealth',
      value: 6,
    }, {
      name: 'Animal Handling',
      value: 6,
    }, {
      name: 'Drive Land Vehicle',
      value: 6,
    }, {
      name: 'Handgun',
      value: 6,
    }, {
      name: 'Melee Weapon',
      value: 6,
    }, {
      name: 'Tracking',
      value: 6,
    }, {
      name: 'Trading',
      value: 6,
    }, {
      name: 'Wilderness Survival',
      value: 6,
    },
  ],
};

export default skillByRole;
