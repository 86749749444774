import { useState } from 'react';
import getRandom from './generator';

const useRerandom = (maxNum: number): [number, () => void] => {
  const [randomValue, setRandomValue] = useState<number>(getRandom(maxNum));

  const triggerRerandom = () => {
    let newValue = getRandom(maxNum);
    while (newValue === randomValue) {
      newValue = getRandom(maxNum);
    }
    setRandomValue(newValue);
  };

  return [randomValue, triggerRerandom];
};

export default useRerandom;
