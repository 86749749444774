import React from 'react';
import { NPC } from 'types';
import { Card } from 'components/UI';

import StatsTable from './StatsTable';
import SkillsTable from './SkillsTable';

import styles from './styles.module.scss';

type NPCStatBlockProps = {
  npc: NPC,
};

const NPCStatBlock = ({ npc }: NPCStatBlockProps) => (
  <Card style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <div className={styles.npcName}>{npc.name}</div>
    <div className={styles.content}>
      <div className={styles.firstColumn}>
        <StatsTable stats={npc.stats} />
        <div className={styles.derivedStats}>
          <div>
            {`HP: ${npc.hp}`}
          </div>
          <div>
            {`Seriously Wounded: ${npc.swThreshold}`}
          </div>
          <div>
            {`Death Save: ${npc.deathSave}`}
          </div>
        </div>
        <div className={styles.npcInfo}>
          <div className={styles.infoBlock}>
            <div className={styles.infoBlockHeader}>
              Armor:
            </div>
            <div className={styles.infoBlockContent}>
              <div>
                {`Head: ${npc.armor.head}`}
              </div>
              <div>
                {`Body: ${npc.armor.body}`}
              </div>
            </div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.infoBlockHeader}>
              Weapons:
            </div>
            <div className={styles.infoBlockContent}>
              {npc.weapons.map((weapon) => (
                <div key={weapon}>
                  {weapon}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.infoBlockHeader}>
              Equipment and Cyberware:
            </div>
            <div className={styles.infoBlockContent}>
              {npc.equipment.map((item) => (
                <div key={item}>{item}</div>
              ))}
            </div>
          </div>
          {npc.programs && (
          <div className={styles.infoBlock}>
            <div className={styles.infoBlockHeader}>
              Programs:
            </div>
            <div className={styles.infoBlockContent}>
              {npc.programs.map((program) => (
                <div key={program}>{program}</div>
              ))}
            </div>
          </div>
          )}
        </div>
      </div>
      <SkillsTable skills={npc.skillBases} />
    </div>
  </Card>
);

export default NPCStatBlock;
