import React, { useState } from 'react';

import { Dropdown, ButtonToRandom, Card } from 'components/UI';

import useRerandom from 'utils/rerandomHook';

import type { EncounterCategories } from 'data/Encounters/encounters';
import { encounterTypes, encounters } from 'data/Encounters/encounters';
import EncounterBlock from './EncounterBlock';

import styles from './styles.module.scss';

const RandomEncounter = () => {
  const [encounterType, setEncounterType] = useState<EncounterCategories>('daytime');
  const [randomValue, triggerRerandom] = useRerandom(100);
  return (
    <Card>
      <div className={styles.baseBlock}>
        <div className={styles.header}>
          Random encounter
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Dropdown<EncounterCategories>
            value={encounterType}
            options={encounterTypes}
            onChange={(option: EncounterCategories) => setEncounterType(option)}
          />
          <ButtonToRandom onClick={triggerRerandom} />
        </div>
        <EncounterBlock
          value={randomValue}
          table={encounters[encounterType]}
        />
      </div>
    </Card>
  );
};

export default RandomEncounter;
