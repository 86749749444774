import type { NPC } from '../../../types';

const npcData: NPC[] = [
  {
    name: 'Bodyguard',
    stats: {
      int: 3,
      ref: 6,
      dex: 5,
      tech: 2,
      cool: 4,
      will: 4,
      luck: 0,
      move: 4,
      body: 6,
      emp: 3,
    },
    hp: 35,
    swThreshold: 18,
    deathSave: 6,
    weapons: [
      'Poor Quality Shotgun 5d6',
      'Very Heavy Pistol 4d6',
    ],
    armor: {
      head: 'Kevlar® 7 SP',
      body: 'Kevlar® 7 SP',
    },
    skillBases: [
      {
        name: 'Athletics',
        value: 9,
      }, {
        name: 'Brawling',
        value: 11,
      }, {
        name: 'Concentration',
        value: 6,
      }, {
        name: 'Conversation',
        value: 5,
      }, {
        name: 'Drive Land Vehicle',
        value: 10,
      }, {
        name: 'Education',
        value: 5,
      }, {
        name: 'Endurance',
        value: 9,
      }, {
        name: 'Evasion',
        value: 7,
      }, {
        name: 'First Aid',
        value: 4,
      }, {
        name: 'Handgun',
        value: 10,
      }, {
        name: 'Human Perception',
        value: 5,
      }, {
        name: 'Interrogation',
        value: 6,
      }, {
        name: 'Language (Native)',
        value: 5,
      }, {
        name: 'Language (Streetslang)',
        value: 5,
      }, {
        name: 'Local Expert (Your Home)',
        value: 5,
      }, {
        name: 'Perception',
        value: 9,
      }, {
        name: 'Persuasion',
        value: 6,
      }, {
        name: 'Resist Torture/Drug',
        value: 8,
      }, {
        name: 'Shoulder Arms',
        value: 10,
      }, {
        name: 'Stealth',
        value: 7,
      },
    ],
    equipment: [
      'Slug Ammo x25', 'Very Heavy Pistol Ammo x25', 'Radio Communicator',
    ],
  },
  {
    name: 'Boosterganger',
    stats: {
      int: 2,
      ref: 6,
      dex: 5,
      tech: 2,
      cool: 4,
      will: 2,
      luck: 0,
      move: 4,
      body: 4,
      emp: 3,
    },
    hp: 20,
    swThreshold: 10,
    deathSave: 4,
    weapons: [
      'Poor Quality VH Pistol 4d6',
      'Rippers 2d6',
    ],
    armor: {
      head: 'Leather 4 SP',
      body: 'Leather 4 SP',
    },
    skillBases: [
      {
        name: 'Athletics',
        value: 9,
      }, {
        name: 'Brawling',
        value: 9,
      }, {
        name: 'Conceal/Reveal Object',
        value: 4,
      }, {
        name: 'Concentration',
        value: 4,
      }, {
        name: 'Conversation',
        value: 5,
      }, {
        name: 'Drive Land Vehicle',
        value: 10,
      }, {
        name: 'Education',
        value: 4,
      }, {
        name: 'Endurance',
        value: 6,
      }, {
        name: 'Evasion',
        value: 7,
      }, {
        name: 'First Aid',
        value: 4,
      }, {
        name: 'Handgun',
        value: 12,
      }, {
        name: 'Human Perception',
        value: 5,
      }, {
        name: 'Interrogation',
        value: 6,
      }, {
        name: 'Language (Native)',
        value: 4,
      }, {
        name: 'Language (Streetslang)',
        value: 4,
      }, {
        name: 'Local Expert (Your Home)',
        value: 4,
      }, {
        name: 'Melee Weapon',
        value: 11,
      }, {
        name: 'Perception',
        value: 6,
      }, {
        name: 'Persuasion',
        value: 6,
      }, {
        name: 'Resist Torture/Drug',
        value: 4,
      }, {
        name: 'Stealth',
        value: 7,
      },
    ],
    equipment: [
      'VH Pistol Ammo x30', 'Disposable Cellphone', 'Rippers', 'Techhair',
    ],
  },
  {
    name: 'Road Ganger',
    stats: {
      int: 4,
      ref: 6,
      dex: 4,
      tech: 4,
      cool: 3,
      will: 3,
      luck: 0,
      move: 3,
      body: 3,
      emp: 3,
    },
    hp: 25,
    swThreshold: 13,
    deathSave: 3,
    weapons: [
      'Very Heavy Pistol 4d6',
      'Crossbow 4d6',
      'Light Melee Weapon 1d6',
    ],
    armor: {
      head: 'Leather 4 SP',
      body: 'Leather 4 SP',
    },
    skillBases: [
      {
        name: 'Archery',
        value: 10,
      }, {
        name: 'Athletics',
        value: 10,
      }, {
        name: 'Brawling',
        value: 6,
      }, {
        name: 'Concentration',
        value: 5,
      }, {
        name: 'Conversation',
        value: 6,
      }, {
        name: 'Drive Land Vehicle',
        value: 12,
      }, {
        name: 'Education',
        value: 6,
      }, {
        name: 'Endurance',
        value: 5,
      }, {
        name: 'Evasion',
        value: 6,
      }, {
        name: 'First Aid',
        value: 6,
      }, {
        name: 'Handgun',
        value: 10,
      }, {
        name: 'Human Perception',
        value: 5,
      }, {
        name: 'Land Vehicle Tech',
        value: 12,
      }, {
        name: 'Language (Native)',
        value: 6,
      }, {
        name: 'Language (Streetslang)',
        value: 6,
      }, {
        name: 'Local Expert (Your Home)',
        value: 6,
      }, {
        name: 'Melee Weapon',
        value: 8,
      }, {
        name: 'Perception',
        value: 6,
      }, {
        name: 'Persuasion',
        value: 5,
      }, {
        name: 'Stealth',
        value: 8,
      }, {
        name: 'Tracking',
        value: 8,
      }, {
        name: 'Wilderness Survival',
        value: 8,
      },
    ],
    equipment: [
      'VH Pistol Ammo x20', 'Arrow Ammo x20', 'Rope', 'Flashlight', 'Neural Link (Interface Plugs)',
    ],
  },
  {
    name: 'Security Operative',
    stats: {
      int: 3,
      ref: 7,
      dex: 4,
      tech: 2,
      cool: 2,
      will: 3,
      luck: 0,
      move: 3,
      body: 5,
      emp: 3,
    },
    hp: 30,
    swThreshold: 15,
    deathSave: 5,
    weapons: [
      'Poor Quality Assault Rifle 5d6',
      'Very Heavy Pistol 4d6',
      'Medium Melee Weapon 2d6',
    ],
    armor: {
      head: 'Kevlar® 7 SP',
      body: 'Kevlar® 7 SP',
    },
    skillBases: [
      {
        name: 'Athletics',
        value: 8,
      }, {
        name: 'Autofire',
        value: 10,
      }, {
        name: 'Brawling',
        value: 6,
      }, {
        name: 'Concentration',
        value: 7,
      }, {
        name: 'Conversation',
        value: 5,
      }, {
        name: 'Education',
        value: 5,
      }, {
        name: 'Evasion',
        value: 6,
      }, {
        name: 'First Aid',
        value: 4,
      }, {
        name: 'Handgun',
        value: 10,
      }, {
        name: 'Human Perception',
        value: 5,
      }, {
        name: 'Interrogation',
        value: 6,
      }, {
        name: 'Language (Native)',
        value: 5,
      }, {
        name: 'Language (Streetslang)',
        value: 5,
      }, {
        name: 'Local Expert (Your Home)',
        value: 5,
      }, {
        name: 'Melee Weapon',
        value: 6,
      }, {
        name: 'Perception',
        value: 5,
      }, {
        name: 'Persuasion',
        value: 4,
      }, {
        name: 'Resist Torture/Drug',
        value: 5,
      }, {
        name: 'Shoulder Arms',
        value: 10,
      }, {
        name: 'Stealth',
        value: 6,
      },
    ],
    equipment: [
      'Rifle x40', 'VH Pistol Ammo x20', 'Radio Communicator',
    ],
  },
  {
    name: 'Netrunner',
    stats: {
      int: 7,
      ref: 5,
      dex: 4,
      tech: 7,
      cool: 4,
      will: 5,
      luck: 0,
      move: 5,
      body: 3,
      emp: 4,
    },
    hp: 30,
    swThreshold: 15,
    deathSave: 3,
    weapons: [
      'Very Heavy Pistol 4d6',
    ],
    armor: {
      head: 'Bodyweight Suit 11 SP',
      body: 'Bodyweight Suit 11 SP',
    },
    uniqSkill: {
      name: 'Interface',
      value: 4,
    },
    skillBases: [
      {
        name: 'Athletics',
        value: 9,
      }, {
        name: 'Basic Tech',
        value: 13,
      }, {
        name: 'Brawling',
        value: 6,
      }, {
        name: 'Conceal/Reveal Object',
        value: 11,
      }, {
        name: 'Concentration',
        value: 9,
      }, {
        name: 'Conversation',
        value: 6,
      }, {
        name: 'Cryptography',
        value: 11,
      }, {
        name: 'Deduction',
        value: 11,
      }, {
        name: 'Education',
        value: 11,
      }, {
        name: 'Electronics/Security Tech',
        value: 11,
      }, {
        name: 'Evasion',
        value: 6,
      }, {
        name: 'First Aid',
        value: 9,
      }, {
        name: 'Forgery',
        value: 13,
      }, {
        name: 'Handgun',
        value: 10,
      }, {
        name: 'Human Perception',
        value: 6,
      }, {
        name: 'Language (Native)',
        value: 9,
      }, {
        name: 'Language (Streetslang)',
        value: 9,
      }, {
        name: 'Local Expert (Your Home)',
        value: 13,
      }, {
        name: 'Library Search',
        value: 9,
      }, {
        name: 'Perception',
        value: 11,
      }, {
        name: 'Persuasion',
        value: 6,
      }, {
        name: 'Pick Lock',
        value: 11,
      }, {
        name: 'Resist Torture/Drug',
        value: 7,
      }, {
        name: 'Stealth',
        value: 8,
      },
    ],
    equipment: [
      'VH Pistol Ammo x50', 'Flashlight', 'Virtuality Goggles', 'Neural Link (Interface Plugs)',
    ],
    programs: [
      'Banhammer', 'DeckKRASH', 'Eraser', 'Hellbolt', 'Shield', 'Sword', 'Worm',
    ],
  },
  {
    name: 'Reaclaimer Chief',
    stats: {
      int: 3,
      ref: 6,
      dex: 6,
      tech: 5,
      cool: 4,
      will: 5,
      luck: 0,
      move: 4,
      body: 6,
      emp: 4,
    },
    hp: 40,
    swThreshold: 20,
    deathSave: 6,
    weapons: [
      'Shotgun 5d6',
      'Light Melee Weapon 1d6',
      'Heavy Pistol 3d6',
      'Heavy Melee Weapon 3d6',
    ],
    armor: {
      head: 'Light Armorjack 11 SP',
      body: 'Light Armorjack 11 SP',
    },
    skillBases: [
      {
        name: 'Athletics',
        value: 12,
      }, {
        name: 'Basic Tech',
        value: 9,
      }, {
        name: 'Brawling',
        value: 8,
      }, {
        name: 'Concentration',
        value: 7,
      }, {
        name: 'Conversation',
        value: 6,
      }, {
        name: 'Deduction',
        value: 7,
      }, {
        name: 'Drive Land Vehicle',
        value: 10,
      }, {
        name: 'Education',
        value: 5,
      }, {
        name: 'Electronics/Security Tech',
        value: 9,
      }, {
        name: 'Endurance',
        value: 11,
      }, {
        name: 'Evasion',
        value: 8,
      }, {
        name: 'First Aid',
        value: 7,
      }, {
        name: 'Handgun',
        value: 10,
      }, {
        name: 'Human Perception',
        value: 6,
      }, {
        name: 'Land Vehicle Tech',
        value: 7,
      }, {
        name: 'Interrogation',
        value: 6,
      }, {
        name: 'Language (Native)',
        value: 5,
      }, {
        name: 'Language (Streetslang)',
        value: 5,
      }, {
        name: 'Local Expert (Your Home)',
        value: 5,
      }, {
        name: 'Melee Weapon',
        value: 10,
      }, {
        name: 'Paramedic',
        value: 7,
      }, {
        name: 'Perception',
        value: 8,
      }, {
        name: 'Persuasion',
        value: 6,
      }, {
        name: 'Pick Lock',
        value: 7,
      }, {
        name: 'Resist Torture/Drug',
        value: 10,
      }, {
        name: 'Shoulder Arms',
        value: 10,
      }, {
        name: 'Stealth',
        value: 10,
      }, {
        name: 'Weaponstech',
        value: 9,
      }, {
        name: 'Wilderness Survival',
        value: 7,
      },
    ],
    equipment: [
      'Slug Ammo x25', 'H Pistol Ammo x25', 'Agent', 'Grapple Gun', 'Radio Communicator',
      'Tent & Camping Equipment', 'Nasal Filters', 'Neural Link (Chipware Socket, Tactile Boost)',
    ],
  },
  {
    name: 'Security officer',
    stats: {
      int: 4,
      ref: 8,
      dex: 6,
      tech: 4,
      cool: 6,
      will: 5,
      luck: 0,
      move: 6,
      body: 7,
      emp: 4,
    },
    hp: 40,
    swThreshold: 20,
    deathSave: 7,
    weapons: [
      'Assault Rifle 5d6',
      'Very Heavy Pistol 4d6',
      'Medium Melee Weapon 2d6',
    ],
    armor: {
      head: 'Medium Armorjack 13 SP',
      body: 'Medium Armorjack 13 SP',
    },
    skillBases: [
      {
        name: 'Athletics',
        value: 10,
      }, {
        name: 'Autofire',
        value: 12,
      }, {
        name: 'Brawling',
        value: 10,
      }, {
        name: 'Concentration',
        value: 7,
      }, {
        name: 'Conversation',
        value: 6,
      }, {
        name: 'Deduction',
        value: 6,
      }, {
        name: 'Drive Land Vehicle',
        value: 12,
      }, {
        name: 'Education',
        value: 6,
      }, {
        name: 'Evasion',
        value: 10,
      }, {
        name: 'First Aid',
        value: 6,
      }, {
        name: 'Handgun',
        value: 10,
      }, {
        name: 'Human Perception',
        value: 6,
      }, {
        name: 'Interrogation',
        value: 8,
      }, {
        name: 'Language (Native)',
        value: 6,
      }, {
        name: 'Language (Streetslang)',
        value: 6,
      }, {
        name: 'Local Expert (Your Home)',
        value: 6,
      }, {
        name: 'Melee Weapon',
        value: 10,
      }, {
        name: 'Perception',
        value: 6,
      }, {
        name: 'Persuasion',
        value: 8,
      }, {
        name: 'Resist Torture/Drug',
        value: 10,
      }, {
        name: 'Shoulder Arms',
        value: 10,
      }, {
        name: 'Stealth',
        value: 6,
      }, {
        name: 'Tactics',
        value: 6,
      },
    ],
    equipment: [
      'Rifle Ammo x50', 'VH Pistol Ammo x30', 'Bulletproof Shield (10 HP)', 'Binoculars',
      'Disposable Cellphone', 'Flashlight', 'Handcuffs x2', 'Radio Communicator', 'Radio Scanner/Music Player',
      'Neural Link (Kerenzikov Speedware)',
    ],
  }, {
    name: 'Security officer (weaker)',
    stats: {
      int: 4,
      ref: 6,
      dex: 4,
      tech: 4,
      cool: 6,
      will: 5,
      luck: 0,
      move: 4,
      body: 7,
      emp: 4,
    },
    hp: 40,
    swThreshold: 20,
    deathSave: 7,
    weapons: [
      'Assault Rifle 5d6',
      'Very Heavy Pistol 4d6',
      'Medium Melee Weapon 2d6',
    ],
    armor: {
      head: 'Medium Armorjack 13 SP',
      body: 'Medium Armorjack 13 SP',
    },
    skillBases: [
      {
        name: 'Athletics',
        value: 8,
      }, {
        name: 'Autofire',
        value: 10,
      }, {
        name: 'Brawling',
        value: 8,
      }, {
        name: 'Concentration',
        value: 7,
      }, {
        name: 'Conversation',
        value: 6,
      }, {
        name: 'Deduction',
        value: 6,
      }, {
        name: 'Drive Land Vehicle',
        value: 10,
      }, {
        name: 'Education',
        value: 6,
      }, {
        name: 'Evasion',
        value: 8,
      }, {
        name: 'First Aid',
        value: 6,
      }, {
        name: 'Handgun',
        value: 8,
      }, {
        name: 'Human Perception',
        value: 6,
      }, {
        name: 'Interrogation',
        value: 8,
      }, {
        name: 'Language (Native)',
        value: 6,
      }, {
        name: 'Language (Streetslang)',
        value: 6,
      }, {
        name: 'Local Expert (Your Home)',
        value: 6,
      }, {
        name: 'Melee Weapon',
        value: 8,
      }, {
        name: 'Perception',
        value: 6,
      }, {
        name: 'Persuasion',
        value: 8,
      }, {
        name: 'Resist Torture/Drug',
        value: 10,
      }, {
        name: 'Shoulder Arms',
        value: 8,
      }, {
        name: 'Stealth',
        value: 4,
      }, {
        name: 'Tactics',
        value: 4,
      },
    ],
    equipment: [
      'Rifle Ammo x50', 'VH Pistol Ammo x30', 'Bulletproof Shield (10 HP)', 'Binoculars',
      'Disposable Cellphone', 'Flashlight', 'Handcuffs x2', 'Radio Communicator', 'Radio Scanner/Music Player',
      'Neural Link (Kerenzikov Speedware)',
    ],
  },
  {
    name: 'Outrider',
    stats: {
      int: 6,
      ref: 8,
      dex: 8,
      tech: 3,
      cool: 5,
      will: 6,
      luck: 0,
      move: 6,
      body: 6,
      emp: 6,
    },
    hp: 40,
    swThreshold: 20,
    deathSave: 6,
    weapons: [
      'Assault Rifle 5d6',
      'Very Heavy Pistol 4d6',
      'Light Melee Weapon 1d6',
    ],
    armor: {
      head: 'Light Armorjack 11 SP',
      body: 'Light Armorjack 11 SP',
    },
    uniqSkill: {
      name: 'Moto Family',
      value: 4,
    },
    skillBases: [
      {
        name: 'Animal Handling',
        value: 8,
      }, {
        name: 'Athletics',
        value: 14,
      }, {
        name: 'Autofire',
        value: 12,
      }, {
        name: 'Basic tech',
        value: 5,
      }, {
        name: 'Brawling',
        value: 14,
      }, {
        name: 'Concentration',
        value: 10,
      }, {
        name: 'Conversation',
        value: 6,
      }, {
        name: 'Criminology',
        value: 10,
      }, {
        name: 'Drive Land Vehicle',
        value: 14,
      }, {
        name: 'Education',
        value: 8,
      }, {
        name: 'Endurance',
        value: 10,
      }, {
        name: 'Evasion',
        value: 14,
      }, {
        name: 'First Aid',
        value: 5,
      }, {
        name: 'Handgun',
        value: 14,
      }, {
        name: 'Human Perception',
        value: 8,
      }, {
        name: 'Land Vehicle Tech',
        value: 7,
      }, {
        name: 'Language (Native)',
        value: 8,
      }, {
        name: 'Language (Streetslang)',
        value: 8,
      }, {
        name: 'Local Expert (Badlands)',
        value: 10,
      }, {
        name: 'Local Expert (Your Home)',
        value: 8,
      }, {
        name: 'Melee Weapon',
        value: 12,
      }, {
        name: 'Perception',
        value: 14,
      }, {
        name: 'Persuasion',
        value: 7,
      }, {
        name: 'Resist Torture/Drug',
        value: 12,
      }, {
        name: 'Shoulder Arms',
        value: 14,
      }, {
        name: 'Stealth',
        value: 12,
      }, {
        name: 'Streetwise',
        value: 9,
      }, {
        name: 'Tracking',
        value: 10,
      },
    ],
    equipment: [
      'Rifle Ammo x60', 'VH Pistol Ammo x40', 'Handcuffs x2', 'Homing Tracers', 'Radio Communicator',
      'Cyberaudio Suite (Amplified Hearing)', 'Cybereye (Targeting Scope, TeleOptics)', 'Neural Link (Interface Plugs)',
    ],
  },
  {
    name: 'Pyro',
    stats: {
      int: 5,
      ref: 8,
      dex: 6,
      tech: 7,
      cool: 4,
      will: 4,
      luck: 0,
      move: 6,
      body: 5,
      emp: 3,
    },
    hp: 35,
    swThreshold: 18,
    deathSave: 5,
    weapons: [
      'Flamethrower 3d6',
      'Heavy Pistol 3d6',
      'Heavy Melee Weapon 3d6',
    ],
    armor: {
      head: 'Light Armorjack 11 SP',
      body: 'Light Armorjack 11 SP',
    },
    uniqSkill: {
      name: 'Combat Awareness',
      value: 4,
    },
    skillBases: [
      {
        name: 'Athletics',
        value: 11,
      }, {
        name: 'Basic Tech',
        value: 12,
      }, {
        name: 'Brawling',
        value: 10,
      }, {
        name: 'Concentration',
        value: 8,
      }, {
        name: 'Conversation',
        value: 5,
      }, {
        name: 'Demolition',
        value: 13,
      }, {
        name: 'Drive Land Vehicle',
        value: 10,
      }, {
        name: 'Education',
        value: 7,
      }, {
        name: 'Evasion',
        value: 13,
      }, {
        name: 'First Aid',
        value: 9,
      }, {
        name: 'Handgun',
        value: 14,
      }, {
        name: 'Heavy Weapons',
        value: 14,
      }, {
        name: 'Human Perception',
        value: 5,
      }, {
        name: 'Interrogation',
        value: 10,
      }, {
        name: 'Language (Native)',
        value: 7,
      }, {
        name: 'Language (Streetslang)',
        value: 7,
      }, {
        name: 'Local Expert (Your Home)',
        value: 7,
      }, {
        name: 'Melee Weapon',
        value: 13,
      }, {
        name: 'Perception',
        value: 12,
      }, {
        name: 'Persuasion',
        value: 6,
      }, {
        name: 'Resist Torture/Drug',
        value: 14,
      }, {
        name: 'Science (Chemistry)',
        value: 10,
      }, {
        name: 'Shoulder Arms',
        value: 10,
      }, {
        name: 'Stealth',
        value: 10,
      }, {
        name: 'Streetwise',
        value: 8,
      }, {
        name: 'Tactics',
        value: 8,
      },
    ],
    equipment: [
      'Flamethrower Ammo (Incendiary Shotgun Shells) x8', 'VH Pistol Ammo x50',
      'Incendiary Grenade x1', 'Flashbang Grenade x1', 'Cyberaudio Suite (Level Dampners)',
      'Cybereye x2 (Anti-Dazzle x2)', 'Nasal Filters',
    ],
  },
  {
    name: 'Cyberpsycho',
    stats: {
      int: 5,
      ref: 8,
      dex: 8,
      tech: 5,
      cool: 4,
      will: 7,
      luck: 0,
      move: 8,
      body: 10,
      emp: 0,
    },
    hp: 55,
    swThreshold: 28,
    deathSave: 10,
    weapons: [
      'Popup Grenade Launcher 6d6',
      'Popup Heavy SMG 3d6',
      'Cybersnake 4d6',
      'Wolvers 3d6',
    ],
    armor: {
      head: 'Subdermal 11 SP',
      body: 'Subdermal 11 SP',
    },
    skillBases: [
      {
        name: 'Athletics',
        value: 16,
      }, {
        name: 'Autofire',
        value: 14,
      }, {
        name: 'Basic Tech',
        value: 11,
      }, {
        name: 'Brawling',
        value: 15,
      }, {
        name: 'Concentration',
        value: 6,
      }, {
        name: 'Conversation',
        value: 2,
      }, {
        name: 'Drive Land Vehicle',
        value: 10,
      }, {
        name: 'Education',
        value: 7,
      }, {
        name: 'Endurance',
        value: 10,
      }, {
        name: 'Evasion',
        value: 13,
      }, {
        name: 'First Aid',
        value: 6,
      }, {
        name: 'Handgun',
        value: 12,
      }, {
        name: 'Heavy Weapons',
        value: 14,
      }, {
        name: 'Human Perception',
        value: 2,
      }, {
        name: 'Interrogation',
        value: 13,
      }, {
        name: 'Language (Native)',
        value: 7,
      }, {
        name: 'Language (Streetslang)',
        value: 7,
      }, {
        name: 'Local Expert (Your Home)',
        value: 7,
      }, {
        name: 'Melee Weapon',
        value: 17,
      }, {
        name: 'Perception',
        value: 9,
      }, {
        name: 'Persuasion',
        value: 6,
      }, {
        name: 'Resist Torture/Drug',
        value: 15,
      }, {
        name: 'Stealth',
        value: 10,
      }, {
        name: 'Tracking',
        value: 10,
      },
    ],
    equipment: [
      'Armor Piercing Grenade x2', 'Heavy Pistol Ammo x100',
      'Cyberarm x2 (Popup Grenade Launcher x2, Popup Heavy SMG, Wolvers)',
      'Cyberleg x2 (Jump Boosters x2)', 'Cybersnake', 'Grafted Muscle & Bone Lace',
      'Neural Link (Chipware Socket, Pain Editor)', 'Subdermal Armor',
    ],
  },
];

export default npcData;
