import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import capitalize from 'lodash/capitalize';
import drugEffects from 'data/Drugs/effects';
import drugFeatures from 'data/Drugs/features';
import drugTimedSideEffects from 'data/Drugs/timedSideEffects';
import drugUntimedSideEffects from 'data/Drugs/untimedSideEffects';
import drugStrengthRelatedInfoData from 'data/Drugs/strengthRelatedInfo';
import drugDurationData from 'data/Drugs/duration';
import drugLegalityData from 'data/Drugs/legality';
import drugFormData from 'data/Drugs/form';

import styles from './styles.module.scss';

import type {
  Drug,
  DrugDuration,
  DrugEffect,
  DrugFeature,
  DrugForm,
  DrugLegality,
  DrugSideEffect,
  DrugStrengthRelatedInfo,
} from '../../../../types';

type EffectBlockProps = {
  block: string,
  selectedEffect: DrugEffect,
  setSelectedEffect: (drugEffect: DrugEffect)=>void
};

const EffectBlock = ({ block, selectedEffect, setSelectedEffect }: EffectBlockProps) => (
  block === 'Effect' ? (
    <section className={styles.infoSegment}>
      {drugEffects
        .map((drugEffect) => (
          <div
            className={`${styles.dataCard} ${drugEffect.name === selectedEffect?.name ? styles.active : ''}`}
            key={drugEffect.name}
            role="button"
            tabIndex={0}
            data-tip
            data-for={drugEffect.name}
            onClick={() => setSelectedEffect(drugEffect)}
          >
            {drugEffect.description && (
              <ReactTooltip effect="solid" id={drugEffect.name} multiline>
                <div style={{ maxWidth: '25rem' }}>
                  {drugEffect.description}
                  <br />
                  {`Overdose: ${drugEffect.od}`}
                </div>
              </ReactTooltip>
            )}
            {`${drugEffect.name} (${drugEffect.difficulty})`}
          </div>
        ))}
    </section>
  ) : null
);

type FeatureBlockProps = {
  block: string,
  selectedFeature: DrugFeature | undefined,
  setSelectedFeature: (drugEffect: DrugFeature)=>void
};

const FeatureBlock = ({ block, selectedFeature, setSelectedFeature }: FeatureBlockProps) => (
  block === 'Feature' ? (
    <section className={styles.infoSegment}>
      {drugFeatures
        .map((drugFeature) => (
          <div
            className={`${styles.dataCard} ${drugFeature.name === selectedFeature?.name ? styles.active : ''}`}
            key={drugFeature.name}
            role="button"
            tabIndex={0}
            data-tip
            data-for={drugFeature.name}
            onClick={() => setSelectedFeature(drugFeature)}
          >
            {drugFeature.description && (
              <ReactTooltip effect="solid" id={drugFeature.name} multiline>
                <div style={{ maxWidth: '25rem' }}>
                  {drugFeature.description}
                </div>
              </ReactTooltip>
            )}
            {`${drugFeature.name} (${drugFeature.costModifier})`}
          </div>
        ))}
    </section>
  ) : null
);

type UntimedSideEffectProps = {
  block: string,
  selectedUntimedSideEffect: DrugSideEffect | undefined,
  setSelectedUntimedSideEffect: (drugEffect: DrugSideEffect)=>void
};

const UntimedSideEffectBlock = ({
  block,
  selectedUntimedSideEffect,
  setSelectedUntimedSideEffect,
}: UntimedSideEffectProps) => (
  block === 'Untimed Side Effect'
    ? (
      <section className={styles.infoSegment}>
        {drugUntimedSideEffects
          .map((drugSideEffect) => (
            <div
              className={`${styles.dataCard} ${drugSideEffect.name === selectedUntimedSideEffect?.name ? styles.active : ''}`}
              key={drugSideEffect.name}
              role="button"
              tabIndex={0}
              data-tip
              data-for={drugSideEffect.name}
              onClick={() => setSelectedUntimedSideEffect(drugSideEffect)}
            >
              {drugSideEffect.description && (
                <ReactTooltip effect="solid" id={drugSideEffect.name} multiline>
                  <div style={{ maxWidth: '25rem' }}>
                    {drugSideEffect.description}
                  </div>
                </ReactTooltip>
              )}
              {`${drugSideEffect.name} (-${drugSideEffect.costModifier})`}
            </div>
          ))}
      </section>
    ) : null
);

type TimedSideEffectBlockProps ={
  block: string,
  selectedTimedSideEffects: DrugSideEffect[],
  setSelectedTimedSideEffect: (drugEffect: DrugSideEffect[])=>void
};

const TimedSideEffectBlock = ({
  block,
  selectedTimedSideEffects,
  setSelectedTimedSideEffect,
}:TimedSideEffectBlockProps) => (
  block === 'Timed Side Effects'
    ? (
      <section className={styles.infoSegment}>
        {drugTimedSideEffects
          .map((drugSideEffect) => (
            <div
              className={`${styles.dataCard} ${selectedTimedSideEffects.some(
                (selectedTimedSideEffect) => selectedTimedSideEffect.name === drugSideEffect.name,
              ) ? styles.active : ''}`}
              key={drugSideEffect.name}
              role="button"
              tabIndex={0}
              data-tip
              data-for={drugSideEffect.name}
              onClick={() => {
                if (selectedTimedSideEffects.some(
                  (selectedTimedSideEffect) => selectedTimedSideEffect.name === drugSideEffect.name,
                )) {
                  const newTimedSideEffects = [...selectedTimedSideEffects];
                  newTimedSideEffects
                    .splice(newTimedSideEffects
                      .findIndex((timedSideEffect) => (
                        timedSideEffect.name === drugSideEffect.name
                      )), 1);
                  setSelectedTimedSideEffect([...newTimedSideEffects]);
                } else {
                  setSelectedTimedSideEffect(
                    [...selectedTimedSideEffects, drugSideEffect],
                  );
                }
              }}
            >
              {drugSideEffect.description && (
                <ReactTooltip effect="solid" id={drugSideEffect.name} multiline>
                  <div style={{ maxWidth: '25rem' }}>
                    {drugSideEffect.description}
                  </div>
                </ReactTooltip>
              )}
              {`${drugSideEffect.name} (-${drugSideEffect.costModifier})`}
            </div>
          ))}
      </section>
    ) : null
);

type StrengthBlockProps = {
  block: string,
  selectedStrengthRelatedInfo: DrugStrengthRelatedInfo,
  setSelectedStrengthRelatedInfo: (drugEffect: DrugStrengthRelatedInfo)=>void
};

const StrengthBlock = ({
  block,
  selectedStrengthRelatedInfo,
  setSelectedStrengthRelatedInfo,
}: StrengthBlockProps) => (
  block === 'Strength'
    ? (
      <section className={styles.infoSegment}>
        {drugStrengthRelatedInfoData.map((strInfo) => (
          <div
            className={`${styles.dataCard} ${
              strInfo.strength === selectedStrengthRelatedInfo?.strength ? styles.active : ''
            }`}
            key={strInfo.strength}
            role="button"
            tabIndex={0}
            data-tip
            data-for={strInfo.strength}
            onClick={() => setSelectedStrengthRelatedInfo(strInfo)}
          >
            <ReactTooltip effect="solid" id={`${strInfo.strength}`} multiline>
              <div style={{ maxWidth: '25rem' }}>
                <div>
                  {`Addiction Number: ${strInfo?.addictionNumber}`}
                </div>
                <div>
                  {`Time To Next Dose: ${strInfo?.timeToNextDose}`}
                </div>
                <div>
                  {`Time To Withdrawal: ${strInfo?.timeToWithdrawal}`}
                </div>
              </div>
            </ReactTooltip>
            {`${strInfo.strength}`}
          </div>
        ))}
      </section>
    ) : null
);

type DurationBlockProps = {
  block: string,
  selectedDuration: DrugDuration,
  setSelectedDuration: (duration: DrugDuration)=>void
};

const DurationBlock = ({ block, selectedDuration, setSelectedDuration }:DurationBlockProps) => (
  block === 'Duration'
    ? (
      <section className={styles.infoSegment}>
        {drugDurationData.map((drugDurationItem) => (
          <div
            className={`${styles.dataCard} ${drugDurationItem.name === selectedDuration?.name ? styles.active : ''}`}
            key={drugDurationItem.name}
            role="button"
            tabIndex={0}
            onClick={() => setSelectedDuration(drugDurationItem)}
          >
            {`${capitalize(drugDurationItem.name)}: ${drugDurationItem.duration}`}
          </div>
        ))}
      </section>
    ) : null
);

type LegalityBlockProps = {
  block: string,
  selectedLegality: DrugLegality,
  setSelectedLegality: (legality: DrugLegality)=>void
};

const LegalityBlock = ({ block, selectedLegality, setSelectedLegality }:LegalityBlockProps) => (
  block === 'Legality'
    ? (
      <section className={styles.infoSegment}>
        {drugLegalityData.map((drugLegalityItem) => (
          <div
            className={`${styles.dataCard} ${drugLegalityItem.name === selectedLegality?.name ? styles.active : ''}`}
            key={drugLegalityItem.name}
            role="button"
            tabIndex={0}
            data-tip
            data-for={drugLegalityItem.name}
            onClick={() => setSelectedLegality(drugLegalityItem)}
          >
            <ReactTooltip effect="solid" id={`${drugLegalityItem.name}`} multiline>
              <div style={{ maxWidth: '25rem' }}>
                {drugLegalityItem.description}
              </div>
            </ReactTooltip>
            {`${drugLegalityItem.name} (x${drugLegalityItem.costMultiplier})`}
          </div>
        ))}
      </section>
    ) : null
);

type FormBlockProps = {
  block: string,
  selectedForm: DrugForm,
  setSelectedForm: (form: DrugForm)=>void
};

const FormBlock = ({ block, selectedForm, setSelectedForm }: FormBlockProps) => (
  block === 'Form'
    ? (
      <section className={styles.infoSegment}>
        {drugFormData.map((drugFormItem) => (
          <div
            className={`${styles.dataCard} ${drugFormItem.name === selectedForm?.name ? styles.active : ''}`}
            key={drugFormItem.name}
            role="button"
            tabIndex={0}
            data-tip
            data-for={drugFormItem.name}
            onClick={() => setSelectedForm(drugFormItem)}
          >
            <ReactTooltip effect="solid" id={`${drugFormItem.name}`} multiline>
              <div style={{ maxWidth: '25rem' }}>
                <div>
                  {`Drug Form: ${drugFormItem.name}`}
                </div>
                <div>
                  {`Time To Effect: ${drugFormItem.timeToEffect}`}
                </div>
                <div>
                  {`Is Cuttable: ${drugFormItem.cuttable ? 'Yes' : 'No'}`}
                </div>
                <div>
                  {drugFormItem.description}
                </div>
              </div>
            </ReactTooltip>
            {`${drugFormItem.name} (x${drugFormItem.costMultiplier})`}
          </div>
        ))}
      </section>
    ) : null
);

type DataSelectorProps = {
  drugData: Drug,
  setDrugData: (drug: Drug)=> void
};

const DataSelector = ({ drugData, setDrugData }: DataSelectorProps) => {
  const [block, setBlock] = useState('Effect');
  return (
    <div className={styles.dataSelector}>
      <div className={styles.controlsSegment}>
        <button type="button" onClick={() => setBlock('Effect')}>Effect</button>
        <button type="button" onClick={() => setBlock('Feature')}>Feature</button>
        <button type="button" onClick={() => setBlock('Untimed Side Effect')}>Untimed Side Effect</button>
        <button type="button" onClick={() => setBlock('Timed Side Effects')}>Timed Side Effects</button>
        <button type="button" onClick={() => setBlock('Strength')}>Strength</button>
        <button type="button" onClick={() => setBlock('Duration')}>Duration</button>
        <button type="button" onClick={() => setBlock('Legality')}>Legality</button>
        <button type="button" onClick={() => setBlock('Form')}>Form</button>
      </div>
      <EffectBlock
        block={block}
        selectedEffect={drugData.effect}
        setSelectedEffect={(effect) => setDrugData({ ...drugData, effect })}
      />
      <FeatureBlock
        block={block}
        selectedFeature={drugData.feature}
        setSelectedFeature={(feature) => setDrugData({ ...drugData, feature })}
      />
      <UntimedSideEffectBlock
        block={block}
        selectedUntimedSideEffect={drugData.untimedSideEffect}
        setSelectedUntimedSideEffect={(sideEffect) => setDrugData(
          { ...drugData, untimedSideEffect: sideEffect },
        )}
      />
      <TimedSideEffectBlock
        block={block}
        selectedTimedSideEffects={drugData.timedSideEffects}
        setSelectedTimedSideEffect={(sideEffects) => setDrugData({
          ...drugData, timedSideEffects: [...sideEffects],
        })}
      />
      <StrengthBlock
        block={block}
        selectedStrengthRelatedInfo={drugData.strengthRelatedInfo}
        setSelectedStrengthRelatedInfo={(strInfo) => setDrugData({
          ...drugData, strengthRelatedInfo: strInfo,
        })}
      />
      <DurationBlock
        block={block}
        selectedDuration={drugData.duration}
        setSelectedDuration={(duration) => setDrugData({
          ...drugData, duration,
        })}
      />
      <LegalityBlock
        block={block}
        selectedLegality={drugData.legality}
        setSelectedLegality={(legality) => setDrugData({
          ...drugData, legality,
        })}
      />
      <FormBlock
        block={block}
        selectedForm={drugData.form}
        setSelectedForm={(form) => setDrugData({
          ...drugData, form,
        })}
      />
    </div>
  );
};

export default DataSelector;
