import React from 'react';

import styles from './styles.module.scss';

type CardProps = {
  style?: React.CSSProperties,
  children: React.ReactNode
};

const Card = ({ style = {}, children }: CardProps) => (
  <div style={{ ...style }} className={styles.card}>
    {children}
  </div>
);

export default Card;
