import type { DrugStrengthRelatedInfo } from 'types';

const drugStrengthRelatedInfoData: DrugStrengthRelatedInfo[] = [
  {
    strength: 1,
    addictionNumber: 2,
    timeToNextDose: '1d6 Hours',
    timeToWithdrawal: '5d6 Hours',
  }, {
    strength: 2,
    addictionNumber: 4,
    timeToNextDose: '2d6 hours',
    timeToWithdrawal: '4d6 hours',
  }, {
    strength: 3,
    addictionNumber: 5,
    timeToNextDose: '3d6 hours',
    timeToWithdrawal: '3d6 hours',
  }, {
    strength: 4,
    addictionNumber: 7,
    timeToNextDose: '4d6 hours',
    timeToWithdrawal: '2d6 hours',
  }, {
    strength: 5,
    addictionNumber: 9,
    timeToNextDose: '5d6 hours',
    timeToWithdrawal: '1d6 hours',
  },
];

export default drugStrengthRelatedInfoData;
