import type { Skill } from './types';

const skillsData: { [key: string]: Skill } = {
  Concentration: {
    name: 'Concentration',
    value: 2,
    description: 'Skill of focus and mental control, encompassing feats of memory, recall, <br> ignoring distractions, and physiological mastery.',
    isX2: false,
    isBase: true,
    linkedStat: 'will',
    skillCategory: 'Awareness',
  },
  'Conceal/Reveal Object': {
    name: 'Conceal/Reveal Object',
    value: 0,
    description: 'Skill for hiding objects and finding objects that have been hidden.This is the Skill used for concealing weapons under clothing and detecting concealed weapons.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Awareness',
  },
  'Lip Reading': {
    name: 'Lip Reading',
    value: 0,
    description: 'Skill of reading someone\'s lips to tell what they are saying',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Awareness',
  },
  Perception: {
    name: 'Perception',
    value: 2,
    description: 'Skill of spotting hidden things like clues, traps, and people using the Stealth Skill, but not objects hidden with the Conceal/ Reveal Object Skill.',
    isX2: false,
    isBase: true,
    linkedStat: 'int',
    skillCategory: 'Awareness',
  },
  Tracking: {
    name: 'Tracking',
    value: 0,
    description: 'Skill of following a trail by observing tracks and other clues left behind.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Awareness',
  },
  Athletics: {
    name: 'Athletics',
    value: 2,
    description: 'Skill of jumping, climbing, throwing, swimming, lifting weights, etc.',
    isX2: false,
    isBase: true,
    linkedStat: 'dex',
    skillCategory: 'Body',
  },
  Contortionist: {
    name: 'Contortionist',
    value: 0,
    description: 'Skill of manipulating your body to get out of handcuffs or other bindings and to fit yourself into otherwise inaccessible places or spaces.',
    isX2: false,
    isBase: false,
    linkedStat: 'dex',
    skillCategory: 'Body',
  },
  Dance: {
    name: 'Dance',
    value: 0,
    description: 'Skill of dancing at a professional level.',
    isX2: false,
    isBase: false,
    linkedStat: 'dex',
    skillCategory: 'Body',
  },
  Endurance: {
    name: 'Endurance',
    value: 0,
    description: 'Skill of withstanding harsh environmental conditions and hardship.',
    isX2: false,
    isBase: false,
    linkedStat: 'will',
    skillCategory: 'Body',
  },
  'Resist Torture/Drugs': {
    name: 'Resist Torture/Drugs',
    value: 0,
    description: 'Skill of resisting painful effects including interrogation, torture, and drugs.',
    isX2: false,
    isBase: false,
    linkedStat: 'will',
    skillCategory: 'Body',
  },
  Stealth: {
    name: 'Stealth',
    value: 2,
    description: 'Skill of moving quietly, hiding, doing an Action discreetly, or otherwise evading detection.Other Characters can try to find you with their Perception Skill.',
    isX2: false,
    isBase: true,
    linkedStat: 'dex',
    skillCategory: 'Body',
  },
  'Drive Land Vehicle': {
    name: 'Drive Land Vehicle',
    value: 0,
    description: 'Skill of driving and maneuvering land vehicles.',
    isX2: false,
    isBase: false,
    linkedStat: 'ref',
    skillCategory: 'Control',
  },
  'Pilot Air Vehicle': {
    name: 'Pilot Air Vehicle',
    value: 0,
    description: 'Skill of piloting and maneuvering air vehicles.',
    isX2: true,
    isBase: false,
    linkedStat: 'ref',
    skillCategory: 'Control',
  },
  'Pilot Sea Vehicle': {
    name: 'Pilot Sea Vehicle',
    value: 0,
    description: 'Skill of piloting and maneuvering sea vehicles.',
    isX2: false,
    isBase: false,
    linkedStat: 'ref',
    skillCategory: 'Control',
  },
  Riding: {
    name: 'Riding',
    value: 0,
    description: 'Skill of riding a living creature trained for the purpose.',
    isX2: false,
    isBase: false,
    linkedStat: 'ref',
    skillCategory: 'Control',
  },
  Accounting: {
    name: 'Accounting',
    value: 0,
    description: 'Skill of balancing books, creating false books and identifying them, juggling numbers, creating budgets, and handling day to day business operations.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  'Animal Handling': {
    name: 'Animal Handling',
    value: 0,
    description: 'Skill of handling, training, and caring for animals.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  Bureaucracy: {
    name: 'Bureaucracy',
    value: 0,
    description: 'Skill for dealing with bureaucrats, knowing who to talk to in a bureaucracy, how to reach them, and how to extract information from bureaucracies.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  Business: {
    name: 'Business',
    value: 0,
    description: 'Skill regarding knowledge of basic business practices, laws of supply and demand, employee management, procurement, sales, marketing.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  Composition: {
    name: 'Composition',
    value: 0,
    description: 'Skill of professionally writing songs, articles, or stories.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  Criminology: {
    name: 'Criminology',
    value: 0,
    description: 'Skill for looking for clues by dusting for fingerprints, doing ballistic tests, examining evidence, and searching through police records and files.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  Cryptography: {
    name: 'Cryptography',
    value: 0,
    description: 'Skill of encrypting and decoding messages.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  Deduction: {
    name: 'Deduction',
    value: 0,
    description: 'Skill of taking several clues and leaping to a non-obvious conclusion or medical diagnosis.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  Education: {
    name: 'Education',
    value: 2,
    description: 'Skill of general knowledge, equivalent to a basic school education, allowing you to know how to read, write, use basic math, and know enough history to get by.',
    isX2: false,
    isBase: true,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  Gamble: {
    name: 'Gamble',
    value: 0,
    description: 'Skill of knowing how to figure odds and play games of chance successfully.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  'Language (Streetslang)': {
    name: 'Language (Streetslang)',
    value: 2,
    description: 'Skill of speaking a particular language. You must choose a specific language whenever you increase this Skill.',
    isX2: false,
    isBase: true,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  Language: {
    name: 'Language',
    value: 0,
    description: 'Skill of speaking a particular language. You must choose a specific language whenever you increase this Skill.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  'Library Search': {
    name: 'Library Search',
    value: 0,
    description: 'Skill of using databases, Data Pools, libraries, and other compiled information sources to find facts.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  'Local Expert (Your Home)': {
    name: 'Local Expert (Your Home)',
    value: 2,
    description: 'Skill of knowing a specific area well and knowing the agendas of its various factions, both political and criminal.You must choose a specific location whenever you increase this Skill, which cannot be any larger than a single neighborhood or community.',
    isX2: false,
    isBase: true,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  Science: {
    name: 'Science',
    value: 0,
    description: 'Skill for knowing how to design experiments, write scientific papers, test hypotheses, and discuss with other academics in a particular field of science. An area of study must be specified whenever this Skill is increased.Possible options include: Geology, Mathematics, Physics, Zoology, Anthropology, Biology, Chemistry, History, etc.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  Tactics: {
    name: 'Tactics',
    value: 0,
    description: 'Skill of managing a large-scale battle effectively and efficiently.A Character with this Skill usually knows what must be done to direct a battle, and how an enemy force may react.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  'Wilderness Survival': {
    name: 'Wilderness Survival',
    value: 0,
    description: 'Skill for knowing how to survive comfortably in the wilderness.',
    isX2: false,
    isBase: false,
    linkedStat: 'int',
    skillCategory: 'Education',
  },
  Brawling: {
    name: 'Brawling',
    value: 2,
    description: 'Skill at fighting and grappling with brute strength.',
    isX2: false,
    isBase: true,
    linkedStat: 'dex',
    skillCategory: 'Fighting',
  },
  Evasion: {
    name: 'Evasion',
    value: 2,
    description: 'Skill for getting out of the way of someone attacking you in melee.A Character with REF 8 or higher can also use this Skill to dodge Ranged Attacks.',
    isX2: false,
    isBase: true,
    linkedStat: 'dex',
    skillCategory: 'Fighting',
  },
  'Martial Arts': {
    name: 'Martial Arts',
    value: 0,
    description: 'Skill for fighting with a Martial Arts Form. Each time you increase this Skill you must choose in which form you are training.You can learn multiple forms,    but you must do so separately.Possible options (Karate, Taekwondo, Judo, Aikido) can be found on pg. 178.',
    isX2: true,
    isBase: false,
    linkedStat: 'dex',
    skillCategory: 'Fighting',
  },
  'Melee Weapon': {
    name: 'Melee Weapon',
    value: 0,
    description: 'Skill for fighting with melee weapons.',
    isX2: false,
    isBase: false,
    linkedStat: 'dex',
    skillCategory: 'Fighting',
  },
  Acting: {
    name: 'Acting',
    value: 0,
    description: 'Skill of assuming a role, disguising yourself as someone else, whether real or fictitious, and faking emotions and moods.',
    isX2: false,
    isBase: false,
    linkedStat: 'cool',
    skillCategory: 'Performance',
  },
  'Play Instrument': {
    name: 'Play Instrument',
    value: 0,
    description: 'Skill of professionally playing a musical instrument. You can choose which instrument when you increase this Skill.Possible options include: singing, guitar, drums, violin, piano, etc.',
    isX2: false,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Performance',
  },
  Archery: {
    name: 'Archery',
    value: 0,
    description: 'Skill for accurately firing bolt- or arrow-launching projectile weapons.',
    isX2: false,
    isBase: false,
    linkedStat: 'ref',
    skillCategory: 'Ranged Weapon',
  },
  Autofire: {
    name: 'Autofire',
    value: 0,
    description: 'Skill for keeping a weapon\'s Autofire firing mode on target through recoil.',
    isX2: true,
    isBase: false,
    linkedStat: 'ref',
    skillCategory: 'Ranged Weapon',
  },
  Handgun: {
    name: 'Handgun',
    value: 0,
    description: 'Skill for accurately firing handheld projectile weapons such as pistols.',
    isX2: false,
    isBase: false,
    linkedStat: 'ref',
    skillCategory: 'Ranged Weapon',
  },
  'Heavy Weapons': {
    name: 'Heavy Weapons',
    value: 0,
    description: 'Skill for accurately firing extremely large projectile weapons, including grenade and rocket launchers.',
    isX2: true,
    isBase: false,
    linkedStat: 'ref',
    skillCategory: 'Ranged Weapon',
  },
  'Shoulder Arms': {
    name: 'Shoulder Arms',
    value: 0,
    description: 'Skill for accurately firing shoulder-braced projectile weapons, including rifles and shotguns.',
    isX2: false,
    isBase: false,
    linkedStat: 'ref',
    skillCategory: 'Ranged Weapon',
  },
  Bribery: {
    name: 'Bribery',
    value: 0,
    description: 'Skill of knowing when to bribe someone, how to approach them, and how much to offer.',
    isX2: false,
    isBase: false,
    linkedStat: 'cool',
    skillCategory: 'Social',
  },
  Conversation: {
    name: 'Conversation',
    value: 2,
    description: 'Skill of extracting information from people without alerting them with careful conversation.',
    isX2: false,
    isBase: true,
    linkedStat: 'emp',
    skillCategory: 'Social',
  },
  'Human Perception': {
    name: 'Human Perception',
    value: 2,
    description: 'Skill of reading a person\'s facial expressions and body language to discern their emotional state and detect lies or deception.',
    isX2: false,
    isBase: true,
    linkedStat: 'emp',
    skillCategory: 'Social',
  },
  Interrogation: {
    name: 'Interrogation',
    value: 0,
    description: 'Skill of forcibly extracting information from people.',
    isX2: false,
    isBase: false,
    linkedStat: 'cool',
    skillCategory: 'Social',
  },
  Persuasion: {
    name: 'Persuasion',
    value: 2,
    description: 'Skill of convincing, persuading, or influencing individuals.',
    isX2: false,
    isBase: true,
    linkedStat: 'cool',
    skillCategory: 'Social',
  },
  'Personal Grooming': {
    name: 'Personal Grooming',
    value: 0,
    description: 'Skill of knowing proper grooming to maximize attractiveness.',
    isX2: false,
    isBase: false,
    linkedStat: 'cool',
    skillCategory: 'Social',
  },
  Streetwise: {
    name: 'Streetwise',
    value: 0,
    description: 'Skill of making and using contacts to get illegal goods and contraband, talking to the criminal element, and avoiding bad situations in bad neighborhoods.',
    isX2: false,
    isBase: false,
    linkedStat: 'cool',
    skillCategory: 'Social',
  },
  Trading: {
    name: 'Trading',
    value: 0,
    description: 'Skill of striking a good bargain with a merchant or customer.',
    isX2: false,
    isBase: false,
    linkedStat: 'cool',
    skillCategory: 'Social',
  },
  'Wardrobe & Style': {
    name: 'Wardrobe & Style',
    value: 0,
    description: 'Skill of knowing the clothes to wear and when to wear them.',
    isX2: false,
    isBase: false,
    linkedStat: 'cool',
    skillCategory: 'Social',
  },
  'Air Vehicle Tech': {
    name: 'Air Vehicle Tech',
    value: 0,
    description: 'Skill of repairing and maintaining air vehicles.',
    isX2: false,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  'Basic Tech': {
    name: 'Basic Tech',
    value: 0,
    description: 'Skill of identifying, understanding, and repairing simple electronic and mechanical devices and all other items not covered by another Technique Skill.',
    isX2: false,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  Cybertech: {
    name: 'Cybertech',
    value: 0,
    description: 'Skill of identifying, understanding, and repairing cybernetics.',
    isX2: false,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  Demolitions: {
    name: 'Demolitions',
    value: 0,
    description: 'Skill of setting and defusing explosives, and knowing how much explosive will accomplish a desired result.',
    isX2: true,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  'Electronics/Security Tech': {
    name: 'Electronics/Security Tech',
    value: 0,
    description: 'Skill of identifying, understanding, repairing, countering, and installing complex electronic devices, including: computers, cyberdecks, personal electronics, electronic security systems, bugs and tracers, pressure plates, laser tripwires, etc.',
    isX2: true,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  'First Aid': {
    name: 'First Aid',
    value: 2,
    description: 'Skill of applying medical treatments to a wounded person to treat the most common Critical Injuries and keep them from dying.',
    isX2: false,
    isBase: true,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  Forgery: {
    name: 'Forgery',
    value: 0,
    description: 'Skill of creating and detecting false documents and identification.',
    isX2: false,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  'Land Vehicle Tech': {
    name: 'Land Vehicle Tech',
    value: 0,
    description: 'Skill of repairing and maintaining land vehicles.',
    isX2: false,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  'Paint/Draw/Sculpt': {
    name: 'Paint/Draw/Sculpt',
    value: 0,
    description: 'Skill of producing professional paintings, drawings, or sculpture.',
    isX2: false,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  Paramedic: {
    name: 'Paramedic',
    value: 0,
    description: 'Skill of applying medical treatments to a wounded person to treat all Critical Injuries not requiring Surgery and keep them from dying(Surgery is only available to Medtechs through their Role Ability, Medicine).',
    isX2: true,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  'Photography/Film': {
    name: 'Photography/Film',
    value: 0,
    description: 'Skill for producing photographs, videos, or braindances.',
    isX2: false,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  'Pick Lock': {
    name: 'Pick Lock',
    value: 0,
    description: 'Skill for bypassing non-electronic locks.',
    isX2: false,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  'Pick Pocket': {
    name: 'Pick Pocket',
    value: 0,
    description: 'Skill for stealthily retrieving items secured on another person and shoplifting small items without being noticed.',
    isX2: false,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  'Sea Vehicle Tech': {
    name: 'Sea Vehicle Tech',
    value: 0,
    description: 'Skill of repairing and maintaining sea vehicles.',
    isX2: false,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
  Weaponstech: {
    name: 'Weaponstech',
    value: 0,
    description: 'Skill for repairing and maintaining weapons of all types.',
    isX2: false,
    isBase: false,
    linkedStat: 'tech',
    skillCategory: 'Technique',
  },
};

export default skillsData;
