import React from 'react';
import { MdAutorenew } from 'react-icons/md';

import styles from './styles.module.scss';

type ButtonToRandomProps = {
  onClick: () => void
};

const ButtonToRandom = ({ onClick }: ButtonToRandomProps) => (
  <button
    type="button"
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
    className={styles.generateButton}
  >
    <MdAutorenew />
  </button>
);

export default ButtonToRandom;
