import React, { useState } from 'react';
import assets from 'assets';
import { Dropdown, Card } from 'components/UI';
import FactItem from '../FactItem';

import styles from './styles.module.scss';

import rolesTable from './rolesTable.json';

type Role = keyof typeof rolesTable;

const RoleBlock = () => {
  const [currentRole, setCurrentRole] = useState<Role>('fixer');
  const [generateRandom, setGenerateRandom] = useState(false);

  return (
    <Card>
      <div
        className={styles.blockHeader}
        role="button"
        tabIndex={0}
        onClick={() => {
          setGenerateRandom(true);
          setTimeout(() => {
            setGenerateRandom(false);
          });
        }}
      >
        Create Role Details
      </div>
      <div className={styles.roleBlock}>
        <div style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '.7rem',
        }}
        >
          <Dropdown
            value={currentRole}
            options={Object.keys(rolesTable)}
            onChange={(value) => { setCurrentRole(value as Role); }}
          />
          <div style={{
            width: '22rem',
            height: '37rem',
            backgroundColor: 'white',
            backgroundImage: `url(${assets[(`${currentRole}Image`) as keyof typeof assets]})`,
            backgroundPosition: 'top',
            backgroundSize: 'cover',
          }}
          />
        </div>
        <div className={styles.factBlockArray}>
          {(rolesTable[currentRole] as []).map((roleTable) => {
            const tableHeader = Object.keys(roleTable)[0];
            return (
              <FactItem
                key={tableHeader}
                generateRandom={generateRandom}
                header={tableHeader}
                table={roleTable[tableHeader]}
              />
            );
          })}
        </div>
        {/* </div> */}
      </div>
    </Card>
  );
};

export default RoleBlock;
