import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';

type EncounterBlockProps = {
  value: number,
  table: (string | number)[][]
};

const EncounterBlock = ({ value, table }: EncounterBlockProps) => {
  const [encounter, setEncounter] = useState('');
  useEffect(() => {
    for (let index = 0; index < table.length; index++) {
      if (value <= table[index][0]) {
        setEncounter(table[index][1] as string);
        return;
      }
    }
  }, [table, value]);
  return (
    <span className={styles.encounterBlock}>
      {encounter}
    </span>
  );
};

export default EncounterBlock;
