import type { DrugSideEffect } from 'types';

const drugUntimedSideEffects: DrugSideEffect[] = [
  {
    name: 'Addiction Tolerance',
    costModifier: 4,
    description: 'People who are addicted to the drug gain a tolerance to its effects. While still hooked on the drug, they gain no benefit from it unless they double or triple their dosage. Even when the dosage is upped, users only gain benefits equal to those normally indicated for a second (or third) dose (which is to say, half), but they are still at risk of overdose.',
  }, {
    name: 'Carcinogenic',
    costModifier: 10,
    description: 'The drug causes cancer in laboratory tests, and the Surgeon General recommends that you stick a loaded gun in your mouth as opposed to taking the substance. Every time you use the substance, roll 1D100 (or have your GM do it for you, secretly). On a roll of 01-03, you’ve got a little bitty tumor growing somewhere in your body. Of course, tumors continue to spread and grow, don’t they…',
  }, {
    name: 'Cardiac Arrest / Heart Attack',
    costModifier: 12,
    description: 'This particularly deadly side effect indicates that the drug puts massive strain on the user’s heart, risking a severe heart attack. Roll 1D10. On a result of 1-3, cardiac arrest results. Roll another 1D10, and then make a death save as if you were at the resulting Mortal level. For example, if the second 1D10 roll results in a 6, the user makes a death save as if at Mortal 6. Failure indicates death.',
  }, {
    name: 'Coma',
    costModifier: 10,
    description: 'By using this drug, characters risk falling into a deep coma. When the drug is taken, roll 1D10. On a result of 1-3, the character becomes comatose for 1D10 days per strength point of the drug. Unless properly cared for, death will most likely result from starvation and dehydration.',
  }, {
    name: 'Death',
    costModifier: 15,
    description: 'The drug that this side effect is attached to is no better than poison. Each time you use it, you must make a Death Save as if you were at Mortal 2. Failure indicates your heart stops and you die. Pretty simple, eh?',
  }, {
    name: 'Genetic Damage',
    costModifier: 6,
    description: 'Drugs with this side effect screw with a person’s genes. In practical terms, this can result in severe birth defects among the user’s progeny. Make a note every time the drug is taken. If the user ever manages to sire a child, roll 1D10. If the roll is less than or equal to the number of times the drug has been taken by the user in his lifetime, there may be some sort of defect/mutation in his child. To determine the defect, use the “Offspring Mutation Table” on page 22 of “Deep Space,” treating all rolls of “Favorable” as “Harmless.”',
  }, {
    name: 'Nerve Degeneration',
    costModifier: 15,
    description: 'This side effect is reserved for those really nasty drugs that only idiots and guinea pigs imbibe of. Every time you use this drug, your Reflex stat is reduced by 2. If it ever reaches 1, you’ve contracted a serious case of Parkinson’s Disease, and all you can do is drool. At least you can use those neat handicapped parking spaces now. If it goes to 0 or below, roll up a new character, chombotta, and remember to say no to drugs.',
  }, {
    name: 'Nightmares',
    costModifier: 6,
    description: 'This drug causes terrible nightmares in users. The first time a user of such a drug sleeps after a dose, he must roll under his COOL -4. Failure indicates that he wakes up screaming after 1D6 hours of restless slumber, and will be unable to sleep again for hours afterwards. Nightmares caused by the side effect cannot be recalled by the user, and reflect on his most primal fears. Addicts of such drugs often use them in conjunction with stimulants since they know that monsters are waiting for them behind their closed eyelids…',
  }, {
    name: 'Permanent Sense Loss',
    costModifier: 6,
    description: 'The drug causes the user to lose a sense (touch, smell, hearing, taste, or sight) permanently. Roll 1D10. On a roll of 1-3, the affected sense is gone, forever. This is due to nerve damage, so senses enhanced with cybernetics (ie, optics, hearing modules, tactile boosts, etc.) are affected as well. It is possible to regain the sense with expensive nanosurgery involving nerve reconstruction.',
  }, {
    name: 'Slightly Physiologically Addictive',
    costModifier: 5,
    description: 'These drugs are physiologically addictive. A drug with this side effect causes the user to physically require the drug in order to function. Slightly Addictive drugs require an addiction save every 1D6+4 times they are used.',
  }, {
    name: 'Mildly Physiologically Addictive',
    costModifier: 10,
    description: 'These drugs are physiologically addictive. A drug with this side effect causes the user to physically require the drug in order to function. Mildly Addictive drugs require an addiction save every 1D6/2+2 times they are used.',
  }, {
    name: 'Highly Physiologically Addictive',
    costModifier: 15,
    description: 'These drugs are physiologically addictive. A drug with this side effect causes the user to physically require the drug in order to function. Highly Addictive drugs require an addiction save each and every time they are used.',
  }, {
    name: 'Possible Attribute Loss',
    costModifier: 8,
    description: 'This side-effect covers the possible loss of an attribute from a variety of factors which could result from drug ingestion. Attributes that can be damaged this way include Intelligence and Tech (brain damage), Reflex and Movement Allowance (nerve degeneration), Body (immunodeficiency and muscle atrophy), and Empathy and Cool (psychological damage). Every time you take the drug in question, roll 1d10. On a roll of 1-3, you’ve lost a point from one of these attributes permanently.',
  }, {
    name: 'Slightly Psychologically Addictive',
    costModifier: 4,
    description: 'This side effect reflects the psychological effect the drug has on its user. A user who is psychologically addicted to a substance is convinced he needs the drug, and feels he can’t perform without it. Slightly Addictive drugs require an addiction save every 1D6+4 times they are used.',
  }, {
    name: 'Mildly Psychologically Addictive',
    costModifier: 8,
    description: 'This side effect reflects the psychological effect the drug has on its user. A user who is psychologically addicted to a substance is convinced he needs the drug, and feels he can’t perform without it. Mildly Addictive drugs require an addiction save every 1D6/2+2 times they are used.',
  }, {
    name: 'Highly Psychologically Addictive',
    costModifier: 12,
    description: 'This side effect reflects the psychological effect the drug has on its user. A user who is psychologically addicted to a substance is convinced he needs the drug, and feels he can’t perform without it. Highly Addictive drugs require an addiction save each and every time they are used.',
  }, {
    name: 'Severe Withdrawal Symptoms',
    costModifier: 6,
    description: 'Only usable for addictive drugs. Withdrawal symptoms are twice as bad as they would normally be. A drug with the “Lessened Withdrawal Symptoms” feature cannot contain this side effect.',
  }, {
    name: 'Sterility',
    costModifier: 8,
    description: 'Using this drug can cause the junkie to become unable to sire children by damaging his sexual glands directly. In severe cases, sexual function is completely impaired, and impotence will result. Roll 1d10 each time the drug is used. On a roll of 1-3, the user’s sexual fertility is destroyed.',
  },
];

export default drugUntimedSideEffects;
