import React from 'react';
import {
  Route, Switch, useRouteMatch,
} from 'react-router-dom';

import NPClist from './NPCList';
import Equipment from './Equipment';

const InfoScreen = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/npc-list`}>
        <NPClist />
      </Route>
      <Route path={`${path}/equipment`}>
        <Equipment />
      </Route>
    </Switch>
  );
};

export default InfoScreen;
