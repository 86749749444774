import React, { useState, useEffect } from 'react';

import getRandom from 'utils/generator';
import { ButtonToRandom, Card } from 'components/UI';

import styles from './nightMarket.module.scss';

import { nightMarketContentByType, nightMarketTypesDescription } from './nightMarketData';
import type { NightMarketTypes } from './nightMarketData';

interface NightMarketData {
  categories: NightMarketTypes[];
  firstCategoryGoods: string[];
  secondCategoryGoods: string[];
}

const getNightMarketTypes = () => {
  const firstType = getRandom(Object.keys(nightMarketTypesDescription).length);
  let secondType = getRandom(Object.keys(nightMarketTypesDescription).length);
  while (firstType === secondType) {
    secondType = getRandom(Object.keys(nightMarketTypesDescription).length);
  }
  return [
    Object.keys(nightMarketTypesDescription)[firstType],
    Object.keys(nightMarketTypesDescription)[secondType],
  ] as NightMarketTypes[];
};

const getNightMarketData = () => {
  const nighMarketData: NightMarketData = {
    categories: [],
    firstCategoryGoods: [],
    secondCategoryGoods: [],
  };

  nighMarketData.categories = getNightMarketTypes();

  for (let index = 0; index < getRandom(10) + 1; index++) {
    nighMarketData.firstCategoryGoods.push(
      nightMarketContentByType[nighMarketData.categories[0]][getRandom(20)],
    );
  }

  for (let index = 0; index < getRandom(10) + 1; index++) {
    nighMarketData.secondCategoryGoods.push(
      nightMarketContentByType[nighMarketData.categories[1]][getRandom(20)],
    );
  }

  return nighMarketData;
};

const NightMarket = () => {
  const [nightMarketData, setNightMarketData] = useState<NightMarketData | null>(null);

  useEffect(() => {
    setNightMarketData(getNightMarketData());
  }, []);

  return (
    nightMarketData && (
      <Card>
        <div className={styles.cardHeader}>
          Nightmarket Generator
        </div>
        <div className={styles.baseBlock}>
          <div className={styles.header}>
            <div>
              <span>This Night Market can offer you </span>
              <span style={{ color: 'rgb(202, 44, 25)' }}>
                {nightMarketData.categories[0]}
              </span>
              <span> and </span>
              <span style={{ color: 'rgb(202, 44, 25)' }}>
                {nightMarketData.categories[1]}
              </span>
            </div>
            <ButtonToRandom onClick={() => setNightMarketData(getNightMarketData())} />
          </div>
          <div className={styles.body}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {nightMarketData.firstCategoryGoods.map((item) => (
                <div
                  key={item + Math.random()}
                  style={{
                    borderBottom: 'dotted 1px rgb(202, 44, 25)',
                    padding: '.2rem',
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {nightMarketData.secondCategoryGoods.map((item) => (
                <div
                  key={item + Math.random()}
                  style={{
                    borderBottom: 'dotted 1px rgb(202, 44, 25)',
                    padding: '.2rem',
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>
    )
  );
};

export default NightMarket;
