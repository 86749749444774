import React from 'react';

import CommonBlock from './CommonBlock';
import RoleBlock from './RoleBlock';

// import styles from './styles.module.scss';

const LifepathScreen = () => (
  <div style={{
    display: 'flex', flexDirection: 'column', gap: '2rem',
  }}
  >
    <CommonBlock />
    <RoleBlock />
  </div>
);

export default LifepathScreen;
