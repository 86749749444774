import type { DrugForm } from 'types';

const drugFormData: DrugForm[] = [
  {
    name: 'Pill, Tablet',
    timeToEffect: '2d6x10 minutes',
    costMultiplier: 0.5,
    cuttable: false,
    description: 'This is your typical pill. It comes in all shapes, sizes, and colors. Pills are generally chalky and taste bad unless coated. They are the cheapest form of drug administration since they take a horribly long time to go into effect.',
  }, {
    name: 'Gel Cap, Caplet',
    timeToEffect: '9+1d6 Minutes',
    costMultiplier: 1,
    cuttable: false,
    description: 'This includes drugs that are specially coated for ease of swallowing, and that are time-released for a quicker effect than tablets. Most people prefer this form to tablets.',
  }, {
    name: 'Paper Tab',
    timeToEffect: '1d10 Minutes',
    costMultiplier: 1,
    cuttable: false,
    description: 'A paper tab is a piece of paper that has been soaked in a concentrated liquid drug and then dried. The user places the tab onto his tongue and sucks on it until the drug takes effect. This form of drug has always been a popular way of administering hallucinogens like LSD.',
  }, {
    name: 'Smoked, Inhaled',
    timeToEffect: '1d6 Turns',
    costMultiplier: 1,
    cuttable: true,
    description: 'These drugs come in a form that is easily burnt. The smoke or fumes produced are then inhaled directly through a device, usually a pipe. Sometimes, tobacco or cigarettes are soaked in a liquid drug and then dried and smoked.',
  }, {
    name: 'Powdered, Snorted',
    timeToEffect: '1d2 Minutes',
    costMultiplier: 1,
    cuttable: true,
    description: 'Powdered drugs which are snorted make their way to the lungs, sinuses, and mucus membranes where they are absorbed by the body. This form of use is less offensive to some than smoking, but it also takes a little longer for the drug to take effect.',
  }, {
    name: 'Injected',
    timeToEffect: '1d6/2 Turns',
    costMultiplier: 1.5,
    cuttable: true,
    description: 'Most injected drugs are concentrated liquids designed for direct entry into the bloodstream. Some start out as powdered drugs, but are liquefied in a process known as “free basing.” Some people prefer this method of using, and will pay the extra cash to avoid the risks of free basing the product themselves.',
  }, {
    name: 'Liquid',
    timeToEffect: '1d10 Minutes',
    costMultiplier: 1.5,
    cuttable: true,
    description: 'Liquid drugs are usually swallowed like a drink. Some can be mixed with normal soft drinks or fruit juices to cut the taste, though some are designed to be quite tasty all by themselves. While it may take the drugs longer to take effect, this is by far one of the most comfortable methods of imbibing.',
  }, {
    name: 'Derm, Slap Patch',
    timeToEffect: '10+2d10 Seconds',
    costMultiplier: 2,
    cuttable: false,
    description: 'An adhesive sticker which is placed upon the skin, and the dose of the drug is then absorbed. Derms are convenient to use, easy to transport, and, if sealed in plastic, they have a good shelf life. However, the adhesive can irritate sensitive skin and cause an itchy rash.',
  }, {
    name: 'Contact',
    timeToEffect: '1d6/2 Turns',
    costMultiplier: 2.5,
    cuttable: false,
    description: 'Contact drugs are absorbed directly by the skin, almost instantly. They are usually hard to keep for long periods of time, as they are unstable and “go bad” quickly. However, they are the easiest and most comfortable way to administer drugs (or poisons) to yourself or an unsuspecting victim.',
  },
];

export default drugFormData;
